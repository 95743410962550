import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

function PageNotFound(props) {
    const { goBack } = useHistory();

    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
                <Button type="primary" onClick={() => goBack()}>
                    Go Back
                </Button>
            }
        />
    );
}

export default PageNotFound;
