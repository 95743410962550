import { useMemo } from 'react';
import { DatePicker } from 'antd';
import { useField } from 'formik';
import { FieldWrapper } from 'components/Wrapper';
import moment from 'moment';
import { DATE_FORMAT } from 'const';
import styled from 'styled-components';

function DateInput({ label, required, onChange, format, ...props }) {
    const [field, meta, helper] = useField(props);
    const { touched, error } = meta;
    const { setValue } = helper;
    const { value } = field;

    const isInvalid = useMemo(() => {
        return !!(touched && error);
    }, [touched, error]);

    const changeHandler = (val) => {
        setValue(val);

        if (onChange) onChange(val);
    };

    const fieldValue = useMemo(() => {
        if (!value) return '';

        const date = moment(value);

        if (date.isValid()) {
            return date;
        }
    }, [value]);

    return (
        <FieldWrapper
            isInvalid={isInvalid}
            label={label}
            required={required}
            error={error}
        >
            <DateField
                {...props}
                format={format || DATE_FORMAT}
                value={fieldValue}
                onChange={changeHandler}
            />
        </FieldWrapper>
    );
}

const DateField = styled(DatePicker)`
    width: 100%;
`;

export default DateInput;
