const initialState = {
  abandonedDocuments: null,
  uploadedDocuments: false,
  documentsLoading: false,
  dashboard: null,
};

export const mamangerDocumentsReduced = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ABANDONED_DOCUMENTS':
      return { ...state, documentsLoading: true };
    case 'FETCH_UPLOADED_DOCUMENTS':
      return { ...state, documentsLoading: true };
    case 'FETCH_ABANDONED_DOCUMENTS_SUCCESS':
      return { ...state, documentsLoading: false, documentsError: null, abandonedDocuments: action.payload };
    case 'FETCH_UPLOADED_DOCUMENTS_SUCCESS':
      return { ...state, documentsLoading: false, documentsError: null, uploadedDocuments: action.payload };
    case 'FETCH_DOCUMENTS_AUDIT_LOG_ERROR':
      return { ...state, loading: false, error: action.payload };
    case 'FETCH_DOCUMENTS_DASHBOARD':
      return { ...state, loading: true };
    case 'FETCH_DOCUMENTS_DASHBOARD_SUCCESS':
      return { ...state, loading: false, dashboard: action.payload };
    case 'FETCH_DOCUMENTS_DASHBOARD_ERROR':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
