import { FilterOutlined } from '@ant-design/icons';
import { Form, DatePicker, Select, Input, Row, Col, Button } from 'antd';
import { formateDateTime } from 'library';

const { Item, useForm } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;

function PaymentFilter({ onFilter }) {
    const [form] = useForm();

    const applyFilter = (filter) => {
        const { range } = filter;

        if (range) {
            filter['from_next_pay_date'] = formateDateTime(
                range[0],
                'YYYY-MM-DD'
            );
            filter['to_next_pay_date'] = formateDateTime(
                range[1],
                'YYYY-MM-DD'
            );
        } else {
            filter['from_next_pay_date'] = null;
            filter['to_next_pay_date'] = null;
        }

        delete filter['range'];

        onFilter(filter);
    };

    return (
        <Form form={form} onFinish={applyFilter}>
            <Row gutter={16} justify="end">
                <Col>
                    <Item name="range">
                        <RangePicker
                            placeholder={['YYYY-MM-DD', 'YYYY-MM-DD']}
                        />
                    </Item>
                </Col>
                <Col>
                    <Item name="case_id">
                        <Input
                            placeholder="Case ID"
                            style={{ width: '130px' }}
                            allowClear
                        />
                    </Item>
                </Col>
                <Col>
                    <Item name="status">
                        <Select
                            style={{ width: 120 }}
                            placeholder="Status"
                            allowClear
                        >
                            <Option value={'current'}>Current</Option>
                            <Option value={'past_due'}>Past Due</Option>
                            <Option value={'paid_in_full'}>Paid in Full</Option>
                        </Select>
                    </Item>
                </Col>
                <Col>
                    <Item>
                        <Button
                            htmlType="button"
                            type="primary"
                            onClick={form.submit}
                            icon={<FilterOutlined />}
                        >
                            Filter
                        </Button>
                    </Item>
                </Col>
            </Row>
        </Form>
    );
}

PaymentFilter.defaultProps = {
    onFilter: () => {},
};

export default PaymentFilter;
