import { Alert, Typography } from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useApi, endpoints } from 'api';
import { Row } from 'antd';
import { useSelector } from 'react-redux';
import { useActions } from 'hooks/useAction';

import {
  MANAGER_DOCUMENTS,
  NEW_CLIENTS_PATH,
  NEW_UNVERIFIED_CLIENTS_PATH,
  NEW_VERIFIED_CLIENTS_PATH,
  REVISITED_CLIENTS_PATH,
  VERIFILINK_COMPLETED_PATH,
  VERIFILINK_IN_PROGRESS_PATH
} from 'routes/paths';

import { StatisticsCard } from 'components/StatisticsCard';


const { Title } = Typography;
const CardArray = [
  {
    "CardTitle": 'New Clients', "CardValue": 0, "key": 'new_clients'
  },
  {
    "CardTitle": 'Email Unverified Clients', "CardValue": 0, "key": 'new_unverified_clients'
  },
  {
    "CardTitle": 'New Email Verified Clients', "CardValue": 0, "key": 'new_verified_clients'
  },
  {
    "CardTitle": 'Revisited Clients', "CardValue": 0, "key": 'revisited_clients'
  },
]


function Dashboard() {
  const history = useHistory();
  const [data, setData] = useState({
    new_clients: 0,
    new_unverified_clients: 0,
    new_verified_clients: 0,
    revisited_clients: 0,
  });

  const { dashboard } = useSelector(state => state.managerDocuments);

  const [api] = useApi();
  const {
    fetchDocumentsDashboard,
  } = useActions();

  const fetchClientsReport = useCallback(
    async () => {
      try {
        const req = await api({
          url: endpoints.ACTIVITY_REPORT_ALL,
          method: 'POST'
        });

        const res = await req.data;

        if (res) {
          setData(res);
        }

      } catch (error) {
        console.log({ error });

      }
    }, [api]
  );

  useEffect(() => {
    fetchClientsReport();
    fetchDocumentsDashboard(api);
  }, [api]);

  const doClick = (key) => {
    switch (key) {
      case 'new_clients':
        history.push(NEW_CLIENTS_PATH);
        break;
      case 'new_unverified_clients':
        history.push(NEW_UNVERIFIED_CLIENTS_PATH);
        break;
      case 'new_verified_clients':
        history.push(NEW_VERIFIED_CLIENTS_PATH);
        break;
      case 'revisited_clients':
        history.push(REVISITED_CLIENTS_PATH);
        break;
      case 'no_of_client_completed_verifilink':
        history.push(VERIFILINK_COMPLETED_PATH);
        break;
      case 'no_of_client_verifilink_in_progress':
        history.push(VERIFILINK_IN_PROGRESS_PATH);
        break;
      case 'manager_documents':
        history.push(MANAGER_DOCUMENTS);
        break;
      default:
        console.log('no matched path')
    }
  }

  return <>
    <Title level={3}>Your Updates</Title>
    {!dashboard?.total_cases && <Alert
        message="You have no assigned cases at the moment..."
        type="warning"
        closable
    />}
    <div className='report-wrapper'>
      <Row style={{ width: '100%' }}>
        <Row style={{ width: '100%' }}>
          <StatisticsCard
            cardTitle="Cases to Review"
            value={dashboard?.uploaded_cases_count || 0}
            onClick={() => doClick('manager_documents')}
            hoverable={true} />
          <StatisticsCard
            cardTitle="Abandoned Cases"
            value={dashboard?.abandoned_cases_count || 0}
            onClick={() => doClick('manager_documents')}
            hoverable={true} />
          <StatisticsCard
            cardTitle="Abandoned This Month"
            value={dashboard?.abandoned_this_month || 0}
            onClick={() => doClick('manager_documents')}
            hoverable={true} />
          <StatisticsCard
            cardTitle="Approved This month"
            value={dashboard?.approved_this_month || 0}
            onClick={() => doClick('manager_documents')}
            hoverable={true} />
          <StatisticsCard
            cardTitle="Avg Days To Approve"
            value={dashboard?.avg_time_to_approve || 0}
            onClick={() => doClick('manager_documents')}
            hoverable={true} />
          <StatisticsCard
            cardTitle="Avg Days To Upload"
            value={dashboard?.avg_time_to_upload || 0}
            onClick={() => doClick('manager_documents')}
            hoverable={true} />
          <StatisticsCard
            cardTitle="Oldest Abandoned Case (days)"
            value={dashboard?.oldest_abandoned_days || 0}
            onClick={() => doClick('manager_documents')}
            hoverable={true} />
          <StatisticsCard
            cardTitle="Oldest case To Review (days)"
            value={dashboard?.oldest_uploaded_days || 0}
            onClick={() => doClick('manager_documents')}
            hoverable={true} />
        </Row>
      </Row>
    </div>

    <Title level={3}>Clients Status Report for Today</Title>
    <div className='report-wrapper'>
      <Row style={{ width: '100%' }}>
        {CardArray.map((cardItem, idx) => <StatisticsCard
          hoverable={true}
          onClick={() => doClick(cardItem.key)}
          cardTitle={cardItem.CardTitle}
          value={data[cardItem.key]}
          key={idx} />)}
      </Row>
    </div>
  </>;
}

export default Dashboard;
