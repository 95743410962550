import Login from 'pages/Auth/Login';
import { Switch, Route, Redirect } from 'react-router-dom';
import store from 'store';
import {
  DASHBOARD,
  LOGIN,
  CLIENTS,
  MANUAL_PAYMENT,
  NEW_CLIENTS_PATH,
  NEW_UNVERIFIED_CLIENTS_PATH,
  NEW_VERIFIED_CLIENTS_PATH,
  REVISITED_CLIENTS_PATH,
  VERIFILINK_COMPLETED_PATH,
  VERIFILINK_IN_PROGRESS_PATH,
  CLIENT_PORTAL_INVITATION,
  CREATE_STAFF_OR_ADMIN,
  CHANGE_PASSWORD,
  USERS, REQUEST_DOCUMENT,
  MANAGER_DOCUMENTS,
  UPLOADED_DOCUMENTS
} from './paths';
import DashboardRoot from 'pages/Dashboard';
import AppLayout from 'containers/AppLayout';
import PageNotFound from 'pages/NotFound/PageNotFound';
import ClientsRoot from 'pages/Clients';
import CPSignup from 'pages/CPSignup';
import CreateAdminIndex from 'pages/CreateAdmin';
import ManualPayment from 'pages/ManualPayment';
import NewClientsPage from 'pages/Dashboard/NewClientsPage';
import NewUnverifiedClientsPage from 'pages/Dashboard/NewUnverifiedClientsPage';
import NewVerifiedClientsPage from 'pages/Dashboard/NewVerifiedClientsPage';
import RevisitedClientsPage from 'pages/Dashboard/RevisitedClientsPage';
import VerifilinkCompletedPage from 'pages/Dashboard/VerifilinkCompletedPage';
import VerifilinkInProgressPage from 'pages/Dashboard/VerifilinkInProgressPage';
import ChangePasswordRoute from "../pages/Auth/ChangePassword";
import UsersRoot from "../pages/Users";
import RequestDocumentWrapper from "../pages/CPRequestDocument";
import UploadedDocumentsWrapper from "../pages/UploadedDocuments";

import ManagerDocuments from "../pages/CPRequestDocument/ManagerDocuments";

const Routes = () => {
  return (
    <Switch>
      <PrivateRoute path={DASHBOARD}>
        <AppLayout>
          <DashboardRoot />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute path={NEW_CLIENTS_PATH}>
        <AppLayout>
          <NewClientsPage />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute path={NEW_UNVERIFIED_CLIENTS_PATH}>
        <AppLayout>
          <NewUnverifiedClientsPage />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute path={NEW_VERIFIED_CLIENTS_PATH}>
        <AppLayout>
          <NewVerifiedClientsPage />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute path={REVISITED_CLIENTS_PATH}>
        <AppLayout>
          <RevisitedClientsPage />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute path={VERIFILINK_COMPLETED_PATH}>
        <AppLayout>
          <VerifilinkCompletedPage />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute path={VERIFILINK_IN_PROGRESS_PATH}>
        <AppLayout>
          <VerifilinkInProgressPage />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute path={CLIENTS}>
        <AppLayout>
          <ClientsRoot />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute path={USERS}>
        <AppLayout>
          <UsersRoot />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute path={CLIENT_PORTAL_INVITATION}>
        <AppLayout>
          <CPSignup />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute path={REQUEST_DOCUMENT}>
        <AppLayout>
          <RequestDocumentWrapper />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute path={UPLOADED_DOCUMENTS}>
        <AppLayout>
          <UploadedDocumentsWrapper />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute path={MANAGER_DOCUMENTS}>
        <AppLayout>
          <ManagerDocuments />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute path={CREATE_STAFF_OR_ADMIN}>
        <AppLayout>
          <CreateAdminIndex />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute path={CHANGE_PASSWORD}>
        <AppLayout>
          <ChangePasswordRoute />
        </AppLayout>
      </PrivateRoute>
      <PrivateRoute path={MANUAL_PAYMENT}>
        <AppLayout>
          <ManualPayment />
        </AppLayout>
      </PrivateRoute>
      <Route exact path={['/', LOGIN]}>
        <Login />
      </Route>
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
};

function PrivateRoute({ children, ...rest }) {
  const access_token = store.get('access_token');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        access_token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: LOGIN,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default Routes;
