import { Layout } from 'antd';
const { Footer: AntFooter } = Layout;

function Footer(props) {
    return (
        <AntFooter style={{ textAlign: 'center' }}>
            <p className="mb-0">
                <small>TaxRise Admin v1.0</small>
            </p>
        </AntFooter>
    );
}

export default Footer;
