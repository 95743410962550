const LOGIN = '/login/';
const DASHBOARD = '/dashboard';
const CLIENTS = '/clients';
const USERS = '/users';
const CLIENT_DETAILS = `${CLIENTS}/details`;
const MANUAL_PAYMENT = '/manual-payment';
const NEW_CLIENTS_PATH = '/new-clients';
const NEW_UNVERIFIED_CLIENTS_PATH = '/new-unverified-clients';
const NEW_VERIFIED_CLIENTS_PATH = '/new-verified-clients';
const REVISITED_CLIENTS_PATH = '/revisited-clients';
const VERIFILINK_COMPLETED_PATH = '/verifilink-completed';
const VERIFILINK_IN_PROGRESS_PATH = '/verifilink-in-progress';
const CHANGE_PASSWORD = '/change-password';
const UPLOADED_DOCUMENTS = '/uploaded-documents'
const CLIENT_PORTAL_INVITATION = '/client-portal-invitation'
const REQUEST_DOCUMENT = '/request-document'
const CREATE_STAFF_OR_ADMIN = '/create-admin-account'

const MANAGER_DOCUMENTS = '/manager/documents'

export {
  LOGIN,
  DASHBOARD,
  CLIENTS,
  CLIENT_DETAILS,
  MANUAL_PAYMENT,
  NEW_CLIENTS_PATH,
  NEW_UNVERIFIED_CLIENTS_PATH,
  NEW_VERIFIED_CLIENTS_PATH,
  REVISITED_CLIENTS_PATH,
  VERIFILINK_COMPLETED_PATH,
  VERIFILINK_IN_PROGRESS_PATH,
  CLIENT_PORTAL_INVITATION,
  REQUEST_DOCUMENT,
  UPLOADED_DOCUMENTS,
  CREATE_STAFF_OR_ADMIN,
  CHANGE_PASSWORD,
  USERS,
  MANAGER_DOCUMENTS,
};
