/* eslint-disable new-cap */
import { Radio, Typography, Tag, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons'
import { useState, useEffect, useCallback } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer
} from 'recharts';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useApi, endpoints } from 'api';
import ClientTable from 'components/ClientTable';
import styled from 'styled-components';
const { Title } = Typography;

const DATA = [
    {
        "day": "0",
        "year": 0,
        "total": 0,
        "items": []
    }
];

const VARIANTS = {
    "day": {
        count: 30,
        reportText: "Last 30 Days"
    },
    "month": {
        count: 12,
        reportText: "Last 12 Months"
    },
    "year": {
        count: 2,
        reportText: "Last 2 Years"
    },
}

function VerifilinkReport(props) {
    const [data, setData] = useState(DATA);
    const [clientsData, setClientsData] = useState([]);
    const [api] = useApi();
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState('day');
    const [titleText, setTitleText] = useState('day');
    const [selected, setSelected] = useState('');
    const [labelText, setLabelText] = useState('');

    const fetchSummary = useCallback(
        async () => {
            try {
                setLoading(true);
                setData(DATA);
                setClientsData([]);
                setTitleText(VARIANTS[view].reportText);
                const req = await api({
                    url: props.APIEndpoint,
                    method: 'POST',
                    params: {
                        duration_type: view, duration: VARIANTS[view].count
                    }
                });

                const res = await req.data;

                if (res) {
                    const temp = [];
                    let tempLabel = '';
                    if (view === 'day') {
                        res.map(item => {
                            if (item.day && item.month) {
                                item.day = `${item.day}${item.month.substring(0, 3).replace(/\b\w/g, (l) => { return l.toUpperCase() })}`;

                                if (temp.length === 0) {
                                    temp.push(item.month);
                                    tempLabel = item.month.replace(/\b\w/g, (l) => { return l.toUpperCase() });
                                }
                                if (!temp.includes(item.month)) {
                                    temp.push(item.month);
                                    tempLabel = `(${tempLabel} - ${item.month.replace(/\b\w/g, (l) => { return l.toUpperCase() })})`;
                                }
                            }
                            return item;
                        });
                        setLabelText(tempLabel);
                    }
                    else if (view === 'month') {
                        res.map(item => {
                            if (item.month && item.year) {
                                item.month = item.month.replace(/\b\w/g, (l) => { return l.toUpperCase() });
                                if (temp.length === 0) {
                                    temp.push(item.year);
                                    tempLabel = item.year;
                                }
                                if (!temp.includes(item.year)) {
                                    temp.push(item.year);
                                    tempLabel = `(${tempLabel} - ${item.year})`;
                                }
                            }
                            return item;
                        });
                        setLabelText(tempLabel);
                    }
                    setData(res);
                    setLoading(false);
                }
            } catch (error) {
                console.log({ error });

            }
        }, [api, view, props.APIEndpoint]
    );

    const fetchClients = useCallback(
        async (clientIdArray) => {
            try {
                // setData(DATA);
                setClientsData([]);
                setTitleText(VARIANTS[view].reportText);
                const req = await api({
                    url: endpoints.VL_GET_CLIENTS,
                    method: 'POST',
                    data: clientIdArray
                });

                const res = await req.data;

                if (res) {
                    setLoading(false);
                    setClientsData(res);
                }
            } catch (error) {
                console.log({ error });

            }
        }, [api, view]
    );


    useEffect(() => {
        fetchSummary();
    }, [fetchSummary]);

    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 20;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = props.ReportName;
        const headers = [["Case ID", "First Name", "Last Name", "Email", "Phone"]];

        const data = clientsData.map(item => [item.case_id, item.first_name, item.last_name, item.email, item.phone]);

        const content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 20);
        doc.autoTable(content);
        doc.save("report.pdf")
    }

    const barClickHandler = (e) => {
        if (!e) return;
        setLoading(true);
        const { activePayload } = e;
        setSelected(getSelectedText(activePayload[0].payload))
        if (activePayload[0].payload.items) {
            fetchClients(activePayload[0].payload.items);
        }
    };

    const getSelectedText = (payload) => {
        if (view === 'day' && payload.day && payload.month) {
            return `${payload.day.match(/(\d+)/)[0]} ${payload.month.replace(/\b\w/g, (l) => { return l.toUpperCase() })}`;
        }
        else if (view === 'month' && payload.month) {
            return `${payload.month}`
        }
        else if (view === 'year' && payload.year) {
            return `${payload.year}`
        }
    }

    const columns = [
        {
            title: 'Case ID',
            dataIndex: 'case_id',
            render: (case_id) => case_id ? <Tag color="green">{case_id}</Tag> : <Tag color="red">CaseID missing</Tag>
        },
        {
            title: 'Name',
            dataIndex: 'first_name',
            render: (name, data) => `${name} ${data.middle_name ? data.middle_name : ''} ${data.last_name}`,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (email) => email ? <Tag color="green">{email}</Tag> : <Tag color="red">Email Missing</Tag>
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            render: (phone) => phone ? <Tag color="green">{phone}</Tag> : <Tag color="red">Phone Missing</Tag>
        },
    ];

    const viewHandler = (e) => {
        if (!e) return;
        setView(e.target.value);
        setLabelText('');
    };

    const clientsBarChart = (
        <ChartWrapper>
            <ResponsiveContainer>
                <BarChart data={data} onClick={barClickHandler}>
                    <XAxis dataKey={view} />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey={'total'} fill="#008ac8" maxBarSize={75} label="Something" />
                </BarChart>
            </ResponsiveContainer>
        </ChartWrapper>
    );

    const getSelectedReportType = () => {
        if (view === 'day') {
            if (clientsData.length === 0) {
                return 'Click on a Bar to Select Day';
            }
            else {
                return selected;
            }
        }
        else if (view === 'month') {
            if (clientsData.length === 0) {
                return 'Click on a Bar to Select Month';
            }
            else {
                return selected;
            }
        }
        else if (view === 'year') {
            if (clientsData.length === 0) {
                return 'Click on a Bar to Select Year';
            }
            else {
                return selected;
            }
        }
    }


    return (
        <>
            <ReportHeader>
                <StyledTitle level={4}>{props.ReportName} - {titleText}{labelText} </StyledTitle>
                <SwitchWrapper>
                    <Radio.Group value={view} onChange={viewHandler} disabled={loading}>
                        <Radio.Button value="day">Day</Radio.Button>
                        <Radio.Button value="month">Month</Radio.Button>
                        <Radio.Button value="year">Year</Radio.Button>
                    </Radio.Group>
                </SwitchWrapper>
            </ReportHeader>
            {clientsBarChart}
            <StyledTitle level={4} >
                Selected {view.replace(/\b\w/g, (l) => { return l.toUpperCase() })} - <LightSpan> {getSelectedReportType()}</LightSpan>
                <Button type="primary" icon={<DownloadOutlined />} size="small" onClick={() => exportPDF()} style={{ float: 'right' }}>
                    Download
                </Button>
            </StyledTitle>

            <ClientTable data={clientsData} columns={columns} loading={loading} />
        </>
    );
}

const SwitchWrapper = styled.div`
    margin-bottom: 25px;
    overflow: auto;

    .ant-radio-group {
        float: right;
    }
`;

const ReportHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ChartWrapper = styled.div`
    max-width: 100%;
    height: 300px;
    margin: 0px auto;
`;

const StyledTitle = styled(Title)`
    margin: 40px 0px 20px 0px;
`;

const LightSpan = styled.span`
    color: #858585;
`;

export default VerifilinkReport;
