import { useCallback, useEffect, useState } from 'react';
import ClientContext from './ClientContext';
import { useParams } from 'react-router-dom';
import { endpoints, useApi } from 'api';

const ClientProvider = ({ children }) => {
  const [clientDetails, setClientDetails] = useState({});
  const [loading, toggleLoading] = useState(false);
  const [api] = useApi();
  const { id, caseId } = useParams();

  useEffect(() => {
    if (id) fetchClientDetails({ id });
    if (caseId) fetchClientDetails({ caseId });
  }, [fetchClientDetails, id, caseId]);

  const fetchClientDetails = useCallback(async ({ id, caseId }) => {
    try {
      toggleLoading(true);
      let url = `${endpoints.CLIENTS}${id}`
      if (caseId) {
        url = `${endpoints.CLIENTS}case/${caseId}`
      }
      const req = await api({ url });
      const res = await req.data;
      toggleLoading(false);

      setClientDetails(res);
    } catch (error) {
      toggleLoading(false);
    }
  }, [api, id]);

  return (
    <ClientContext.Provider
      value={{
        clientDetails,
        loading,
        fetchClientDetails,
        toggleLoading,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export default ClientProvider;
