import { Card, Col } from 'antd';
import CountUp from 'react-countup';


function StatisticsCard(props) {
  return (
    <Col span={6} style={{padding: 8}}>
      <Card hoverable={props.hoverable} title={props.cardTitle}
        extra={props.hoverable ? < a href="">More</a> : ''}
        onClick={props.onClick}
        style={{ height: 200, width: '100%' }}>
        <div className='report-card'>
          <div>
            <CountUp end={props.value} duration={5} />
          </div>
        </div>
      </Card >
    </Col>
  )
}

export default StatisticsCard;