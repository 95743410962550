import styled from 'styled-components';

export const TableResponsive = styled.div`
    max-width: 100%;
    overflow-y: auto;

    .ant-table table {
        background-color: #fff;
    }
`;
