import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form';

import { Input, Modal, Select } from 'antd'
import styled from 'styled-components';

const UpdateDocument = ({ title, categoriesData, handleModalSubmit, document, show, setShow }) => {
  const { Option } = Select;
  const [selectedCategory, setSelectedCategory] = useState(document?.document_type?.category || null)
  const [selectedTypeId, setSelectedTypeId] = useState(document?.document_type?.id || '')
  const categoriesArr = [...new Set(categoriesData?.map(cat => cat.category))];
  const filteredTypes = categoriesData?.filter(el => el.category === selectedCategory)
  const {
    handleSubmit,
    setValue,
    control
  } = useForm();

  const handleCancel = () => {
    setShow(false);
  };

  const onSubmit = (data) => {
    const document = {
      document_type_id: selectedTypeId,
      title: data.title,
    }
    handleModalSubmit(document);
    handleCancel();
  };

  return (
    <>
      <Modal
        title={title}
        visible={show}
        centered
        onCancel={handleCancel}
        onOk={handleSubmit(onSubmit)}
        okText="Update"
        cancelText="Cancel"
      >
        <InputWrapper>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select document category"
            optionFilterProp="children"
            defaultValue={selectedCategory}
            onChange={(value) => {
              setSelectedCategory(value)
              setValue('title', '')
              setSelectedTypeId(null);
            }}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {categoriesArr?.length && categoriesArr?.map((cat, idx) =>
              <Option key={idx} value={cat}>{cat}</Option>
            )}
          </Select>
        </InputWrapper>
        {(filteredTypes && filteredTypes?.length > 0) &&
          <InputWrapper>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select document type"
              optionFilterProp="children"
              onChange={setSelectedTypeId}
              value={selectedTypeId}
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
              {filteredTypes?.map((el, idx) =>
                <Option key={idx} value={el.id}>{el.type}</Option>
              )}
            </Select>
          </InputWrapper>
        }
        
        <form onSubmit={handleSubmit(onSubmit)}>
          {selectedTypeId &&
            <InputWrapper>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Document title (optional)"
                    defaultValue={document?.title}
                    {...field}
                  />
                )}
              />
            </InputWrapper>
          }
        </form>
      </Modal>
    </>
  )
}

const InputWrapper = styled('div')`
  width: 100%;

  :not(:first-of-type) {
    margin: 24px 0;
  }
`;

export default UpdateDocument
