import PageNotFound from 'pages/NotFound/PageNotFound';
import { Switch, Route } from 'react-router-dom';
import { CLIENTS, CLIENT_DETAILS } from 'routes/paths';
import ClientProvider from './ClientContext/ClientProvider';
import ClientDetails from './ClientDetails';
import Clients from './Clients';

function ClientsRoot(props) {
  return (
    <Switch>
      <Route exact path={`${CLIENT_DETAILS}/:id`}>
        <ClientProvider>
          <ClientDetails />
        </ClientProvider>
      </Route>
      <Route exact path={`${CLIENT_DETAILS}/case/:caseId`}>
        <ClientProvider>
          <ClientDetails />
        </ClientProvider>
      </Route>
      <Route exact path={CLIENTS}>
        <Clients />
      </Route>
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
}

export default ClientsRoot;
