import React, { useState } from 'react'

import { Button, Modal } from 'antd'

const NotifyButton = ({ label, handleNotify, disabled }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onSubmit = (data) => {
    handleNotify(data);
    setVisible(false);
  };

  return (
    <>
      <Button type="primary" ghost onClick={showModal} disabled={disabled} >
        {label}
      </Button>
    <Modal
        title={`Send Email Notification?`}
        visible={visible}
        centered
        ghost="false"
        onCancel={handleCancel}
        onOk={onSubmit}
        okText="Send"
        cancelText="Cancel"
      >
      You will send email notification with a list of Requested and already Rejected documents that require upload
      </Modal>
    </>
  )
}

export default NotifyButton
