import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, Button, Tooltip } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { TableResponsive } from 'components/Styled'
import { useApi } from 'api';
import { useActions } from 'hooks/useAction';
import { formattedLocaleDateTime } from 'utilities/dateUtils';
import PreviewButton from 'components/TableActionButtons/PreviewButton/PreviewButton';
import RejectButton from 'components/TableActionButtons/RejectButton/RejectButton';
import AcceptButton from 'components/TableActionButtons/AcceptButton/AcceptButton';
import DeleteButton from 'components/TableActionButtons/DeleteButton/DeleteButton';
import UpdateDocument from './Documents/RequestDocumentModal/UpdateDocumentModal';
import UploadButton from 'components/TableActionButtons/UploadButton/UploadButton';
import { Link } from 'react-router-dom';
import { CLIENT_DETAILS } from 'routes/paths';


const ManagerDocumentsTable = ({ documents }) => {
  const [currentVisibleUpdateModal, setCurrentVisibleUpdateModal] = useState(0);
  const { categoriesData } = useSelector(state => state.categories);
  const [api] = useApi(true);
  const {
    fetchCategories,
    acceptDocument,
    deleteDocument,
    rejectDocument,
    uploadDocument,
    updateDocument,
  } = useActions();
  const [ordering, setOrdering] = useState('');

  useEffect(() => {
    fetchCategories(api);
  }, [api]);


  const handleTableChange = (a, b, sorting) => {
    let ordering = '';
    const { order, field } = sorting;
    if (order) {
      ordering = `${order === 'ascend' ? '' : '-'}${field}`;
    }
    setOrdering(ordering);
  };

  const handleDocumentAccept = (request_id, case_id) => {
    acceptDocument(api, case_id, request_id)
  }

  const handleDocumentDelete = (request_id, case_id) => {
    deleteDocument(api, case_id, request_id)
  }

  const handleDocumentReject = (request_id, reason, case_id) => {
    rejectDocument(api, case_id, request_id, reason)
  }

  const handleUploadDocuments = (request_id, file, case_id) => {
    uploadDocument(api, case_id, request_id, file)
  }

  const handleDocumentUpdate = (request_id, document, case_id) => {
    updateDocument(api, case_id, request_id, document)
  }

  const columns = [
    {
      title: '#',
      dataIndex: '#',
      render: (text, record, index) => index + 1,
      sorter: false,
    },
    {
      title: 'Case ID',
      dataIndex: 'case_id',
      render: (case_id) => (
        <Link to={`${CLIENT_DETAILS}/case/${case_id}`}>{case_id}</Link>
      ),
      sorter: false,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      render: (type, data) => data?.document_type.category,
      sorter: false,
      filters: [...new Map(categoriesData?.map(c => [c.category, {
        text: c.category,
        value: c.category
      }])).values()],
      onFilter: (value, record) => record?.document_type.category === value
    },
    {
      title: 'Document',
      dataIndex: 'type',
      render: (type, data) => data?.document_type.type + ' ' + (data?.title ? data.title : ''),
      filters: [...new Map(categoriesData?.map(c => [c.type, { text: c.type, value: c.type }])).values()],
      onFilter: (value, record) => record?.document_type.type === value,
      sorter: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => <Status>{status}</Status>,
      sorter: false,
    },
    {
      title: 'Requested',
      dataIndex: 'requested_at',
      render: (requested_at) => formattedLocaleDateTime(requested_at),
      sorter: false,
    },
    {
      title: 'Last Status Updated At',
      dataIndex: 'updated_at',
      render: (updated_at) => formattedLocaleDateTime(updated_at, '-'),
      sorter: false,
    },
    {
      title: 'Actions',
      align: 'center',
      dataIndex: 'actions',
      render: (_, { s3_file_url_presigned, id, status, title, document_type, case_id }) => {
        return (
          <>
            <UploadButton
              onUpload={file => handleUploadDocuments(id, file, case_id)}
              disabled={status === 'uploaded' || status === 'approved' || status === 'accepted'}
            />
            <PreviewButton
              previewLink={s3_file_url_presigned}
            />
            <AcceptButton
              onAcceptClick={() => handleDocumentAccept(id, case_id)}
              disabled={status !== 'uploaded'}
            />
            <RejectButton
              handleReject={reason => handleDocumentReject(id, reason, case_id)}
              disabled={status !== 'uploaded'}
            />
            <DeleteButton
              title={title}
              handleDelete={() => handleDocumentDelete(id, case_id)}
            />
            <Tooltip title="Update">
              <Button size="middle" icon={<FormOutlined />} type="text" onClick={() => {
                setCurrentVisibleUpdateModal(id)
              }} />
            </Tooltip>

            <UpdateDocument
              title='Update Document Request'
              categoriesData={categoriesData}
              show={currentVisibleUpdateModal === id}
              document={{ title, document_type, id }}
              setShow={(show) => setCurrentVisibleUpdateModal(show ? id : 0)}
              handleModalSubmit={(document) => handleDocumentUpdate(id, document)}
            />
          </>
        )
      },
      sorter: false,
    },
  ]

  return (
    <div>
      <TableActionButtons>

      </TableActionButtons>
      <TableWrapper>
        <TableResponsive>
          {documents && (
            <Table
              dataSource={documents}
              columns={columns}
              pagination={false}
              ordering={ordering}
              rowKey={'id'}
              onChange={handleTableChange}
            />
          )}
        </TableResponsive>
      </TableWrapper>
    </div>
  )
}

const TableActionButtons = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  button {
    border-radius: 4px;
    margin-left: 24px;
  }
`;

const TableWrapper = styled('div')`
  .ant-table {
    font-size: 12px;
  }

  .ant-pagination {
    text-align: right;
    margin: 16px 0;
  }
`;

const Status = styled('span')`
  text-transform: capitalize;
`;

const NotifyText = styled('span')`
  margin: 0 8px;
  color: #808080;
`;

export default ManagerDocumentsTable;
