import { Switch, Route } from 'react-router-dom';
import { DASHBOARD } from 'routes/paths';
import Dashboard from './Dashboard';

function DashboardRoot() {
    return (
        <Switch>
            <Route exact path={DASHBOARD}>
                <Dashboard />
            </Route>
        </Switch>
    );
}

export default DashboardRoot;
