import { Switch, Route } from 'react-router-dom';
import { CLIENT_PORTAL_INVITATION } from 'routes/paths';
import CPInvitation from './CPInvitation';

function CPSignup(props) {
    return (
        <Switch>
            <Route exact path={CLIENT_PORTAL_INVITATION}>
                <CPInvitation />
            </Route>
        </Switch> 
    );
}

export default CPSignup;
