import { PageHeader, Statistic, Card, Row, Col } from 'antd';
import { Fragment } from 'react';

function PaymentHeader(props) {
    return (
        <Fragment>
            <PageHeader onBack={null} title="Manual Payment" />
            <Row gutter={16} style={{ paddingBottom: 20 }}>
                <Col>
                    <Card>
                        <Statistic
                            title="Total Successful Transactions"
                            value={0}
                            prefix="$"
                            valueStyle={{ color: '#3f8600' }}
                        />
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Statistic
                            title="Total Declined Transactions"
                            value={0}
                            prefix="$"
                            valueStyle={{ color: '#cf1322' }}
                        />
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
}

export default PaymentHeader;
