import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Button, Comment, Form, Input, List } from 'antd';
import { useApi } from 'api';
import { useActions } from 'hooks/useAction';
import ClientContext from '../ClientContext/ClientContext';
import { useContext, useRef } from "react";
import { defaultIcon, icon } from 'assets/img';


const { TextArea } = Input;

const CommentList = ({ comments, client }) => {
  const messagesEndRef = useRef(null)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [comments]);

  return (
    <List
      style={{
        overflow: 'auto',
        height: '300px',
        flexDirection: 'column-reverse',
      }}
      dataSource={comments}
      header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
      itemLayout="horizontal"
      renderItem={(item) => <Comment
        content={item.message}
        author={item.direction == 'outbound' ? 'TaxRise' : client ? client.first_name + ' ' + client.last_name : 'Client'}
        avatar={item.direction == 'outbound' ? <Avatar src={icon} style={{ backgroundColor: '#f0f2f5' }} /> : <Avatar src={defaultIcon} />}
        datetime={new Date(item.created_at).toLocaleTimeString() + ' ' + new Date(item.created_at).toLocaleDateString()}
      />}
      footer={<div ref={messagesEndRef} />}
    />
  );
}


const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        Send
      </Button>
    </Form.Item>
  </>
);

const Chat = () => {
  const { clientDetails } = useContext(ClientContext);
  const phone = clientDetails ? clientDetails.phone : null;
  const [body, setBody] = useState('');

  const { data, sending } = useSelector(state => state.chat);

  const [api] = useApi(true);

  const handleSubmit = () => {
    if (!body) {
      return;
    }

    postMessage(api, phone, body);

    setBody('');
  };

  const handleChange = (e) => {
    setBody(e.target.value);
  };

  const {
    fetchChatHistory,
    postMessage,
  } = useActions();

  useEffect(() => {
    fetchChatHistory(api, phone)
    
    const id = setInterval(() => !sending ? fetchChatHistory(api, phone) : null, 7000);

    return () => clearInterval(id);  
  }, []);

  return (
    <>
      <CommentList comments={data} client={clientDetails} />
      <Comment
        avatar={<Avatar src={icon} style={{ backgroundColor: '#f0f2f5' }} />}
        content={
          <Editor
            onChange={handleChange}
            onSubmit={handleSubmit}
            value={body}
          />
        }
      />
    </>
  )
}

export default Chat;
