import { Spin, Layout, Tabs } from 'antd';
import { Fragment, useContext } from 'react';
import ClientProfile from './ClientProfile';
import ClientContext from '../ClientContext/ClientContext';
import FinancialTransaction from '../FinancialTransaction';
import DocumentsAuditLog from '../DocumentsAuditLog/DocumentsAuditLog';
import Documents from '../Documents/Documents';
import Chat from '../Chat/Chat';

const { TabPane } = Tabs;

const { Content } = Layout;

function ClientDetails(props) {
  const { loading } = useContext(ClientContext);

  return (
    <Fragment>
      <Spin spinning={loading}>
        <ClientProfile />

        <Content
          style={{
            padding: '16px 24px',
            backgroundColor: 'white',
            marginTop: 20,
          }}
        >
          <Tabs defaultActiveKey="documents" type="card">
            <TabPane tab="Documents" key="documents">
              <Documents />
            </TabPane>
            <TabPane tab="Financial" key="financial">
              <FinancialTransaction />
            </TabPane>
            <TabPane tab="Events Log" key="log">
              <DocumentsAuditLog />
            </TabPane>
            <TabPane tab="Chat" key="chat">
              <Chat />
            </TabPane>
          </Tabs>
        </Content>
      </Spin>
    </Fragment>
  );
}

export default ClientDetails;
