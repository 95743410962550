/* eslint-disable react/display-name */
import {
    PageHeader,
    Table,
    Tag,
    Button,
    Tooltip,
    Input,
    Pagination,
    message,
} from 'antd';
import {
    CalendarOutlined,
    CheckOutlined,
    DisconnectOutlined,
    MailOutlined,
} from '@ant-design/icons';
import { endpoints, useApi } from 'api';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CLIENT_DETAILS } from 'routes/paths';
import { TYPE_ADMIN } from 'const';
import { useClientUpdate, useSendResetPasswordEmail } from 'hooks';
import styled from 'styled-components';
import { formateDateTime } from 'library';
import { TableResponsive } from 'components/Styled';
import { DateRangeFilter } from 'components/TableFilters';

const { Search } = Input;

const getSendPasswordRedirectUrl = function () {
    if (endpoints.REACT_APP_API_ENDPOINT.includes('staging')) {
        return 'https://staging-client.taxrise.com'
    }
    else return 'https://client.taxrise.com'
}

function Clients() {
    const [clientsData, setClientsData] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [loading, toggleLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [ordering, setOrdering] = useState('');
    const [api] = useApi();

    useEffect(() => {
        fetchClients();
    }, [fetchClients, filters, ordering]);

    const fetchClients = useCallback(
        async (pagination = { current: 1, pageSize: 10 }) => {
            try {
                toggleLoading(true);
                const { current, pageSize } = pagination;

                const req = await api({
                    url: endpoints.CLIENTS,
                    params: {
                        user_type: 50,
                        ...filters,
                        page: current - 1,
                        size: pageSize,
                        ordering,
                    },
                });
                const res = await req.data;

                toggleLoading(false);

                setClientsData(res.items);
                setPagination({
                    ...pagination,
                    total: res.total,
                });
            } catch (error) {
                toggleLoading(false);
            }
        },
        [api, filters, ordering]
    );

    const [updateClient] = useClientUpdate(
        () => fetchClients(pagination),
        null,
        toggleLoading
    );

    const [sendResetPasswordEmail] = useSendResetPasswordEmail(
        () => message.success('Password reset email sent'),
        null,
        null,
    )

    const onPageChange = (page, pageSize) => {
        const newPage = {
            current: page,
            pageSize,
        };

        setPagination((curPageData) => ({
            ...curPageData,
            ...newPage,
        }));

        fetchClients(newPage);
    };

    const onSearch = (searchQuery) => {
        setFilters((existingFilters) => ({
            ...existingFilters,
            q: searchQuery || undefined,
        }));
    };

    const filterByDateRange = (dates, filterKeys) => {
        let startDate = null;
        let endDate = null;

        if (dates) {
            startDate = `${formateDateTime(dates[0], 'YYYY-MM-DD')}T00:00:00`;
            endDate = `${formateDateTime(dates[1], 'YYYY-MM-DD')}T23:59:59`;
        }

        const filter = {
            [filterKeys[0]]: startDate,
            [filterKeys[1]]: endDate,
        };

        setFilters((existingFilters) => ({
            ...existingFilters,
            ...filter,
        }));
    };

    const columns = [
        {
            title: 'Case ID',
            dataIndex: 'case_id',
            render: (caseId, data) => (
                <Link to={`${CLIENT_DETAILS}/${data.id}`}>{caseId}</Link>
            ),
            sorter: true,
        },
        {
            title: 'Name',
            dataIndex: 'first_name',
            width: '200px',
            sorter: true,
            render: (name, data) =>
                `${name} ${data.middle_name} ${data.last_name}`,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: true,
            render: (email) => <a href={`mailto:${email}`}>{email}</a>,
        },
        {
            title: 'Joined Date',
            dataIndex: 'date_joined',
            width: '170px',
            sorter: true,
            render: (date) =>
                date ? formateDateTime(`${date}+00`, 'lll') : '',
            filterIcon: (filtered) => (
                <CalendarOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
            filterDropdown: (filterProps) => (
                <DateRangeFilter
                    {...filterProps}
                    dataIndex="date_joined"
                    onChange={filterByDateRange}
                    filterKeys={['date_joined_gte', 'date_joined_lte']}
                />
            ),
        },
        {
            title: 'Last Login',
            dataIndex: 'last_login',
            width: '170px',
            sorter: true,
            render: (date) =>
                date ? formateDateTime(`${date}+00`, 'lll') : '',
            filterIcon: (filtered) => (
                <CalendarOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
            filterDropdown: (filterProps) => (
                <DateRangeFilter
                    {...filterProps}
                    dataIndex="last_login"
                    onChange={filterByDateRange}
                    filterKeys={['last_login_gte', 'last_login_lte']}
                />
            ),
        },
        {
            title: 'Email Verified',
            dataIndex: 'is_email_verified',
            width: '100px',
            align: 'center',
            sorter: true,
            render: (active) =>
                active ? (
                    <Tag color="green">Yes</Tag>
                ) : (
                    <Tag color="orange">No</Tag>
                ),
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            width: '100px',
            align: 'center',
            sorter: true,
            render: (active) =>
                active ? (
                    <Tag color="green">Active</Tag>
                ) : (
                    <Tag color="orange">Inactive</Tag>
                ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            align: 'right',
            width: '150px',
            render: (action, { is_active, id, type, case_id, email }) => {
                if (type == TYPE_ADMIN) return;

                return (
                    <Fragment>
                        <Tooltip title="Active Client">
                            <Button
                                size="small"
                                icon={<CheckOutlined />}
                                type="primary"
                                onClick={() =>
                                    updateClient(id, { is_active: true })
                                }
                                disabled={is_active}
                            />
                        </Tooltip>
                        <Tooltip title="Inactive Client">
                            <Button
                                style={{ marginLeft: 5 }}
                                size="small"
                                icon={<DisconnectOutlined />}
                                type="default"
                                onClick={() =>
                                    updateClient(id, { is_active: false })
                                }
                                disabled={!is_active}
                            />
                        </Tooltip>
                        &nbsp; &nbsp;| &nbsp;
                        <Tooltip title="Send Password Reset Email">
                            <Button
                                style={{ marginLeft: 5 }}
                                size="small"
                                icon={<MailOutlined />}
                                type="default"
                                onClick={() =>
                                    sendResetPasswordEmail({ case_id: case_id, email: email, email_app_name: 'Taxrise', redirect_url: `${getSendPasswordRedirectUrl()}/forgot-password/reset-password` })
                                }
                            />
                        </Tooltip>
                    </Fragment>
                );
            },
        },
    ];


    const handleTableChange = (a, b, sorting) => {
        let ordering = '';

        const { order, field } = sorting;

        if (order) {
            ordering = `${order === 'ascend' ? '' : '-'}${field}`;
        }

        setOrdering(ordering);
    };

    return (
        <Fragment>
            <PageHeader onBack={null} title="Clients" />
            <SearchWrap>
                <p className="title">
                    {filters['q'] && (
                        <span>
                            Search result for: <strong>{filters['q']}</strong>
                        </span>
                    )}
                </p>
                <Search
                    className="table-search"
                    placeholder="Search"
                    onSearch={onSearch}
                    enterButton
                    allowClear
                />
            </SearchWrap>
            <TableWrapper>
                <TableResponsive>
                    <Table
                        loading={loading}
                        dataSource={clientsData}
                        columns={columns}
                        rowKey={'id'}
                        pagination={false}
                        onChange={handleTableChange}
                    />
                </TableResponsive>

                <Pagination
                    defaultCurrent={1}
                    total={pagination.total}
                    current={pagination.current}
                    onChange={onPageChange}
                />
            </TableWrapper>
        </Fragment>
    );
}

const SearchWrap = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px 10px;

    .title {
        margin-bottom: 0;
        order: 1;
    }

    .title:empty {
        height: 0px;
    }

    .table-search {
        max-width: 350px;
        order: -1;
        margin-bottom: 10px;
    }

    @media (min-width: 767px) {
        flex-direction: row;

        .table-search {
            order: 1;
            margin-bottom: 0;
        }
    }
`;

const TableWrapper = styled.div`
    .ant-table {
        font-size: 12px;
    }

    .ant-pagination {
        text-align: right;
        margin: 16px 0;
    }
`;

export default Clients;
