import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const DateRangeFilter = ({
    setSelectedKeys,
    confirm,
    dataIndex,
    onChange,
    filterKeys,
    disabled,
}) => {
    const changeHandler = (dates) => {
        if (dates) {
            setSelectedKeys([dataIndex]);
        } else {
            setSelectedKeys([]);
        }

        if (onChange) onChange(dates, filterKeys);
        confirm();
    };

    return (
        <RangePicker
            onChange={changeHandler}
            placeholder={['YYYY-MM-DD', 'YYYY-MM-DD']}
            disabled={disabled}
        />
    );
};

export default DateRangeFilter;
