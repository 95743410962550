import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form';

import { Button, Input, Modal, Select } from 'antd'

import styled from 'styled-components';

const RequestDocument = ({ title, categoriesData, handleRequestDocument }) => {
  const { Option } = Select;
  const [visible, setVisible] = useState(false);
  const [ selectedCategory, setSelectedCategory ] = useState('')
  const [ selectedTypeId, setSelectedTypeId ] = useState('')

  const categoriesArr = [ ...new Set(categoriesData?.map(cat => cat.category)) ];
  const filteredTypes = categoriesData?.filter(el => el.category === selectedCategory)

  const {
    reset,
    handleSubmit,
    setValue,
    control
  } = useForm();

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onSubmit = (data) => {
    const documents = [{
      document_type_id: selectedTypeId,
      title: data.title,
    }]
    
    handleRequestDocument(documents);
    setSelectedCategory('');
    setSelectedTypeId('');
    reset();
    handleCancel();
  };

  return (
    <>
    <Button type="primary" ghost onClick={showModal}>
      {title}
    </Button>
    <Modal
        title="Request a Document"
        visible={visible}
        centered
        onCancel={handleCancel}
        onOk={handleSubmit(onSubmit)}
        okText="Request"
        cancelText="Cancel"
      >
      <InputWrapper>
        <Select
          showSearch
          style={{ width: '100%' }}
          placeholder="Select document category"
          optionFilterProp="children"
          onChange={(value) => {
            setSelectedCategory(value)
            setValue('title', '')
            setSelectedTypeId(null);
          }}
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        >
          {categoriesArr?.length && categoriesArr?.map((cat, idx) =>
            <Option key={idx} value={cat}>{cat}</Option>
          )}
        </Select>
      </InputWrapper>
      {(filteredTypes && filteredTypes?.length > 0) &&
        <InputWrapper>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select document type"
            optionFilterProp="children"
            onChange={setSelectedTypeId}
            value={selectedTypeId}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {filteredTypes?.map((el, idx) => 
              <Option key={idx} value={el.id}>{el.type}</Option>
            )}
          </Select>
        </InputWrapper>
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        {selectedTypeId &&
          <InputWrapper>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <Input
                placeholder="Document title (optional)"
                {...field}
                />
              )}
            />
          </InputWrapper>
        }
      </form>
      </Modal>
    </>
  )
}

const InputWrapper = styled('div')`
  width: 100%;

  :not(:first-of-type) {
    margin: 24px 0;
  }
`;

export default RequestDocument
