/* eslint-disable react/display-name */
import {
    PageHeader,
    Typography,
    Button,
    Tooltip,
    Input,
    message,
    Space,
} from 'antd';
import {
    CalendarOutlined,
    CheckOutlined,
    DisconnectOutlined,
    MailOutlined,
} from '@ant-design/icons';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {endpoints, useApi} from 'api';
import {Fragment, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {ErrorMessage, Formik} from "formik";
import {PasswordInput} from "../../../components/FormInput";
import * as Yup from "yup";
import {
    CHARACTERS_TYPE_PATTERN,
    FORBIDDEN_SPECIAL_SYMBOLS_PATTERN,
    LETTER_CASES_PATTERN,
    SPECIAL_SYMBOLS_PATTERN
} from "../../../const";

const {Search} = Input;
const {Title} = Typography;

const initialValues = {
    old_password: '',
    new_password: '',
    confirm_new_password: '',
};

function ChangePassword() {
    const [api] = useApi();

    const [initialInfo, updateInitialInfo] = useState(initialValues);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    const updatePassword = async (data, {resetForm}) => {
        console.log(data)
        // data['case_id'] = case_id;
        try {
            const req = await api({
                method: 'POST',
                url: endpoints.CHANGE_PASSWOR_API_URL,
                data,
            });

            const res = await req.data;

            if (res) {
                setShowSuccess(true);
                setErrorMessage(false)
                message.success(res.msg)

                resetForm({
                    old_password: '',
                    new_password: '',
                    confirm_new_password: '',
                })
            }
        } catch (e) {
            setShowSuccess(true);
            setErrorMessage(JSON.parse(e.response.data.detail).msg)
            message.error(JSON.parse(e.response.data.detail).msg)
        }
    };

    return (
        <Fragment>
            <PageHeader onBack={null} title="Change Password"/>

            <Formik
                initialValues={initialInfo}
                onSubmit={updatePassword}
                validationSchema={validationRules}
                validateOnMount
            >
                {({isValid, handleSubmit, isSubmitting}) => (
                    <form onSubmit={handleSubmit}>
                        <PasswordInput
                            name="old_password"
                            label="Current Password"
                        />
                        <ErrorMessage name="old_password"/>
                        <PasswordInput
                            name="new_password"
                            label="New Password"
                        />
                        <ErrorMessage name="new_password"/>
                        <PasswordInput
                            name="confirm_new_password"
                            label="Re-Type New Password"
                        />
                        <ErrorMessage name="confirm_new_password"/>
                        <Button
                            block
                            type="primary"
                            htmlType="submit"
                            className="mt-16 mb-20"
                            disabled={!isValid}
                            loading={isSubmitting}
                            onClick={handleSubmit}
                        >
                            Update Password
                        </Button>
                    </form>
                )}
            </Formik>
        </Fragment>
    );
}


const validationRules = Yup.object().shape({
    old_password: Yup.string().required('Please enter current password'),
    new_password: Yup.string()
        .required('No password provided.')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(LETTER_CASES_PATTERN, 'A mixture of both uppercase and lowercase letters')
        .matches(CHARACTERS_TYPE_PATTERN, 'A mixture of letters and numbers')
        .matches(SPECIAL_SYMBOLS_PATTERN, 'Inclusion of at least one special character, e.g.,!@#]')
        .matches(FORBIDDEN_SPECIAL_SYMBOLS_PATTERN, 'Do not use < or > in your password!'),
    confirm_new_password: Yup.string()
        .required('No password provided.')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .oneOf([Yup.ref('new_password'), null], 'Password must match'),
});

export default ChangePassword;
