import { endpoints } from 'api';


export const fetchAllDocuments = (api, page=1, limit=100, status = null, 
                                  category = null, document_type_id = null, case_id = null) => {
  return async (dispatch) => {
    try {
      let queryString = `?page=${page}&limit=${limit}`
      if (status) {
        queryString = queryString + `&status=${status}`
      }
      if (category) {
        queryString = queryString + `&category=${category}`
      }
      if (document_type_id) {
        queryString = queryString + `&document_type_id=${document_type_id}`
      }
      if (case_id) {
        queryString = queryString + `&case_id=${case_id}`
      }
      dispatch({ type: 'FETCH_ALL_DOCUMENTS' });
      const { data } = await api({url: `${endpoints.UPLOADED_DOCUMENTS}${queryString}`});

      dispatch({
        type: 'FETCH_ALL_DOCUMENTS_SUCCESS',
        payload: data,
      });

    } catch (error) {
      dispatch({
        type: 'FETCH_ALL_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};


export const acceptDocumentFromAllDocs = (api, case_id, request_id, reloadDocsQuery) => {
  return async (dispatch) => {
    try {
      const res = await api({
        method: 'put',
        url: `${endpoints.DOCUMENTS}/v1/cases/${case_id}/requests/${request_id}/`,
        data: {
          status: 'approved',
        }
      });

      if (res.status === 200 && reloadDocsQuery) {
        const { status, category, document_type_id} = reloadDocsQuery
        dispatch(fetchAllDocuments(api, 1, 100, status, category, document_type_id))
      }

    } catch (error) {
      dispatch({
        type: 'FETCH_ALL_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const deleteDocumentFromAllDocs = (api, case_id, request_id, reloadDocsQuery) => {
  return async (dispatch) => {
    try {
      const res = await api({
        method: 'delete',
        url: `${endpoints.DOCUMENTS}/v1/cases/${case_id}/requests/${request_id}/`,
      });

      if (res.status === 200 && reloadDocsQuery) {
        const { status, category, document_type_id} = reloadDocsQuery
        dispatch(fetchAllDocuments(api, 1, 100, status, category, document_type_id))
      }

    } catch (error) {
      dispatch({
        type: 'FETCH_ALL_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const rejectDocumentFromAllDocs = (api, case_id, request_id, { rejection_description }, reloadDocsQuery) => {
  return async (dispatch) => {
    try {
      const res = await api({
        method: 'put',
        url: `${endpoints.DOCUMENTS}/v1/cases/${case_id}/requests/${request_id}/`,
        data: {
          status: 'rejected',
          rejection_description
        }
      });

      if (res.status === 200 && reloadDocsQuery) {
        const { status, category, document_type_id} = reloadDocsQuery
        dispatch(fetchAllDocuments(api, 1, 100, status, category, document_type_id))
      }

    } catch (error) {
      dispatch({
        type: 'FETCH_ALL_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const updateDocumentFromAllDocs = (api, case_id, request_id, document, reloadDocsQuery) => {
  return async (dispatch) => {
    try {
      const res = await api({
        method: 'put',
        url: `${endpoints.DOCUMENTS}/v1/cases/${case_id}/requests/${request_id}/`,
        data: document
      });

      if (res.status === 200 && reloadDocsQuery) {
        const { status, category, document_type_id} = reloadDocsQuery
        dispatch(fetchAllDocuments(api, 1, 100, status, category, document_type_id))
      }

    } catch (error) {
      dispatch({
        type: 'FETCH_ALL_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

