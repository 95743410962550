const initialState = {
  data: [],
  loading: false,
  sending: false
};

export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_CHAT_HISTORY':
      return { ...state, loading: true };
    case 'FETCH_CHAT_HISTORY_SUCCESS':
      return { ...state, loading: false, data: action.payload };
    case 'POST_CHAT_MESSAGE':
      return { ...state, sending: true, data: state.data.concat(action.payload) };
    case 'POST_CHAT_MESSAG_SUCCEED':
      return { ...state, sending: false };
    default:
      return state;
  }
}
