import { useMemo } from 'react';
import { InputNumber } from 'antd';
import { useField } from 'formik';
import { FieldWrapper } from 'components/Wrapper';
import styled from 'styled-components';

function NumberInput({ label, required, onChange, ...props }) {
    const [field, meta, helper] = useField(props);
    const { touched, error } = meta;
    const { setValue } = helper;

    const isInvalid = useMemo(() => {
        return !!(touched && error);
    }, [touched, error]);

    const changeHandler = (val) => {
        setValue(val);

        if (onChange) onChange(val);
    };

    return (
        <FieldWrapper
            isInvalid={isInvalid}
            label={label}
            required={required}
            error={error}
        >
            <NumberField {...field} {...props} onChange={changeHandler} />
        </FieldWrapper>
    );
}

const NumberField = styled(InputNumber)`
    width: 100%;
`;

export default NumberInput;
