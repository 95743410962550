import { endpoints } from 'api';
const FROM_NUMBER = '+19494385711';

const MESSAGES_PREFIX = 'Taxrise MSG: '

export const fetchChatHistory = (api, phone) => {
  return async (dispatch) => {

    try {
      dispatch({ type: 'FETCH_CHAT_HISTORY' });

      const { data } = await api({
        url: `${endpoints.CHAT}/sms?phone=${phone}&app=client-portal`,
      });

      const messages = data.map((m) => {
        const { message, direction, created_at } = m;

        return {
          message: message.replace(MESSAGES_PREFIX, ''),
          direction,
          created_at,
        };
      });

      dispatch({
        type: 'FETCH_CHAT_HISTORY_SUCCESS',
        payload: messages,
      });

    } catch (error) {
      dispatch({
        type: 'FETCH_CHAT_HISTORY_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const postMessage = (api, to, body) => {
  return async (dispatch) => {

    try {
      const requestData = {
        to,
        from: FROM_NUMBER,
        body: MESSAGES_PREFIX + body,
        carrier: 'twilio',
        app: 'client-portal',
        p2p: true,
      };

      dispatch({
        type: 'POST_CHAT_MESSAGE', payload: {
          message: body,
          direction: 'outbound',
          created_at: new Date().toISOString(),
        } });

      const { data } = await api({
        method: 'post',
        url: `${endpoints.CHAT}/sms/outbound`,
        data: requestData,
      });

      dispatch({
        type: 'POST_CHAT_MESSAG_SUCCEED',
        payload: data,
      });

    } catch (error) {
      dispatch({
        type: 'POST_CHAT_MESSAG_ERROR',
        payload: error?.message || error,
      });
    }
  };
};
