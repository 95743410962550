import { useState } from 'react';
import { Layout } from 'antd';
import Navigation from './Navigation';
import Header from './Header';
import Footer from './Footer';
import styled from 'styled-components';

const { Content } = Layout;

function AppLayout({ children }) {
    const [collapsed, toggleCollapsed] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const collapseHandler = (collapse) => {
        toggleCollapsed(collapse);
        setIsMobile(collapse && window.innerWidth < 578);
    };

    return (
        <Layout>
            <Navigation onCollapseTrigger={collapseHandler} />
            <Body collapsed={collapsed ? 1 : 0} ismobile={isMobile ? 1 : 0}>
                <Header />
                <ContentArea>
                    <Wrapper>{children}</Wrapper>
                </ContentArea>
                <Footer />
            </Body>
        </Layout>
    );
}

const Wrapper = styled.section`
    padding: 24px;
    min-height: calc(100vh - 158px);

    @media (max-width: 576px) {
        padding: 5px;
    }
`;

const Body = styled(Layout)`
    margin-left: ${({ collapsed, ismobile }) =>
        collapsed ? (ismobile ? '0px' : '80px') : '200px'};
`;

const ContentArea = styled(Content)`
    margin: 24px 16px 0;
`;

export default AppLayout;
