import { useMemo } from 'react';
import { Checkbox } from 'antd';
import { useField } from 'formik';
import { FieldWrapper } from 'components/Wrapper';

function CheckboxInput({ label, required, onChange, ...props }) {
    const [field, meta, helper] = useField(props);
    const { touched, error } = meta;
    const { name, value } = field;
    const { setValue } = helper;

    const isInvalid = useMemo(() => {
        return !!(touched && error);
    }, [touched, error]);

    const changeHandler = (e) => {
        const val = e.target.checked;

        setValue(val);

        if (onChange) onChange(val);
    };

    return (
        <FieldWrapper
            isInvalid={isInvalid}
            required={required}
            error={error}
            hideLabel
        >
            <Checkbox
                name={name}
                checked={value}
                {...props}
                onChange={changeHandler}
            >
                {label}
            </Checkbox>
        </FieldWrapper>
    );
}

export default CheckboxInput;
