import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';

const TransactionBarChart = ({ data, onClick }) => {
    return (
        <div style={{ maxWidth: '100%', height: 550 }}>
            <ResponsiveContainer>
                <BarChart data={data} onClick={onClick}>
                    <XAxis dataKey={'label'} />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey={'amount'} fill="#008ac8" maxBarSize={75} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default TransactionBarChart;
