import { combineReducers } from 'redux';
import { categoriesReducer } from './DocumentCategories/reducer';
import { documentsReducer } from './Documents/reducer';
import { allDocumentsReducer } from './AllDocuments/reducer';
import { mamangerDocumentsReduced } from './ManagerDocuments/reducer';
import { documentsAuditLogReducer } from './DocumentsAuditLog/reducer';
import { chatReducer } from './Chat/reducer';

export const rootReducer = combineReducers({
  documents: documentsReducer,
  allDocuments: allDocumentsReducer,
  categories: categoriesReducer,
  managerDocuments: mamangerDocumentsReduced,
  documentsAuditLog: documentsAuditLogReducer,
  chat: chatReducer,
});