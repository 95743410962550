import React, { useState } from 'react'
import { Tooltip, Button, Modal, Descriptions } from 'antd'
import { EyeFilled } from '@ant-design/icons';

import JsPDF from 'jspdf'

import styled from 'styled-components';

const PreviewTaxOrganizerButton = ({ handleReject, disabled, taxOrganizerData }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const SPOUSE_INFO_FILING_STATUSES = [
    'Married Filing Jointly',
    'Married Filing Separately',
    'Qualifying Widow(er)',
  ]

  // TODO: Add all fields
  // const sections = [
  //   {
  //     title: 'Taxpayer Info',
  //     show: !!taxOrganizerData.first_name,
  //     fields: [
  //       { label: 'First Name', value: taxOrganizerData.first_name },
  //       { label: 'Last Name', value: taxOrganizerData.last_name },
  //       { label: 'Occupation', value: taxOrganizerData.occupation },
  //       { label: 'Were you legally blind', value: taxOrganizerData.is_legally_blind },
  //       { label: 'Were you permanently and totally disabled', value: taxOrganizerData.is_totally_disabled },
  //       { label: 'Is Taxpayer dependent', value: taxOrganizerData.is_dependent },
  //       { label: 'Was the taxpayer a full-time student', value: taxOrganizerData.is_full_time_student },
  //       { label: 'Comment', value: taxOrganizerData.comment_personal_taxpayer_info },
  //     ],
  //   },
  //   {
  //     title: 'Address',
  //     show: !!taxOrganizerData.street_address,
  //     fields: [
  //       { label: 'Street Address', value: taxOrganizerData.street_address },
  //       { label: 'Apartment', value: taxOrganizerData.apartment },
  //       { label: 'City', value: taxOrganizerData.city },
  //       { label: 'State', value: taxOrganizerData.state },
  //       { label: 'Zip Code', value: taxOrganizerData.zip },
  //       { label: 'Country', value: taxOrganizerData.country },
  //       { label: 'Comment', value: taxOrganizerData.comment_personal_address },
  //     ],
  //   },
  //   {
  //     title: 'Filing Status',
  //     show: !!taxOrganizerData.filing_status,
  //     fields: [
  //       { label: 'Filing Status', value: taxOrganizerData.filing_status },
  //       ...(SPOUSE_INFO_FILING_STATUSES.includes(taxOrganizerData.filing_status) ? [
  //         { label: 'Spouse First Name', value: taxOrganizerData.spouse_first_name },
  //         { label: 'Spouse Last Name', value: taxOrganizerData.spouse_last_name },
  //         { label: 'Spouse Occupation', value: taxOrganizerData.spouse_occupation },
  //         { label: 'Spouse Were you legally blind', value: taxOrganizerData.spouse_is_legally_blind },
  //         { label: 'Spouse Were you permanently and totally disabled', value: taxOrganizerData.spouse_is_totally_disabled },
  //         { label: 'Spouse Is Taxpayer dependent', value: taxOrganizerData.spouse_is_dependent },
  //         { label: 'Spouse Was the taxpayer a full-time student', value: taxOrganizerData.spouse_is_full_time_student },
  //         { label: 'Spouse Comment', value: taxOrganizerData.spouse_comment_personal_taxpayer_info },
  //       ] : []),
  //     ],
  //   },
  //   {
  //     title: 'Dependents',
  //     show: !!taxOrganizerData.dependents,
  //     fields: [
  //       { label: 'Comment', value: taxOrganizerData.comment_dependents },
  //       ...(taxOrganizerData.dependents.reduce((dependentFields, dependent) => {
  //         return dependentFields + [
  //           { label: 'First Name', value: dependent.first_name },
  //           { label: 'Last Name', value: dependent.last_name },
  //           { label: 'SSN/ITNN', value: dependent.ssn },
  //           { label: 'Date of Birth', value: dependent.date_of_birth },
  //           { label: 'Relationship', value: dependent.relationship },
  //           { label: 'Months Living With You', value: dependent.months_living_with },
  //           { label: 'Has Income', value: dependent.has_income },
  //           { label: 'Income Amount', value: dependent.income_amount },
  //         ]
  //       })),
  //     ],
  //   },
  //   {
  //     title: 'Health Insurance',
  //     show: !!taxOrganizerData.has_health_insurance,
  //     fields: [
  //       { label: 'Health Insurance Type', value: taxOrganizerData.health_insurance_type },
  //       { label: 'Do you have health insurance for the whole year?', value: taxOrganizerData.health_insurance_is_whole_year },
  //       ...(taxOrganizerData.is_health_insurance_whole_year === 'No' ? [
  //         { label: 'What months did you have health insurance?', value: taxOrganizerData.health_insurance_months },
  //       ] : []),
  //       { label: 'Comment', value: taxOrganizerData.comment_health_insurance },
  //     ],
  //   }        
  // ]

  const generatePDF = () => {
    const report = new JsPDF('portrait', 'px', [1000, 1410], true);
    report.html(document.querySelector('.ant-modal-body')).then(() => {
      report.save('report.pdf');
    });
  };

  return (
    <>
      <Tooltip title="Preview">
        <RejectBtn size="middle" icon={<EyeFilled />} type="text" onClick={showModal} disabled={disabled} />
      </Tooltip>
      <Modal
        title="Tax Organizer Preview"
        visible={visible}
        width={1000}
        centered
        footer={
          <>
            <Button type="primary" onClick={generatePDF}>
              Download
            </Button>
            <Button type="primary" onClick={handleCancel}>
              OK
            </Button>
          </>
        }
        cancelText="Cancel"
        onCancel={handleCancel}
      >
        {taxOrganizerData && (
          <div id={"table"}>
            <Descriptions title="Taxpayer Info" bordered size='small'>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="First Name" span={3}>{taxOrganizerData.first_name}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Last Name" span={3}>{taxOrganizerData.last_name}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Occupation" span={3}>{taxOrganizerData.occupation}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Were you legally blind" span={3}>{taxOrganizerData.is_legally_blind}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Were you permanently and totally disabled" span={3}>{taxOrganizerData.is_totally_disabled}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Is Taxpayer dependent" span={3}>{taxOrganizerData.is_dependent}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Was the taxpayer a full-time student" span={3}>{taxOrganizerData.is_full_time_student}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_personal_taxpayer_info}</Descriptions.Item>
            </Descriptions>

            <Descriptions title="Address" bordered size='small'>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Street Address" span={3}>{taxOrganizerData.street_address}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Apartment" span={3}>{taxOrganizerData.apartment}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="City" span={3}>{taxOrganizerData.city}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="State" span={3}>{taxOrganizerData.state}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Zip Code" span={3}>{taxOrganizerData.zip}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Country" span={3}>{taxOrganizerData.country}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_personal_address}</Descriptions.Item>
            </Descriptions>

            <Descriptions title="Filing Status" bordered size='small'>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Filing Status" span={3}>{taxOrganizerData.filing_status}</Descriptions.Item>
              {SPOUSE_INFO_FILING_STATUSES.includes(taxOrganizerData.filing_status) && (
                <>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Spouse First Name" span={3}>{taxOrganizerData.spouse_first_name}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Spouse Last Name" span={3}>{taxOrganizerData.spouse_last_name}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Spouse SSN/ITNN" span={3}>{taxOrganizerData.spouse_ssn}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Date of Marriage" span={3}>{taxOrganizerData.spouse_date_of_marriage}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Spouse Date of Birth" span={3}>{taxOrganizerData.spouse_date_of_birth}</Descriptions.Item>
                  {taxOrganizerData.spouse_deduction_type && taxOrganizerData.filing_status === "Married Filing Separately" && (
                    <Descriptions.Item contentStyle={{ width: '20%' }} label="Spouse Deduction Type" span={3}>{taxOrganizerData.spouse_deduction_type}</Descriptions.Item>
                  )}
                  {taxOrganizerData.spouse_date_of_divorce && (
                    <Descriptions.Item contentStyle={{ width: '20%' }} label="Date of Divorce" span={3}>{taxOrganizerData.spouse_date_of_divorce}</Descriptions.Item>
                  )}
                  {taxOrganizerData.spouse_date_of_death && (
                    <Descriptions.Item contentStyle={{ width: '20%' }} label="Date of Death" span={3}>{taxOrganizerData.spouse_date_of_death}</Descriptions.Item>
                  )}
                </>
              )}
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_personal_filing_status}</Descriptions.Item>
            </Descriptions>

            <Descriptions title="Dependents" bordered size='small'>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_personal_dependents}</Descriptions.Item>
            </Descriptions>

            {taxOrganizerData.dependents && taxOrganizerData.dependents.map((dependent, index) => (
              <Descriptions bordered size='small' key={index}>
                <Descriptions.Item contentStyle={{ width: '20%' }} label="First Name" span={3}>{dependent.first_name}</Descriptions.Item>
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Last Name" span={3}>{dependent.last_name}</Descriptions.Item>
                <Descriptions.Item contentStyle={{ width: '20%' }} label="SSN/ITNN" span={3}>{dependent.ssn}</Descriptions.Item>
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Date of Birth" span={3}>{dependent.date_of_birth}</Descriptions.Item>
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Relationship" span={3}>{dependent.relationship}</Descriptions.Item>
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Months Living With You" span={3}>{dependent.months_living_with}</Descriptions.Item>
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Has Income" span={3}>{dependent.has_income}</Descriptions.Item>
                {dependent.has_income === "Yes" && (
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Income" span={3}>{dependent.income}</Descriptions.Item>
                )}
              </Descriptions>
            ))}

            <Descriptions title="Health Insurance" bordered size='small'>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Do you have health insurance for this year?" span={3}>{taxOrganizerData.has_health_insurance}</Descriptions.Item>
              {taxOrganizerData.has_health_insurance === 'Yes' && (
                <>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Health Insurance Type" span={3}>{taxOrganizerData.health_insurance_type}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Do you have health insurance for the whole year?" span={3}>{taxOrganizerData.health_insurance_is_whole_year}</Descriptions.Item>
                  {taxOrganizerData.is_health_insurance_whole_year === 'No' && (
                    <Descriptions.Item contentStyle={{ width: '20%' }} label="What months did you have health insurance?" span={3}>{taxOrganizerData.health_insurance_months}</Descriptions.Item>
                  )}
                </>
              )}
            </Descriptions>

            <Descriptions title="Life Events" bordered size='small'>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Did your marital status changed?" span={3}>{taxOrganizerData.is_marital_status_changed}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Did the taxpayer, spouse, or a dependent change their name with the Social Security Administration?" span={3}>{taxOrganizerData.is_changed_name}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive a notice or letter from the IRS or a state revenue agency?" span={3}>{taxOrganizerData.is_irs_notice_received}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Did the taxpayer, spouse, or a dependent receive an identity protection PIN (IP PIN) from the IRS?" span={3}>{taxOrganizerData.is_pin_received}</Descriptions.Item>
              {taxOrganizerData.is_pin_received === 'Yes' && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="IP PIN" span={3}>{taxOrganizerData.pin_number}</Descriptions.Item>
              )}
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Were any children born or adopted?" span={3}>{taxOrganizerData.is_child_born}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Were you a member of the Armed Forces?" span={3}>{taxOrganizerData.is_armed_forces}</Descriptions.Item>
            </Descriptions>

            <Descriptions title="Financial Events" bordered size='small'>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you buy, sell, or refinance a home or rental property?" span={3}>{taxOrganizerData.is_property_sold}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you incur property damage or theft caused by a federally declared disaster?" span={3}>{taxOrganizerData.is_property_damage}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you convert or roll over any retirement accounts?" span={3}>{taxOrganizerData.is_retirement_accounts_converted}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive, sell, exchange, gift, or otherwise dispose of a digital asset?" span={3}>{taxOrganizerData.is_digital_assets_sold}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you have a financial interest in or signature authority over a foreign account or trust?" span={3}>{taxOrganizerData.is_foreign_account}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you gift $16,000 or more to another individual?" span={3}>{taxOrganizerData.is_gift}</Descriptions.Item>
              <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_questionnaire_financial_events}</Descriptions.Item>
            </Descriptions>

            {taxOrganizerData.properties &&
              <Descriptions title="Properties" bordered={false}>
              </Descriptions>
            }

            {taxOrganizerData.properties && taxOrganizerData.properties.map((property, index) => (
              <Descriptions bordered size='small' key={index}>
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Type" span={3}>{property.type}</Descriptions.Item>
                {property.years_owned && property.type === "Primary Residence" &&                 
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Years Owned" span={3}>{property.years_owned}</Descriptions.Item>
                }
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Date Purchased" span={3}>{property.date_purchased}</Descriptions.Item>
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Purchase Price" span={3}>{property.price_purchased}</Descriptions.Item>
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Date Sold" span={3}>{property.date_sold}</Descriptions.Item>
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Sale Price" span={3}>{property.price_sold}</Descriptions.Item>               
                {taxOrganizerData.properties.length - 1 === index &&                 
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_questionnaire_property}</Descriptions.Item>
                }
              </Descriptions>
            ))}

            
            {taxOrganizerData.is_retirement_account_covid_distributed && (
              <Descriptions title="Other Events" bordered size='small'>
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you make a coronavirus-related distribution from a retirement account?" span={3}>{taxOrganizerData.is_retirement_account_covid_distributed}</Descriptions.Item>
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_questionnaire_other_events}</Descriptions.Item>
              </Descriptions>
            )}

            <Descriptions title="Income" bordered size='small'>
              {taxOrganizerData.income_is_employment && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from employment?" span={3}>{taxOrganizerData.income_is_employment ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_retirement_distribution && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from retirement distributions?" span={3}>{taxOrganizerData.income_is_retirement_distribution ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_social_security && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from Social Security?" span={3}>{taxOrganizerData.income_is_social_security ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_self_employment && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from self-employment?" span={3}>{taxOrganizerData.income_is_self_employment ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_state_or_local_tax_refund && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from state or local tax refunds?" span={3}>{taxOrganizerData.income_is_state_or_local_tax_refund ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_unemployment && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from unemployment?" span={3}>{taxOrganizerData.income_is_unemployment ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_interest && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from interest?" span={3}>{taxOrganizerData.income_is_interest ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_dividends && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from dividends?" span={3}>{taxOrganizerData.income_is_dividends ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_sold_stock_or_investments && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from sold stock or investments?" span={3}>{taxOrganizerData.income_is_sold_stock_or_investments ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_canceled_debt && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from canceled debt?" span={3}>{taxOrganizerData.income_is_canceled_debt ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_rents && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from rents?" span={3}>{taxOrganizerData.income_is_rents ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_royalties && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from royalties?" span={3}>{taxOrganizerData.income_is_royalties ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_esa_or_529_distribution && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from ESA or 529 distributions?" span={3}>{taxOrganizerData.income_is_esa_or_529_distribution ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_hsa_or_msa_distribution && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from HSA or MSA distributions?" span={3}>{taxOrganizerData.income_is_hsa_or_msa_distribution ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_partnership && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from partnership?" span={3}>{taxOrganizerData.income_is_partnership ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_s_corporation && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from S-corporation?" span={3}>{taxOrganizerData.income_is_s_corporation ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_estate_or_trust && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from trust?" span={3}>{taxOrganizerData.income_is_estate_or_trust ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_gambling && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from gambling?" span={3}>{taxOrganizerData.income_is_gambling ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_other && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from other sources?" span={3}>{taxOrganizerData.income_is_other ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_farming && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any income from farming?" span={3}>{taxOrganizerData.income_is_farming ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.income_is_alimony && (
                <>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you receive any alimony income?" span={3}>{taxOrganizerData.income_is_alimony ? 'Yes' : 'No'}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Alimony Income Amount" span={3}>{taxOrganizerData.income_alimony_amount}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Alimony Payer First Name" span={3}>{taxOrganizerData.income_alimoni_payer_first_name}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Alimony Payer Last Name" span={3}>{taxOrganizerData.income_alimoni_payer_last_name}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Alimony Payer SSN" span={3}>{taxOrganizerData.income_alimoni_payer_ssn}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Date of Divorce" span={3}>{taxOrganizerData.spouse_date_of_divorce}</Descriptions.Item>
                </>


              )}

              <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_income_sources}</Descriptions.Item>
            </Descriptions>

            <Descriptions title="Expenses" bordered size='small'>
              {taxOrganizerData.expenses_is_adoption && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you have any adoption expenses?" span={3}>{taxOrganizerData.expenses_is_adoption ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.expenses_is_alimony && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you have any alimony expenses?" span={3}>{taxOrganizerData.expenses_is_alimony ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.expenses_is_dependent_care && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you have any child or dependent care expenses?" span={3}>{taxOrganizerData.expenses_is_dependent_care ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.expenses_is_educator && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you have any educator expenses?" span={3}>{taxOrganizerData.expenses_is_educator ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.expenses_is_higher_education && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you have any higher education expenses?" span={3}>{taxOrganizerData.expenses_is_higher_education ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.expenses_is_household_worker && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you have any household worker expenses?" span={3}>{taxOrganizerData.expenses_is_household_worker ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.expenses_is_health_savings && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you have any health saving expenses?" span={3}>{taxOrganizerData.expenses_is_health_savings ? 'Yes' : 'No'}</Descriptions.Item>
              )}


              {taxOrganizerData.expenses_is_retirement && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you have any detirement expenses?" span={3}>{taxOrganizerData.expenses_is_retirement ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.expenses_is_student_loan && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you have any student loan interest?" span={3}>{taxOrganizerData.expenses_is_student_loan ? 'Yes' : 'No'}</Descriptions.Item>
              )}

              {taxOrganizerData.expenses_is_energy_efficiency && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Do you have any energy efficiency expenses?" span={3}>{taxOrganizerData.expenses_is_energy_efficiency ? 'Yes' : 'No'}</Descriptions.Item>
              )}
      

              <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_expenses_summary}</Descriptions.Item>
            </Descriptions>

            <Descriptions title="Expenses Details" bordered size='small'>
              {taxOrganizerData.expenses_is_adoption && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Adoption Expenses" span={3}>{taxOrganizerData.expenses_adoption_amount}</Descriptions.Item>
              )}

              {taxOrganizerData.expenses_is_alimony && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Alimony Expenses" span={3}>{taxOrganizerData.expenses_alimony_amount}</Descriptions.Item>
              )}

              {taxOrganizerData.expenses_is_dependent_care && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Child or Dependent Care Expenses" span={3}>{taxOrganizerData.expenses_dependent_care_amount}</Descriptions.Item>
              )}

              {taxOrganizerData.expenses_is_educator && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Educator Expenses" span={3}>{taxOrganizerData.expenses_educator_amount}</Descriptions.Item>
              )}

              {taxOrganizerData.expenses_is_higher_education && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Higher Education Expenses" span={3}>{taxOrganizerData.expenses_higher_education_amount}</Descriptions.Item>
              )}

              {taxOrganizerData.expenses_is_household_worker && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Household Worker Expenses" span={3}>{taxOrganizerData.expenses_household_worker_amount}</Descriptions.Item>
              )}

              {taxOrganizerData.expenses_is_health_savings && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Health Saving Expenses" span={3}>{taxOrganizerData.expenses_health_savings_amount}</Descriptions.Item>
              )}

              {taxOrganizerData.expenses_is_retirement && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Retirement Expenses" span={3}>{taxOrganizerData.expenses_retirement_amount}</Descriptions.Item>
              )}

              {taxOrganizerData.expenses_is_student_loan && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Student Loan Interest" span={3}>{taxOrganizerData.expenses_student_loan_amount}</Descriptions.Item>
              )}
              
              {taxOrganizerData.expenses_is_energy_efficiency && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Energy-efficient home improvements" span={3}>{taxOrganizerData.expenses_energy_efficient_amount}</Descriptions.Item>
              )}

              <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_expenses_details}</Descriptions.Item>
            </Descriptions>

            <Descriptions title="Deductions" bordered size='small'>
              {taxOrganizerData.is_standart_deduction === "Yes" && (
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Standard deduction?" span={3}>{taxOrganizerData.is_standart_deduction}</Descriptions.Item>
              )}

              {taxOrganizerData.is_standart_deduction === "No" && (
                <>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Medical and dental expenses?" span={3}>{taxOrganizerData.expenses_deduction_medical_dental}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Mortgage interest?" span={3}>{taxOrganizerData.expenses_deduction_mortgage_interest}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="State and income taxes?" span={3}>{taxOrganizerData.expenses_deduction_state_local_income}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Donations to charity (cash)?" span={3}>{taxOrganizerData.expenses_deduction_charity_cash}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Donations to charity (non-cash)?" span={3}>{taxOrganizerData.expenses_deduction_charity_noncash}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Casualty and theft losses?" span={3}>{taxOrganizerData.expenses_deduction_casualty_theft}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Sales taxes?" span={3}>{taxOrganizerData.expenses_deduction_sales}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Real Estate taxes?" span={3}>{taxOrganizerData.expenses_deduction_real_estate}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Personal property taxes?" span={3}>{taxOrganizerData.expenses_deduction_personal_property}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Gambling Loss?" span={3}>{taxOrganizerData.expenses_deduction_gambling}</Descriptions.Item>
                </>
              )}

              <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_expenses_deductions}</Descriptions.Item>
            </Descriptions>

            {taxOrganizerData.income_is_self_employment && (
              <Descriptions title="Sole Proprietorship" bordered size='small'>
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Do you have a Sole Propprietorship?" span={3}>{taxOrganizerData.income_is_self_employment ? "Yes" : "No"}</Descriptions.Item>
                <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_questionnaire_property}</Descriptions.Item>
              </Descriptions>
            )}

            {(taxOrganizerData.income_is_self_employment && taxOrganizerData.sole_proprietorship_business_name) && (
              <>
                <Descriptions title="Business Basic Information" bordered size='small'>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Business name" span={3}>{taxOrganizerData.sole_proprietorship_business_name}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Business activity" span={3}>{taxOrganizerData.sole_proprietorship_business_activity}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Business EIN" span={3}>{taxOrganizerData.sole_proprietorship_business_ein}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="This business started or was acquired?" span={3}>{taxOrganizerData.sole_proprietorship_business_started_or_acquired}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="This business disposed?" span={3}>{taxOrganizerData.sole_proprietorship_business_disposed}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="This business used an accounting method other than the cash method?" span={3}>{taxOrganizerData.sole_proprietorship_business_accounting_method}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Is the business address the same as the taxpayer's home address?" span={3}>{taxOrganizerData.sole_proprietorship_business_same_address}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_sole_proprietorship_general}</Descriptions.Item>
                </Descriptions>

                <Descriptions title="Business Specific Information" bordered size='small'>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you make any payments to contractors or other non-employees?" span={3}>{taxOrganizerData.sole_proprietorship_is_payment_to_not_employee}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you file Form 1099 for these undividuals?" span={3}>{taxOrganizerData.sole_proprietorship_is_payment_to_not_employee_file_1099}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you pay for your own health or dental insurance premiums?" span={3}>{taxOrganizerData.sole_proprietorship_is_pay_own_healthcare}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Did you or do you plan to make contributions to a self-employed retirement plan?" span={3}>{taxOrganizerData.sole_proprietorship_is_contributions_to_retirement}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Type of plan" span={3}>{taxOrganizerData.sole_proprietorship_is_contributions_to_retirement_plan}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Amount contributed" span={3}>{taxOrganizerData.sole_proprietorship_is_contributions_to_retirement_amount}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_sole_proprietorship_specific}</Descriptions.Item>
                </Descriptions>

                <Descriptions title="Profit and Loss" bordered size='small'>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Profit and Loss statement fulfillment type" span={3}>{taxOrganizerData.sole_proprietorship_profit_and_loss}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Income reported on 1099-MISC" span={3}>{taxOrganizerData.sole_propietorship_income_reported}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Income not reported on 1099-MISC" span={3}>{taxOrganizerData.sole_propietorship_income_not_reported}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Returns and allowances" span={3}>{taxOrganizerData.sole_propietorship_income_returns_and_allowences}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Other income" span={3}>{taxOrganizerData.sole_propietorship_income_other}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_sole_proprietorship_income}</Descriptions.Item>
                </Descriptions>

                <Descriptions title="Business Expenses" bordered size='small'>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Advertising expenses" span={3}>{taxOrganizerData.sole_proprietorship_expenses_advertising}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Commissions and fees" span={3}>{taxOrganizerData.sole_proprietorship_expenses_commissions_and_fees}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Contract labor" span={3}>{taxOrganizerData.sole_proprietorship_expenses_contract_labor}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Employee benefit programs" span={3}>{taxOrganizerData.sole_proprietorship_expenses_employee_benefit_programs}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Insurance other than health" span={3}>{taxOrganizerData.sole_proprietorship_expenses_insurance_other_than_health}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Interest mortgage" span={3}>{taxOrganizerData.sole_proprietorship_expenses_interest_mortgage}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Interest other" span={3}>{taxOrganizerData.sole_proprietorship_expenses_interest_other}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Legal and professional services" span={3}>{taxOrganizerData.sole_proprietorship_expenses_legal_and_professional_services}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Office expenses" span={3}>{taxOrganizerData.sole_proprietorship_expenses_office_expenses}</Descriptions.Item>

                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Pension and profit sharing plans" span={3}>{taxOrganizerData.sole_proprietorship_expenses_pension_and_profit_sharing_plans}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Rent" span={3}>{taxOrganizerData.sole_proprietorship_expenses_rent}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Repairs and maintenance" span={3}>{taxOrganizerData.sole_proprietorship_expenses_repairs_and_maintenance}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Supplies" span={3}>{taxOrganizerData.sole_proprietorship_expenses_supplies}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Taxes and licenses" span={3}>{taxOrganizerData.sole_proprietorship_expenses_taxes_and_licenses}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Travel" span={3}>{taxOrganizerData.sole_proprietorship_expenses_travel}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Meal" span={3}>{taxOrganizerData.sole_proprietorship_expenses_meal}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Utilities" span={3}>{taxOrganizerData.sole_proprietorship_expenses_utilities}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Wages" span={3}>{taxOrganizerData.sole_proprietorship_expenses_wages}</Descriptions.Item>

                  {taxOrganizerData.sole_proprietorship_expenses_other?.map((item, index) => (
                    <Descriptions.Item key={index} contentStyle={{ width: '20%' }} label={item.description} span={3}>{item.amount}</Descriptions.Item>
                  ))}

                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_sole_proprietorship_expenses}</Descriptions.Item>
                </Descriptions>

                <Descriptions title="Business Assets" bordered size='small'>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_sole_proprietorship_assets}</Descriptions.Item>
                </Descriptions>

                {taxOrganizerData.sole_proprietorship_assets?.map((item, index) => (
                  <Descriptions key={index} bordered size='small'>
                    <Descriptions.Item contentStyle={{ width: '20%' }} label="Description" span={3}>{item.description}</Descriptions.Item>
                    <Descriptions.Item contentStyle={{ width: '20%' }} label="Cost" span={3}>{item.cost}</Descriptions.Item>
                    <Descriptions.Item contentStyle={{ width: '20%' }} label="Date acquired" span={3}>{item.date_acquired}</Descriptions.Item>
                    <Descriptions.Item contentStyle={{ width: '20%' }} label="Is sold" span={3}>{item.is_sold}</Descriptions.Item>
                    <Descriptions.Item contentStyle={{ width: '20%' }} label="Date sold" span={3}>{item.date_sold}</Descriptions.Item>
                    <Descriptions.Item contentStyle={{ width: '20%' }} label="Sale price" span={3}>{item.sale_price}</Descriptions.Item>
                  </Descriptions>
                ))}

                <Descriptions title="Business Vehicles" bordered size='small'>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_sole_proprietorship_vehicles}</Descriptions.Item>
                </Descriptions>

                {taxOrganizerData.sole_prroprietorship_is_vehicles === 'Yes' && (
                  <>
                    {taxOrganizerData.sole_proprietorship_vehicles?.map((item, index) => (
                      <Descriptions key={index} bordered size='small'>
                        <Descriptions.Item contentStyle={{ width: '20%' }} label="Description" span={3}>{item.description}</Descriptions.Item>
                        <Descriptions.Item contentStyle={{ width: '20%' }} label="In Service From" span={3}>{item.date_in_service_from}</Descriptions.Item>
                        <Descriptions.Item contentStyle={{ width: '20%' }} label="Business Miles" span={3}>{item.business_miles}</Descriptions.Item>
                        <Descriptions.Item contentStyle={{ width: '20%' }} label="Total Miles" span={3}>{item.total_miles}</Descriptions.Item>
                        <Descriptions.Item contentStyle={{ width: '20%' }} label="Interest on loan" span={3}>{item.expense_interest_on_loan}</Descriptions.Item>
                        <Descriptions.Item contentStyle={{ width: '20%' }} label="Parking and tolls" span={3}>{item.expenses_parking_and_tolls}</Descriptions.Item>
                        <Descriptions.Item contentStyle={{ width: '20%' }} label="Personal property taxes" span={3}>{item.expenses_porsonal_property_taxes}</Descriptions.Item>
                        <Descriptions.Item contentStyle={{ width: '20%' }} label="Fuel and oil" span={3}>{item.expenses_fuel_and_oil}</Descriptions.Item>
                        <Descriptions.Item contentStyle={{ width: '20%' }} label="Repairs and maintenance" span={3}>{item.expenses_repairs_and_maintenance}</Descriptions.Item>
                        <Descriptions.Item contentStyle={{ width: '20%' }} label="Insurance" span={3}>{item.expenses_insurance}</Descriptions.Item>
                        <Descriptions.Item contentStyle={{ width: '20%' }} label="License and registration" span={3}>{item.expenses_license_and_registration}</Descriptions.Item>
                        <Descriptions.Item contentStyle={{ width: '20%' }} label="Lease payments" span={3}>{item.expenses_lease_payments}</Descriptions.Item>
                        <Descriptions.Item contentStyle={{ width: '20%' }} label="Other" span={3}>{item.expenses_other}</Descriptions.Item>
                      </Descriptions>
                    ))}
                  </>
                )}

                <Descriptions title="Home Used for Business" bordered size='small'>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Is home used for Business" span={3}>{taxOrganizerData.sole_proprietorship_is_home_used}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Total area of home" span={3}>{taxOrganizerData.sole_proprietorship_home_area_total}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Area of home used for business" span={3}>{taxOrganizerData.sole_proprietorship_home_area_business}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Mortgage interest" span={3}>{taxOrganizerData.sole_proprietorship_home_expenses_mortgage_interest_total}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Mortgage interest for business" span={3}>{taxOrganizerData.sole_proprietorship_home_expenses_mortgage_interest_business}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Property taxes" span={3}>{taxOrganizerData.sole_proprietorship_home_expenses_property_taxes_total}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Property taxes for business" span={3}>{taxOrganizerData.sole_proprietorship_home_expenses_property_taxes_business}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Utilities" span={3}>{taxOrganizerData.sole_proprietorship_home_expenses_utilities_total}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Utilities for business" span={3}>{taxOrganizerData.sole_proprietorship_home_expenses_utilities_business}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Repairs" span={3}>{taxOrganizerData.sole_proprietorship_home_expenses_repairs_total}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Repairs for business" span={3}>{taxOrganizerData.sole_proprietorship_home_expenses_repairs_business}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Other" span={3}>{taxOrganizerData.sole_proprietorship_home_expenses_other_total}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Other for business" span={3}>{taxOrganizerData.sole_proprietorship_home_expenses_other_business}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Insurance" span={3}>{taxOrganizerData.sole_proprietorship_home_expenses_insurance_total}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Insurance for business" span={3}>{taxOrganizerData.sole_proprietorship_home_expenses_insurance_business}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Rent" span={3}>{taxOrganizerData.sole_proprietorship_home_expenses_rent_total}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Rent for business" span={3}>{taxOrganizerData.sole_proprietorship_home_expenses_rent_business}</Descriptions.Item>
                  <Descriptions.Item contentStyle={{ width: '20%' }} label="Comment" span={3}>{taxOrganizerData.comment_sole_proprietorship_home}</Descriptions.Item>
                </Descriptions>
              </>
            )}
          </div>
        )}
      </Modal >
    </>
  )
}

const RejectBtn = styled(Button)`
      color: #008AC8;

      :hover {
        color: #005893;
  }

      svg {
        width: 18px;
      height: 18px;
      margin-bottom: -1px;
  }
      `;

export default PreviewTaxOrganizerButton

