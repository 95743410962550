import { logoImage } from 'assets/img';
import styled from 'styled-components';

function Logo(props) {
    return (
        <Wrapper>
            <img src={logoImage} alt="Taxrise Logo" />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    height: 63px;
    padding: 14px;

    img {
        max-width: 100%;
    }
`;

export default Logo;
