import {Switch, Route} from 'react-router-dom';
import {CHANGE_PASSWORD} from 'routes/paths';
import ChangePassword from './ChangePassword';

function ChangePasswordRoute(props) {
    return (
        <Switch>
            <Route exact path={CHANGE_PASSWORD}>
                <ChangePassword/>
            </Route>
        </Switch>
    );
}

export default ChangePasswordRoute;
