/* eslint-disable react/display-name */
import {
    PageHeader,
    Button,
    Input,
    message,
    Space,
} from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { endpoints, useApi } from 'api';
import { Fragment, useState } from 'react';
import styled from 'styled-components';

const { Search } = Input;

function CPInvitation() {
    const [api] = useApi();

    const [filters, setFilters] = useState({});
    const [show, setShow] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [url, setUrl] = useState('');

    const getSignUpUrl = async (case_id, send_email) => {
        try {
            const req = await api({
                method: 'post',
                url: `${endpoints.GET_CLIENT_PORTAL_SIGNUP_URL}`,
                data: { 'case_id': case_id, 'send_email': send_email },
            })
            if (req.status === 200) {
                if (send_email) {
                    message.success(`Sign up url is emailed to ${req.data.email}`);
                }
                else {
                    message.success('Signup URL generated');
                    setFirstName(req.data.first_name)
                    setLastName(req.data.last_name)
                    setEmail(req.data.email)
                    setUrl(req.data.url)
                    setShow(true)
                }

            }
        }
        catch {
            message.error('This Case ID does not exist');
            setShow(false)
        }


        // const res = req.data;
        // setNewPass(res.new_password)
        // setNewPassIsSshow(true)


    }


    const onSearch = (searchQuery) => {
        setFilters((existingFilters) => ({
            ...existingFilters,
            q: searchQuery || undefined,
        }));
        getSignUpUrl(searchQuery, false);
    };

    return (
        <Fragment>
            <PageHeader onBack={null} title="Client Portal Invitation" />
            <SearchWrap>
                {show ?
                    <>
                        <Space>
                            &nbsp; &nbsp;
                            <b>First Name:</b> {firstName},
                            <b>Last Name:</b> {lastName},
                            <b>Email:</b> {email}
                        </Space>

                    </> : ''}

                <p className="title">
                    {filters['q'] && (
                        <span>
                            Search result for: <strong>{filters['q']}</strong>
                        </span>
                    )}
                </p>

                <Search
                    className="table-search"
                    placeholder="Search Case ID"
                    onSearch={onSearch}
                    enterButton
                    allowClear
                />
            </SearchWrap>
            {show ?
                <>
                    <Space>
                        &nbsp; &nbsp;
                        <b>Client Portal Signup URL:</b> <a href={url}>{url}</a>
                        <Button type="primary" onClick={() => getSignUpUrl(filters['q'], true)}>Send email to client</Button>
                        <CopyToClipboard text={url}
                            onCopy={() => message.success('Signup url is copied to clipboard')}>
                            <Button>Copy url to clipboard</Button>
                        </CopyToClipboard>
                    </Space>

                </> : ''}


        </Fragment>
    );
}

const ResultWrap = styled.header`
    display: flex;
    align-items: left;
    flex-direction: column;
`

const SearchWrap = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px 10px;

    .title {
        margin-bottom: 0;
        order: 1;
    }

    .title:empty {
        height: 0;
    }

    .table-search {
        max-width: 350px;
        order: -1;
        margin-bottom: 10px;
    }

    @media (min-width: 767px) {
        flex-direction: row;

        .table-search {
            order: -1;
            margin-bottom: 0;
        }
    }
`;

export default CPInvitation;
