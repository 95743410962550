import { Row, Col, Select } from 'antd';
import { COLORS } from 'const';
import { Fragment, useEffect, useState } from 'react';
import {
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
    Legend,
    Sector,
} from 'recharts';
import styled from 'styled-components';

const legendWrapperStyle = {
    height: 100,
    right: '5px',
    left: '5px',
    overflowY: 'auto',
};

const RenderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        label,
        value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 3) * cos;
    const sy = cy + (outerRadius + 3) * sin;
    const mx = cx + (outerRadius + 15) * cos;
    const my = cy + (outerRadius + 15) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={0} textAnchor="middle" fill={fill}>
                {label}
            </text>
            <text
                x={cx}
                y={cy}
                dy={16}
                textAnchor="middle"
                fill={'#333'}
                style={{ fontWeight: 'bold' }}
            >
                {value}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius + 3}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={fill}
                fill="none"
            />
            <text
                x={ex + (cos >= 0 ? 1 : -1)}
                y={ey}
                textAnchor={textAnchor}
                fill={fill}
                dy={5}
            >
                {value}
            </text>
        </g>
    );
};

const { Option } = Select;

const CategoryPieChart = ({ data, onClick }) => {
    const [chartData, setChartData] = useState(data);
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState([]);

    useEffect(() => {
        setSelectedCategory([]);
        setChartData(data);
    }, [data]);

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    const filterCategory = (selectedCats) => {
        setSelectedCategory(selectedCats);
        if (!selectedCats.length) {
            setChartData(data);
            return;
        }

        setChartData(data.filter((d) => selectedCats.includes(d.label)));
    };

    const categorySelect = (
        <Select
            mode="multiple"
            allowClear
            style={{ minWidth: 150 }}
            placeholder="Filter category"
            onChange={filterCategory}
            value={selectedCategory}
        >
            {data.map((d, key) => (
                <Option key={key} value={d.label}>
                    {d.label}
                </Option>
            ))}
        </Select>
    );

    return (
        <Fragment>
            {categorySelect}
            <div style={{ maxWidth: '100%', height: 550 }}>
                <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            activeIndex={activeIndex}
                            activeShape={RenderActiveShape}
                            onMouseEnter={onPieEnter}
                            data={chartData}
                            dataKey="amount"
                            nameKey="label"
                            cx="50%"
                            cy="50%"
                            outerRadius={'70%'}
                            innerRadius={'60%'}
                            fill="#8884d8"
                            onClick={onClick}
                        >
                            {data.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                />
                            ))}
                        </Pie>
                        <Legend
                            layout="vertical"
                            iconType="circle"
                            wrapperStyle={legendWrapperStyle}
                            content={<CategoriesList />}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </Fragment>
    );
};

const CategoriesList = ({ payload }) => {
    return (
        <Row>
            {payload.map((entry, index) => (
                <Col xs={12} sm={8} key={`item-${index}`}>
                    <Category color={entry.color}>{entry.value}</Category>
                </Col>
            ))}
        </Row>
    );
};

const Category = styled.p`
    color: ${(props) => props.color || '#333'};
    word-break: break-all;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 1em;
    position: relative;
    padding-left: 17px;
    padding-right: 5px;

    &::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50px;
        background-color: ${(props) => props.color || '#333'};
        position: absolute;
        left: 0;
    }
`;

export default CategoryPieChart;
