import { useMemo } from 'react';
import { Input } from 'antd';
import { useField } from 'formik';
import { FieldWrapper } from 'components/Wrapper';

function TextInput({ label, required, ...props }) {
    const [field, meta] = useField(props);
    const { touched, error } = meta;

    const isInvalid = useMemo(() => {
        return !!(touched && error);
    }, [touched, error]);

    return (
        <FieldWrapper
            isInvalid={isInvalid}
            label={label}
            required={required}
            error={error}
        >
            <Input {...field} {...props} />
        </FieldWrapper>
    );
}

export default TextInput;
