import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useApi } from 'api';
import { useActions } from 'hooks/useAction';
import ManagerDocumentsTable from './ManagerDocumentsTable';
import { Typography, Tabs, Layout, Pagination, Alert } from 'antd';

const { Title } = Typography;
const { TabPane } = Tabs;
const { Content } = Layout;

const ManagerDocuments = () => {
  const { uploadedDocuments, abandonedDocuments, documentsLoading } = useSelector(state => state.managerDocuments);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [page1, setPage1] = useState(1);
  const [limit1, setLimit1] = useState(10);
  const [api] = useApi(true);
  const {
    fetchAbandonedDocuments,
    fetchUploadedDocuments,
  } = useActions();

  useEffect(() => {
    fetchUploadedDocuments(api, page, limit);
  }, [api, page, limit]);

  useEffect(() => {
    fetchAbandonedDocuments(api, page, limit);
  }, [api, page1, limit1]);

  return (
    <>
      <Title level={3}>Your Documents</Title>
      <Content
        style={{
          padding: '16px 24px',
          backgroundColor: 'white',
          marginTop: 20,
        }}
      >
        <Tabs defaultActiveKey="uploaded" type="card">
          <TabPane tab="Recently Uploaded" key="uploaded">
              {!uploadedDocuments?.total && <Alert
                  message="You have no recently uploaded documents..."
                  type="warning"
                  closable
              />}
            {documentsLoading && <div>Loading...</div>}
            {uploadedDocuments?.data && (
              <>
                <ManagerDocumentsTable documents={uploadedDocuments?.data} />
                <Pagination total={uploadedDocuments.total} defaultCurrent={page} style={{ margin: 10 }} onChange={setPage} onShowSizeChange={setLimit} />
              </>
            )}
          </TabPane>
          <TabPane tab="Abandoned Documents" key="abandoned">
              {!uploadedDocuments?.total && <Alert
                  message="You have no abandoned documents..."
                  type="warning"
                  closable
              />}
            {documentsLoading && <div>Loading...</div>}
            {abandonedDocuments?.data && (
              <>
                <ManagerDocumentsTable documents={abandonedDocuments?.data} />
                <Pagination total={abandonedDocuments.total} defaultCurrent={page1} style={{ margin: 10 }} onChange={setPage1} onShowSizeChange={limit1} />
              </>
            )}
          
          </TabPane>
        </Tabs>
      </Content>
    </>
  )
}

const TableActionButtons = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  button {
    border-radius: 4px;
    margin-left: 24px;
  }
`;

const TableWrapper = styled('div')`
  .ant-table {
    font-size: 12px;
  }

  .ant-pagination {
    text-align: right;
    margin: 16px 0;
  }
`;

const Status = styled('span')`
  text-transform: capitalize;
`;

const NotifyText = styled('span')`
  margin: 0 8px;
  color: #808080;
`;

export default ManagerDocuments;
