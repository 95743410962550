import { endpoints, useApi } from 'api';
import { useEffect, useState } from 'react';

const useFetchBanks = (caseId) => {
    const [banksList, updateBanksList] = useState([]);
    const [loading, toggleLoading] = useState(false);

    const [api] = useApi();

    useEffect(() => {
        const fetchTransactions = async (caseId) => {
            if (!caseId) return;

            try {
                toggleLoading(true);
                const req = await api({
                    url: `${endpoints.PLAID_BANKS}`,
                    params: {
                        cs_id: caseId,
                    },
                });

                const res = await req.data;

                if (Array.isArray(res) && res.length) {
                    updateBanksList(res);
                }
                toggleLoading(false);
            } catch (error) {
                console.log({ error });
                toggleLoading(false);
            }
        };

        fetchTransactions(caseId);
    }, [api, caseId]);

    return [banksList, loading];
};

export default useFetchBanks;
