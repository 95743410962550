import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import styled from 'styled-components';

import { TableResponsive } from 'components/Styled'
import { useApi } from 'api';
import { useActions } from 'hooks/useAction';
import { formattedLocaleDateTime } from 'utilities/dateUtils';
import PreviewButton from 'components/TableActionButtons/PreviewButton/PreviewButton';
import RejectButton from 'components/TableActionButtons/RejectButton/RejectButton';
import AcceptButton from 'components/TableActionButtons/AcceptButton/AcceptButton';
import DeleteButton from 'components/TableActionButtons/DeleteButton/DeleteButton';
import RequestDocument from './RequestDocumentModal/RequestDocumentModal';
import ClientContext from '../ClientContext/ClientContext';
import {useContext} from "react";
import UploadButton from 'components/TableActionButtons/UploadButton/UploadButton';
import NotifyButton from 'components/TableActionButtons/NotifyButton/NotifyButton';


const Documents = () => {
  const { clientDetails } = useContext(ClientContext);
  const caseId = clientDetails ? clientDetails.case_id : null;

  const { documentsData, documentsDataLoading, notificationData } = useSelector(state => state.documents);
  const { categoriesData } = useSelector(state => state.categories);

  const [api] = useApi(true);
  const {
    fetchDocuments,
    requestDocument,
    fetchCategories,
    fetchLastNotification,
    acceptDocument,
    deleteDocument,
    rejectDocument,
    notifyDocuments,
    uploadDocument,
  } = useActions();
  const [ ordering, setOrdering ] = useState('');

  useEffect(() => {
    fetchDocuments(api, caseId);
    fetchCategories(api, caseId);
    fetchLastNotification(api, caseId);
  }, [caseId, ordering, api]);


  const handleTableChange = (a, b, sorting) => {
    let ordering = '';
    const { order, field } = sorting;
    if (order) {
        ordering = `${order === 'ascend' ? '' : '-'}${field}`;
    }
    setOrdering(ordering);
  };

  const handleDocumentAccept = (request_id) => {
    acceptDocument(api, clientDetails.case_id, request_id)
  }

  const handleDocumentDelete = (request_id) => {
    deleteDocument(api, clientDetails.case_id, request_id)
  }

  const handleDocumentReject = (request_id, reason) => {
    rejectDocument(api, clientDetails.case_id, request_id, reason)
  }

  const handleDocumentRequest = (documents) => {
    requestDocument(api, clientDetails.case_id, documents)
  }

  const handleNotifyDocuments = () => {
    notifyDocuments(api, clientDetails.case_id)
  }

  const handleUploadDocuments = (request_id, file) => {
    uploadDocument(api, clientDetails.case_id, request_id, file)
  }

  const columns = [
    {
      title: '#',
      dataIndex: '#',
      render: (text, record, index) => index + 1,
      sorter: false,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      render: (type, data) => data?.document_type.category,
      sorter: false,
      filters:[...new Map(categoriesData?.map(c => [c.category, {text: c.category, value: c.category}])).values()],
      onFilter: (value, record) => record?.document_type.category === value
    },
    {
      title: 'Document',
      dataIndex: 'type',
      render: (type, data) => data?.document_type.type + ' ' + (data?.title ? data.title : ''),
      filters:[...new Map(categoriesData?.map(c => [c.type, {text: c.type, value: c.type}])).values()],
      onFilter: (value, record) => record?.document_type.type === value,
      sorter: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => <Status>{status}</Status>,
      filters:[...new Map(documentsData?.map(c => [c.status, {text: c.status, value: c.status}])).values()],
      onFilter: (value, record) => record?.status === value,
      sorter: false,
    },
    {
      title: 'Requested',
      dataIndex: 'requested_at',
      render: (requested_at) => formattedLocaleDateTime(requested_at),
      sorter: false,
    },
    {
      title: 'Last Status Updated At',
      dataIndex: 'updated_at',
      render: (updated_at) => formattedLocaleDateTime(updated_at, '-'),
      sorter: false,
    },
    {
      title: 'Actions',
      align: 'center',
      dataIndex: 'actions',
      render: (_, { s3_file_url_presigned, id, status, title }) => {
        return (
          <>
            <UploadButton
              onUpload={file => handleUploadDocuments(id, file)}
              disabled={status === 'uploaded' || status === 'approved' || status === 'accepted'}
            />
            <PreviewButton
              previewLink={s3_file_url_presigned}
            />
            <AcceptButton
              onAcceptClick={() => handleDocumentAccept(id)}
              disabled={status !== 'uploaded'}
            />
            <RejectButton
              handleReject={reason => handleDocumentReject(id, reason)}
              disabled={status !== 'uploaded'}
              />
            <DeleteButton
              title={title}
              handleDelete={() => handleDocumentDelete(id)}
            />
          </>
        )
      },
      sorter: false,
    },
  ]

  return (
      <div>
        <TableActionButtons>
          {notificationData?.inserted_at ?
            <div>
              <NotifyText>Last notified:</NotifyText>
              {formattedLocaleDateTime(notificationData?.inserted_at)}
            </div> :
            <NotifyText>
              There is no notification
            </NotifyText>
          }
          <div>
            <NotifyButton
              label={notificationData?.inserted_at ? 'Send More Notification' : 'Notify About Requested Documents'}
              ghost
              handleNotify={handleNotifyDocuments}
            />
            <RequestDocument
              title='Request a Document'
              categoriesData={categoriesData}
              handleRequestDocument={(title, document_type_id) => handleDocumentRequest(title, document_type_id)}
            />
          </div>
        </TableActionButtons>
        <TableWrapper>
          <TableResponsive>
            <Table
              loading={documentsDataLoading}
              dataSource={documentsData}
              columns={columns}
              pagination={false}
              rowKey={'id'}
              onChange={handleTableChange}
            />
          </TableResponsive>
        </TableWrapper>
      </div>
    )
}

const TableActionButtons = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  button {
    border-radius: 4px;
    margin-left: 24px;
  }
`;

const TableWrapper = styled('div')`
  .ant-table {
      font-size: 12px;
  }

  .ant-pagination {
      text-align: right;
      margin: 16px 0;
  }
`;

const Status = styled('span')`
  text-transform: capitalize;
`;

const NotifyText = styled('span')`
  margin: 0 8px;
  color: #808080;
`;

export default Documents;
