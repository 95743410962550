/* eslint-disable react/display-name */
import {
    PageHeader,
    Table,
    Tag,
    Button,
    Tooltip,
    Input,
    Pagination,
    message, Space,
} from 'antd';
import {
    CalendarOutlined,
    CheckOutlined,
    DisconnectOutlined,
    MailOutlined,
} from '@ant-design/icons';
import {endpoints, useApi} from 'api';
import {Fragment, useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {CLIENT_DETAILS, CREATE_STAFF_OR_ADMIN} from 'routes/paths';
import {AdminType, CaseManagerType, ClientType, ManagerType, FinancialAnalystType} from 'const';
import {useClientUpdate, useSendResetPasswordEmail} from 'hooks';
import styled from 'styled-components';
import {formateDateTime} from 'library';
import {TableResponsive} from 'components/Styled';
import {DateRangeFilter} from 'components/TableFilters';

const {Search} = Input;

const getSendPasswordRedirectUrl = function () {
    if (endpoints.REACT_APP_API_ENDPOINT.includes('staging')) {
        return 'https://staging-client.taxrise.com'
    } else return 'https://client.taxrise.com'
}

function Users() {
    const [clientsData, setClientsData] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [loading, toggleLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [ordering, setOrdering] = useState('');
    const [api] = useApi();

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers, filters, ordering]);

    const fetchUsers = useCallback(
        async (pagination = {current: 1, pageSize: 10}) => {
            try {
                toggleLoading(true);
                const {current, pageSize} = pagination;

                const req = await api({
                    url: endpoints.STAFFS,
                    params: {
                        user_type: [10, 30],
                        ...filters,
                        page: current - 1,
                        size: pageSize,
                        ordering,
                    },
                });
                const res = await req.data;

                toggleLoading(false);

                setClientsData(res.items);
                console.log(res.items)
                setPagination({
                    ...pagination,
                    total: res.total,
                });
            } catch (error) {
                toggleLoading(false);
            }
        },
        [api, filters, ordering]
    );

    const [updateClient] = useClientUpdate(
        () => fetchUsers(pagination),
        null,
        toggleLoading
    );

    const onPageChange = (page, pageSize) => {
        const newPage = {
            current: page,
            pageSize,
        };

        setPagination((curPageData) => ({
            ...curPageData,
            ...newPage,
        }));

        fetchUsers(newPage);
    };

    const onSearch = (searchQuery) => {
        setFilters((existingFilters) => ({
            ...existingFilters,
            q: searchQuery || undefined,
        }));
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'first_name',
            width: '200px',
            sorter: true,
            render: (name, data) =>
                `${name} ${data.middle_name ? data.middle_name : ''} ${data.last_name}`,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: true,
            render: (email) => <a href={`mailto:${email}`}>{email}</a>,
        },
        {
            title: 'User Type',
            dataIndex: 'type',
            width: '100px',
            align: 'center',
            sorter: true,
            render: (type) => {
                console.log(type)
                if (type === AdminType) {
                    // console.log("Admin")
                    return <Tag color="green">Admin</Tag>
                } else if (type === ManagerType) {
                    return <Tag color="green">Manager</Tag>
                } else if (type === CaseManagerType) {
                    return <Tag color="green">Case Manager</Tag>
                } else if (type === FinancialAnalystType) {
                    return <Tag color="green">Financial Analyst</Tag>
                }else if (type === ClientType) {
                    return <Tag color="green">Client</Tag>
                }
            }
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            width: '100px',
            align: 'center',
            sorter: true,
            render: (active) =>
                active ? (
                    <Tag color="green">Active</Tag>
                ) : (
                    <Tag color="orange">Inactive</Tag>
                ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            align: 'right',
            width: '150px',
            render: (action, {is_active, id, type, case_id, email}) => {
                return (
                    <Fragment>
                        <Tooltip title="Active Client">
                            <Button
                                size="small"
                                icon={<CheckOutlined/>}
                                type="primary"
                                onClick={() =>
                                    updateClient(id, {is_active: true})
                                }
                                disabled={is_active}
                            />
                        </Tooltip>
                        <Tooltip title="Inactive Client">
                            <Button
                                style={{marginLeft: 5}}
                                size="small"
                                icon={<DisconnectOutlined/>}
                                type="default"
                                onClick={() =>
                                    updateClient(id, {is_active: false})
                                }
                                disabled={!is_active}
                            />
                        </Tooltip>
                    </Fragment>
                );
            },
        },
    ];


    const handleTableChange = (a, b, sorting) => {
        let ordering = '';

        const {order, field} = sorting;

        if (order) {
            ordering = `${order === 'ascend' ? '' : '-'}${field}`;
        }

        setOrdering(ordering);
    };

    return (
        <Fragment>
            <Space>
                <PageHeader onBack={null} title="Users"/>
                <Link to={CREATE_STAFF_OR_ADMIN}>Create User</Link>
            </Space>
            <SearchWrap>
                <p className="title">
                    {filters['q'] && (
                        <span>
                            Search result for: <strong>{filters['q']}</strong>
                        </span>
                    )}
                </p>
                <Search
                    className="table-search"
                    placeholder="Search"
                    onSearch={onSearch}
                    enterButton
                    allowClear
                />
            </SearchWrap>
            <TableWrapper>
                <TableResponsive>
                    <Table
                        loading={loading}
                        dataSource={clientsData}
                        columns={columns}
                        rowKey={'id'}
                        pagination={false}
                        onChange={handleTableChange}
                    />
                </TableResponsive>

                <Pagination
                    defaultCurrent={1}
                    total={pagination.total}
                    current={pagination.current}
                    onChange={onPageChange}
                />
            </TableWrapper>
        </Fragment>
    );
}

const SearchWrap = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px 10px;

  .title {
    margin-bottom: 0;
    order: 1;
  }

  .title:empty {
    height: 0;
  }

  .table-search {
    max-width: 350px;
    order: -1;
    margin-bottom: 10px;
  }

  @media (min-width: 767px) {
    flex-direction: row;

    .table-search {
      order: 1;
      margin-bottom: 0;
    }
  }
`;

const TableWrapper = styled.div`
  .ant-table {
    font-size: 12px;
  }

  .ant-pagination {
    text-align: right;
    margin: 16px 0;
  }
`;

export default Users;
