/* eslint-disable react/display-name */
import {
    PageHeader,
    Button,
    Input,
    message,
    Form,
    Select,
} from 'antd';
import { endpoints, useApi } from 'api';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import {USERS} from "../../routes/paths";

const { Option } = Select;


function CreateAdmin() {
    const history = useHistory();
    const [api] = useApi();

    const onFinish = async (values) => {
        console.log('Success:', values);
        try {
            const req = await api({
                method: 'post',
                url: `${endpoints.ADMIN_OR_STAFF_CREATE_API_URL}`,
                data: values,
            })
            console.log(req)
            if (req.status === 200) {
                message.success(`User created Successfully`);
                // setTimeout(() => {
                //     window.location.reload();
                // }, 2000);
                history.push(USERS);
            }
            else {
                message.error('Failed to create user');
            }
        }
        catch {
            message.error('Failed to create user');
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Fragment>
            <PageHeader onBack={null} title="Create Admin or Staff" />
            <Form
                name="basic"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[{ required: true, message: 'Please input your First Name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[{ required: true, message: 'Please input your Last Name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input valid Email!', type: 'email', whitespace: false }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item name="staff_or_admin" label="Role" rules={[{ required: true }]}>
                    <Select
                        placeholder="Select an option"
                    >
                        <Option value="admin">Admin</Option>
                        {/* <Option value="Manager">Manager</Option> */}
                        <Option value="case_manager">Case Manager</Option>
                        <Option value="financial_analyst">Financial Analyst</Option>
                    </Select>
                </Form.Item>

                {/* <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                    <Checkbox>Remember me</Checkbox>
                </Form.Item> */}

                <Form.Item wrapperCol={{ offset: 12, span: 2 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Fragment>
    );
}

export default CreateAdmin;
