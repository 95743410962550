import { DATE_FORMAT } from 'const';
import moment from 'moment';

const formateDateTime = (dateTime, format = DATE_FORMAT) => {
    return moment(dateTime).format(format);
};

function cleanObj(obj) {
    for (const propName in obj) {
        if (
            obj[propName] === null ||
            obj[propName] === undefined ||
            obj[propName] === ''
        ) {
            delete obj[propName];
        }
    }
    return obj;
}

export { formateDateTime, cleanObj };
