import PropTypes from 'prop-types';

/*
    FieldWrapper is a component which mimic Ant's Form Item component.
    It's a wrapper around Formik's field components.
*/
function FieldWrapper(props) {
    const { children, label, required, error, isInvalid, hideLabel } = props;

    return (
        <div
            className={`ant-form-item ${
                isInvalid
                    ? 'ant-form-item-with-help ant-form-item-has-error'
                    : ''
            }`}
        >
            {!hideLabel && (
                <div className="ant-form-item-label">
                    <label
                        htmlFor={props.name}
                        className={required && 'ant-form-item-required'}
                        title={label}
                    >
                        {label}
                    </label>
                </div>
            )}
            <div className="ant-form-item-control">
                <div className="ant-form-item-control-input">
                    <div className="ant-form-item-control-input-content">
                        {children}
                    </div>
                </div>
                {isInvalid ? (
                    <div className="ant-form-item-explain ant-form-item-explain-error">
                        <div role="alert">{error}</div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

FieldWrapper.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
    ]),
    required: PropTypes.bool,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
    ]),
    isInvalid: PropTypes.bool,
};

export default FieldWrapper;
