import React from 'react'
import { Button, Tooltip } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons';

import styled from 'styled-components';

const AcceptButton = ({ onAcceptClick, disabled }) => {
  return (
    <Tooltip title="Accept">
      <AcceptBtn size="middle" icon={<CheckCircleFilled />} type="text" onClick={onAcceptClick} disabled={disabled}/>
    </Tooltip>
  )
}

const AcceptBtn = styled(Button)`
  color: #45A421;

  :hover {
    color: #006e00;
  }
`;

export default AcceptButton;