import React, { useRef } from 'react'

import { Button, Tooltip } from 'antd'
import { UploadOutlined } from '@ant-design/icons';

import styled from 'styled-components';

import { ACCEPTED_FILE_TYPES } from 'const';

const UploadButton = ({ onUpload, disabled }) => {
  const handleClick = event => {
    hiddenFileInput.current.click();
};
  const onSelectFile = event => {
    event.preventDefault();
    const file = event.target.files[0];

    onUpload(file);
  };

  const hiddenFileInput = useRef(null);

  return (
    <Tooltip title="Upload document">
      <AcceptBtn size="middle" icon={<UploadOutlined />} type="text" onClick={handleClick} disabled={disabled}>
        <input
          ref={hiddenFileInput}
          type="file"
          accept={ACCEPTED_FILE_TYPES}
          multiple
          hidden
          onChange={onSelectFile}
        />
      </AcceptBtn> 
    </Tooltip>
  )
}

const AcceptBtn = styled(Button)`
  width: 32px;
  display: inline-flex;
  justify-content: center;
  color: #5037de;

  :hover {
    color: #2c2acf;
  }
`;

export default UploadButton