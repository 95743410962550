// import RequestDocumentWrapper from './ClientDetails';
//
// export default RequestDocumentWrapper;
import { Switch, Route } from 'react-router-dom';
import { REQUEST_DOCUMENT } from 'routes/paths';
import RequestDocumentComponent from './ClientDetails';

function RequestDocumentWrapper(props) {
    return (
        <Switch>
            <Route exact path={REQUEST_DOCUMENT}>
                <RequestDocumentComponent />
            </Route>
        </Switch>
    );
}

export default RequestDocumentWrapper;