import { endpoints, useApi } from 'api';

export default function useClientUpdate(onSuccess, onFailed, triggerLoader) {
    const [api] = useApi();

    const updateClient = async (userId, data) => {
        try {
            if (triggerLoader) triggerLoader(true);
            const req = await api({
                method: 'put',
                url: `${endpoints.CLIENTS}${userId}`,
                data,
            });

            const res = await req.data;

            if (triggerLoader) triggerLoader(false);

            if (res && onSuccess) {
                onSuccess();
            }
        } catch (error) {
            if (triggerLoader) triggerLoader(false);
            if (onFailed) onFailed(error);
        }
    };

    return [updateClient];
}   
