import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { FieldWrapper } from 'components/Wrapper';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 20,
    },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};
const AddFieldInputs = ({ createTaxOrgQ, optionsFieldsUpdated}) => {
  return (
    <Form
    name="dynamic_form_item"
    {...formItemLayoutWithOutLabel}
    onFinish={createTaxOrgQ}
    onFieldsChange={optionsFieldsUpdated}
    style={{maxWidth: 600}}
  >
      <Form.List
        name="options"
        initialValue={["",""]}
      >
        {(fields, { add, remove }, { errors }) => {

          return (
          <FieldWrapper
            label="Possible Answers">
            {fields.map((field, index) => (
              <Form.Item
                {...(formItemLayout)}
                label={null}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  
                  noStyle
                >
                  <Input
                    placeholder={`Answer #${index + 1}`}
                    style={{
                      width: '60%',
                    }}
                  />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    onClick={() => remove(field.name)}
                    style={{margin: "5px", color: "red"}}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item {...(formItemLayout)} style={{marginBottom: "0px"}}>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{
                  width: '60%',
                }}
                icon={<PlusOutlined />}
              >
                Add Option
              </Button>

              <Form.ErrorList errors={errors} />
            </Form.Item>
          </FieldWrapper>
        )}}
      </Form.List>
      </Form>


  );
};
export default AddFieldInputs;