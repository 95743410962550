const initialState = {
  categoriesData: null,
  categoriesLoading: false,
  categoriesError: null,
};

export const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_CATEGORIES':
      return { ...state, categoriesLoading: true, categoriesError: null, categoriesData: [] };
    case 'FETCH_CATEGORIES_SUCCESS':
      return { ...state, categoriesLoading: false, categoriesError: null, categoriesData: action.payload };
    case 'FETCH_CATEGORIES_ERROR':
      return { ...state, categoriesLoading: true, categoriesError: action.payload, categoriesData: [] };
  
    default:
      return state;
  }
}