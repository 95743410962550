import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, Pagination, Tag } from 'antd';

import styled from 'styled-components';

import { TableResponsive } from 'components/Styled'
import { useApi } from 'api';
import { useActions } from 'hooks/useAction';
import { formattedLocaleDateTime } from 'utilities/dateUtils';
import ClientContext from '../ClientContext/ClientContext';
import { useContext } from "react";

const DocumentsAuditLog = () => {
  const { clientDetails } = useContext(ClientContext);
  const caseId = clientDetails ? clientDetails.case_id : null;

  const { data, loading } = useSelector(state => state.documentsAuditLog);

  const [page, setPage] = useState(1);
  const [limit, setlimit] = useState(10);

  const [api] = useApi(true);

  const {
    fetchDocumentsAuditLog,
  } = useActions();

  useEffect(() => {
    fetchDocumentsAuditLog(api, caseId, page, limit);
  }, [caseId, api, page, limit]);

  const columns = [
    {
      title: '#',
      dataIndex: '#',
      render: (text, record, index) => index + 1,
      sorter: false,
    },
    {
      title: 'User',
      dataIndex: 'user_modified',
      render: (user) => <Tag color="green">{ user }</Tag>,
      sorter: false,
    },
    {
      title: 'Description',
      dataIndex: 'audit_description',
      render: (audit_description) => audit_description,
      sorter: false,
    },
    {
      title: 'Date',
      dataIndex: 'date_inserted',
      render: (date_inserted) => formattedLocaleDateTime(date_inserted),
      sorter: false,
    },
  ]

  return (
    <div>
      <TableWrapper>
        <TableResponsive>
          <Table
            loading={loading}
            dataSource={data.data}
            columns={columns}
            pagination={false}
            rowKey={'id'}
          />
          <Pagination total={data.total} defaultCurrent={page} style={{ margin: 10 }} onChange={setPage} onShowSizeChange={setlimit} />
        </TableResponsive>
      </TableWrapper>
    </div>
  )
}

const TableActionButtons = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  button {
    border-radius: 4px;
    margin-left: 24px;
  }
`;

const TableWrapper = styled('div')`
  .ant-table {
      font-size: 12px;
  }

  .ant-pagination {
      text-align: right;
      margin: 16px 0;
  }
`;

const Status = styled('span')`
  text-transform: capitalize;
`;

const NotifyText = styled('span')`
  margin: 0 8px;
  color: #808080;
`;

export default DocumentsAuditLog;
