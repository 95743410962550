/* eslint-disable no-undef */
const REACT_APP_API_PROTOCOL = process.env['REACT_APP_API_PROTOCOL'];
const DUE_PAY_ENDPOINT = process.env['REACT_APP_DUE_PAYMENT'];
const API_GATEWAY = process.env['REACT_APP_API_GATEWAY'];
const ACTIVITY_LOGGER_ENDPOINT = process.env['REACT_APP_ACTIVITY_LOGGER'];
const REACT_APP_API_ENDPOINT = process.env['REACT_APP_API_ENDPOINT'];

const api_ver = 'api/v1';
const LOGIN = `/${api_ver}/staff-login/`;
const CLIENTS = `/${api_ver}/users/`;
const STAFFS = `/${api_ver}/users/staffs`;
const RESET_DUE_PAY = `${DUE_PAY_ENDPOINT}/init/`;
const DUE_PAY_LIST = `${DUE_PAY_ENDPOINT}/list/`;
const MERCHANT_LIST = `${DUE_PAY_ENDPOINT}/merchant/`;
const SUBMIT_DUE_PAY = `${DUE_PAY_ENDPOINT}/submit/`;
const PLAID_TRANSACTIONS = `${API_GATEWAY}/plaid/transactions/`;
const PLAID_PERIODIC = `${API_GATEWAY}/plaid/periodic-amount/`;
const PLAID_BANKS = `${API_GATEWAY}/plaid/banks/`;
const DOCUMENTS = `${API_GATEWAY}/doc`;
const CHAT = `${API_GATEWAY}/chat/api/v1`;
const TAX_ORG_YEAR_QUESTIONS = `${DOCUMENTS}/v1/tax-org-questions`;
const UPLOADED_DOCUMENTS = `${API_GATEWAY}/doc/v1/documents`;


const ACTIVITY_REPORT_ALL = `${REACT_APP_API_PROTOCOL}/${ACTIVITY_LOGGER_ENDPOINT}/${api_ver}/report/all`;
const NEW_CLIENTS_REPORT = `${REACT_APP_API_PROTOCOL}/${ACTIVITY_LOGGER_ENDPOINT}/${api_ver}/report/cp/group-by/new-accounts`;
const NEW_UNVERIFIED_CLIENTS_REPORT = `${REACT_APP_API_PROTOCOL}/${ACTIVITY_LOGGER_ENDPOINT}/${api_ver}/report/cp/group-by/new-accounts-unverified-email`;
const NEW_VERIFIED_CLIENTS_REPORT = `${REACT_APP_API_PROTOCOL}/${ACTIVITY_LOGGER_ENDPOINT}/${api_ver}/report/cp/group-by/new-accounts-verified-email`;
const REVISITED_CLIENTS_REPORT = `${REACT_APP_API_PROTOCOL}/${ACTIVITY_LOGGER_ENDPOINT}/${api_ver}/report/cp/group-by/revisited-clients`;
const VERIFILINK_COMPLETED_REPORT = `${REACT_APP_API_PROTOCOL}/${ACTIVITY_LOGGER_ENDPOINT}/${api_ver}/report/cp/group-by/revisited-clients`;
const VERIFILINK_IN_PROGRESS_REPORT = `${REACT_APP_API_PROTOCOL}/${ACTIVITY_LOGGER_ENDPOINT}/${api_ver}/report/cp/group-by/revisited-clients`;
const VL_GROUP_BY_COMPLETED = `${REACT_APP_API_PROTOCOL}/${ACTIVITY_LOGGER_ENDPOINT}/${api_ver}/report/vl/group/complete`;
const VL_GROUP_BY_IN_PROGRESS = `${REACT_APP_API_PROTOCOL}/${ACTIVITY_LOGGER_ENDPOINT}/${api_ver}/report/vl/group/in-progress`;
const VL_GET_CLIENTS = `${REACT_APP_API_PROTOCOL}/${ACTIVITY_LOGGER_ENDPOINT}/${api_ver}/report/vl/clients`;
const INVALIDATE_TOKEN = `${REACT_APP_API_PROTOCOL}/${REACT_APP_API_ENDPOINT}/${api_ver}/logout`;

const SEND_RESET_PASSWORD = `${REACT_APP_API_PROTOCOL}/${REACT_APP_API_ENDPOINT}/${api_ver}/password-recovery`;
const GENERATE_NEW_PASSWORD = `${REACT_APP_API_PROTOCOL}/${REACT_APP_API_ENDPOINT}/${api_ver}/login/autogenerate-reset-password`;

const GET_CLIENT_PORTAL_SIGNUP_URL = `${REACT_APP_API_PROTOCOL}/${REACT_APP_API_ENDPOINT}/${api_ver}/users/get-registration-url`;
const CHANGE_PASSWOR_API_URL = `${REACT_APP_API_PROTOCOL}/${REACT_APP_API_ENDPOINT}/${api_ver}/users/staff-change-password`;

const RESEND_CLIENT_PORTAL_VERIFICATION_EMAIL = `${REACT_APP_API_PROTOCOL}/${REACT_APP_API_ENDPOINT}/${api_ver}/login/admin-resend-email-verification-email`;

const ADMIN_OR_STAFF_CREATE_API_URL = `${REACT_APP_API_PROTOCOL}/${REACT_APP_API_ENDPOINT}/${api_ver}/users/create-new-admin-user`;


export default {
    REACT_APP_API_ENDPOINT,
    LOGIN,
    CLIENTS,
    STAFFS,
    RESET_DUE_PAY,
    DUE_PAY_LIST,
    MERCHANT_LIST,
    SUBMIT_DUE_PAY,
    PLAID_TRANSACTIONS,
    PLAID_PERIODIC,
    ACTIVITY_REPORT_ALL,
    NEW_CLIENTS_REPORT,
    NEW_UNVERIFIED_CLIENTS_REPORT,
    NEW_VERIFIED_CLIENTS_REPORT,
    REVISITED_CLIENTS_REPORT,
    PLAID_BANKS,
    VERIFILINK_COMPLETED_REPORT,
    VERIFILINK_IN_PROGRESS_REPORT,
    VL_GROUP_BY_IN_PROGRESS,
    VL_GROUP_BY_COMPLETED,
    VL_GET_CLIENTS,
    INVALIDATE_TOKEN,
    SEND_RESET_PASSWORD,
    GENERATE_NEW_PASSWORD,
    GET_CLIENT_PORTAL_SIGNUP_URL,
    RESEND_CLIENT_PORTAL_VERIFICATION_EMAIL,
    ADMIN_OR_STAFF_CREATE_API_URL,
    DOCUMENTS,
    CHAT,
    CHANGE_PASSWOR_API_URL,
    TAX_ORG_YEAR_QUESTIONS,
    UPLOADED_DOCUMENTS
};
