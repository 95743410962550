import PropTypes from 'prop-types';
import TransactionsTable from './TransactionsTable';

const Transactions = ({ banksFilter, caseId }) => {
    return (
        <TransactionsTable
            caseId={caseId}
            params={{ bank_names: banksFilter }}
        />
    );
};

Transactions.propTypes = {
    banksFilter: PropTypes.string,
    caseId: PropTypes.number,
};

Transactions.defaultProps = {
    banksFilter: '',
    caseId: null,
};

export default Transactions;
