import { endpoints } from 'api';
import { message } from 'antd';

export const fetchAbandonedDocuments = (api, page=1, limit=10) => {
  return async (dispatch) => {

    try {
      dispatch({ type: 'FETCH_ABANDONED_DOCUMENTS' });

      const { data } = await api({
        url: `${endpoints.DOCUMENTS}/v1/cases/manager/abandoned?limit=${limit}&page=${page}`,
      });

      dispatch({
        type: 'FETCH_ABANDONED_DOCUMENTS_SUCCESS',
        payload: data,
      });

    } catch (error) {
      dispatch({
        type: 'FETCH_ABANDONED_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const fetchDocumentsDashboard = (api) => {
  return async (dispatch) => {

    try {
      dispatch({ type: 'FETCH_DOCUMENTS_DASHBOARD' });

      const { data } = await api({
        url: `${endpoints.DOCUMENTS}/v1/cases/dashboard`,
      });

      dispatch({
        type: 'FETCH_DOCUMENTS_DASHBOARD_SUCCESS',
        payload: data,
      });

    } catch (error) {
      dispatch({
        type: 'FETCH_DOCUMENTS_DASHBOARD_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const fetchUploadedDocuments = (api, page=1, limit=10) => {
  return async (dispatch) => {

    try {
      dispatch({ type: 'FETCH_UPLOADED_DOCUMENTS' });

      const { data } = await api({
        url: `${endpoints.DOCUMENTS}/v1/cases/manager/review?limit=${limit}&page=${page}`,
      });

      dispatch({
        type: 'FETCH_UPLOADED_DOCUMENTS_SUCCESS',
        payload: data,
      });

    } catch (error) {
      dispatch({
        type: 'FETCH_UPLOADED_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};
