const initialState = {
  documentsData: null,
  documentsLoading: false,
  documentsError: null,
  notificationData: null,
};

export const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DOCUMENTS':
      return { ...state, documentsLoading: true, documentsError: null, documentsData: [] };
    case 'FETCH_DOCUMENTS_SUCCESS':
      return { ...state, documentsLoading: false, documentsError: null, documentsData: action.payload };
    case 'FETCH_DOCUMENTS_ERROR':
      return { ...state, documentsLoading: true, documentsError: action.payload, documentsData: [] };
    case 'NOTIFY_DOCUMENTS_SUCCESS':
      return { ...state, notificationData: action.payload };
    default:
      return state;
  }
}
