import { PageHeader, Table } from 'antd';
import { Fragment } from 'react';

const dataSource = [
    {
        id: 1,
        merchant: 'Auth.net',
        approved: '$0',
        declined: '$0',
        total_transactions: 0,
        avg_transaction: '$0',
    },
    {
        id: 3,
        merchant: 'Vyva Pay',
        approved: '$0',
        declined: '$0',
        total_transactions: 0,
        avg_transaction: '$0',
    },
    {
        id: 4,
        merchant: 'Court Compass',
        approved: '$0',
        declined: '$0',
        total_transactions: 0,
        avg_transaction: '$0',
    },
];

const columns = [
    {
        title: 'Merchant',
        dataIndex: 'merchant',
    },
    {
        title: 'Approved',
        dataIndex: 'approved',
    },
    {
        title: 'Declined',
        dataIndex: 'declined',
    },
    {
        title: '# of Transactions',
        dataIndex: 'total_transactions',
    },
    {
        title: 'Avg Transaction Size',
        dataIndex: 'avg_transaction',
    },
];

function MerchantSummary(props) {
    return (
        <Fragment>
            <PageHeader onBack={null} title="Merchants Record" />
            <Table
                dataSource={dataSource}
                pagination={false}
                columns={columns}
                rowKey={'id'}
            />
        </Fragment>
    );
}

export default MerchantSummary;
