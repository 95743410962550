import React, { useState, useEffect } from 'react'
import { endpoints } from 'api';
import { Button, Select, Modal, Table, Space, Spin, Alert} from 'antd'
import { yearRanges } from 'const';
import { useApi } from 'api';
import DeleteTaxOrgQButton from 'components/TableActionButtons/DeleteTaxOrgQButton/DeleteTaxOrgQButton';
import CreateTaxOrgQModal from '../CreateTaxOrgQModal/CreateTaxOrgQModal';
import styled from 'styled-components';

const TaxOrgQsModal = ({ title }) => {
  const [api] = useApi(true);
  const [visible, setVisible] = useState(false);
  const [ taxOrgQs, setTaxOrgQs ] = useState([])
  const [ dataIsLoading, setDataIsLoading ] = useState(false)
  const [ selectedYear, setSelectedYear ] = useState(null)
  const [ triggerUpdateToTaxOrgQs, setTriggerUpdateToTaxOrgQs ] = useState(false)

  useEffect(() => {
    async function grabTaxOrgQs(){
      try {
        if (!selectedYear) {
          return
        }
        setDataIsLoading(true)

        const { data } = await api({
          url: `${endpoints.DOCUMENTS}/v1/tax-org-questions/${selectedYear}`,
        });
        if (data) {
          const taxOrgQsArray = []
          if (data.length > 0 ) {
            for (const item of data) {
              if (item.options) {
                item.options = item.options.join(', ')
              }
              item.key = item.id
              taxOrgQsArray.push(item)
            }
          }
          setTaxOrgQs(taxOrgQsArray)
          setDataIsLoading(false)
        }
      } catch(error) {
        console.log(error)
        setDataIsLoading(false)
      }
    }
    grabTaxOrgQs()

  }, [selectedYear, triggerUpdateToTaxOrgQs]);

  async function deleteRequest(id) {
    setDataIsLoading(true)
    try{
      const res = await api({
        method: 'delete',
        url: `${endpoints.DOCUMENTS}/v1/tax-org-questions/${id}`,
      });
      if (res.status === 200) {
        const newTaxOrgQs = taxOrgQs.filter((item)=>{return item.id !== id})
        setTaxOrgQs(newTaxOrgQs)
      } 
      setDataIsLoading(false)
    } catch(error) {
      console.log(error)
      setDataIsLoading(false)
    }
  }

  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Options',
      dataIndex: 'options',
      key: 'options',
    },
    {
      title: 'Updated By',
      dataIndex: 'last_user_modified',
      key: 'last_user_modified',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
            <DeleteTaxOrgQButton
              handleDelete={() => deleteRequest(record.id)}
              title={`Delete this question?`}
            />
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button type="primary" ghost onClick={showModal}>
        {title}
      </Button>
      <Modal
          title="Additional Tax Organizer Questions"
          visible={visible}
          centered
          footer={null}
          onCancel={handleCancel}
          width={1000}
          bodyStyle={{padding: "15px"}}
        >
          <InputWrapper>
            <Select 
              placeholder="Year" 
              onChange={(val) =>setSelectedYear(val)} 
              options={yearRanges}
            />
            <CreateTaxOrgQModal 
              title="Create Question" 
              setTriggerUpdateToTaxOrgQs={setTriggerUpdateToTaxOrgQs}
              triggerUpdateToTaxOrgQs={triggerUpdateToTaxOrgQs}
            />        
          </InputWrapper>
          <Table 
            loading={dataIsLoading} 
            columns={columns} 
            dataSource={taxOrgQs} 
            pagination={false}
          />
      </Modal>
    </>
  )
}

const InputWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export default TaxOrgQsModal
