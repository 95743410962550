import {Alert, Layout, Tabs, Row, PageHeader, Input, Space} from 'antd';
import {Fragment, useState} from 'react';
import ClientProfile from './ClientProfile';
import Documents from './Documents/Documents';
import {endpoints, useApi} from 'api';

const {TabPane} = Tabs;
const {Content} = Layout;
const {Search} = Input;

function RequestDocumentComponent(props) {
    const [case_id, setCaseId] = useState(null);
    const [clientDetails, setClientDetails] = useState({});
    const [show, setShow] = useState(false);
    const [isError, setIsError] = useState(false);
    const [api] = useApi();

    const onSearch = async (searchQuery) => {
        setCaseId(searchQuery);
        try {
            const req = await api({url: `${endpoints.CLIENTS}case/${searchQuery}`});
            const res = await req.data;
            setClientDetails(res);
            setShow(true);
            setIsError(false);
        } catch (e) {
            console.log(e)
            setClientDetails({});
            setShow(false);
            setIsError(true);
        }
    };
    return (
        <Fragment>
            <PageHeader
                onBack={null}
                ghost={false}
            >
                <Row>
                    <Search
                        className="table-search"
                        placeholder="Search Case ID"
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                </Row>
            </PageHeader>
            {isError ? <Alert message="Invalid Case ID" type="error" showIcon/> : ''}
            {show ? <>
                <ClientProfile clientDetails={clientDetails}/>
                <Content
                    style={{
                        padding: '16px 24px',
                        backgroundColor: 'white',
                        marginTop: 20,
                    }}
                >
                    <Tabs defaultActiveKey="documents" type="card">
                        <TabPane tab="Documents" key="documents">
                            <Documents clientDetails={clientDetails}/>
                        </TabPane>
                    </Tabs>
                </Content>
            </> : ''}
        </Fragment>
    );
}

export default RequestDocumentComponent;
