/* eslint-disable react/display-name */
import {
    Table,
    Form,
    InputNumber,
    Select,
    Button,
    Pagination,
    Row,
    Col,
    notification,
} from 'antd';
import MerchantSummary from './MerchantSummary';
import PaymentFilter from './PaymentFilter';
import PaymentHeader from './PaymentHeader';
import { endpoints, useApi } from 'api';
import { Fragment, useCallback, useEffect, useState } from 'react';
import PaymentStatus from './PaymentStatus';
import styled from 'styled-components';
import { cleanObj } from 'library';
import { CheckSquareFilled, ReloadOutlined } from '@ant-design/icons';

function handleError(error) {
    if (!error['response']) {
        return;
    }

    const { status } = error.response;

    if (status === 500) {
        notification.error({
            message: 'Something went wrong',
            description: 'Please contact the support team!',
        });
    }
}

function ManualPayment(props) {
    const [paymentData, updatePaymentData] = useState([]);
    const [merchantsData, setMerchantsData] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [loading, toggleLoading] = useState(false);
    const [filter, setFilter] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [ordering, setOrdering] = useState('');

    const [form] = Form.useForm();
    const [api] = useApi();

    useEffect(() => {
        fetchMerchants();
    }, [fetchMerchants]);

    useEffect(() => {
        fetchPaymentData();
    }, [fetchPaymentData, filter, ordering]);

    const fetchMerchants = useCallback(async () => {
        try {
            toggleLoading(true);
            const req = await api({
                url: endpoints.MERCHANT_LIST,
            });
            const res = await req.data;

            setMerchantsData(res);
            toggleLoading(false);
        } catch (error) {
            toggleLoading(false);
            handleError(error);
        }
    }, [api]);

    const fetchPaymentData = useCallback(
        async (pagination = { current: 1, pageSize: 10 }) => {
            try {
                toggleLoading(true);
                const { current, pageSize } = pagination;

                const req = await api({
                    url: endpoints.DUE_PAY_LIST,
                    params: {
                        page: current - 1,
                        size: pageSize,
                        ...filter,
                        ordering,
                    },
                });

                const res = await req.data;

                updatePaymentData(res.items);
                toggleLoading(false);
                setPagination({
                    ...pagination,
                    total: res.total,
                });
            } catch (error) {
                toggleLoading(false);
                handleError(error);
            }
        },
        [api, filter, ordering]
    );

    const paymentSubmit = async (record) => {
        try {
            const { getFieldValue, setFields } = form;
            const data = {
                uid: record.uid,
                amount: getFieldValue(`amount[${record.id}]`),
                merchant_uid: getFieldValue(`merchant_uid[${record.id}]`),
            };

            if (data.merchant_uid === 'default') {
                data['merchant_uid'] = null;
            }

            if (!data['amount']) {
                setFields({
                    [`amount[${record.id}]`]: {
                        errors: [new Error('Set Amount')],
                    },
                });

                return;
            }

            toggleLoading(true);

            const req = await api({
                method: 'post',
                url: endpoints.SUBMIT_DUE_PAY,
                data,
            });

            const res = await req.data;

            toggleLoading(false);

            if (res) {
                notification.success({ message: 'Payment Complete' });
                fetchPaymentData(pagination);
            }
        } catch (error) {
            toggleLoading(false);
            handleError(error);
        }
    };

    const columns = [
        {
            title: 'Case ID',
            dataIndex: 'caseid',
            width: 100,
            sorter: true,
        },
        {
            title: 'Payment Due',
            dataIndex: 'AmountPastDue',
            width: 200,
            sorter: true,
            render: (data, record) => (
                <Form.Item
                    name={`amount[${record.id}]`}
                    initialValue={data}
                    style={{ marginBottom: 0 }}
                    rules={[{ required: true, message: 'Set amount' }]}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        min={0}
                    />
                </Form.Item>
            ),
        },
        {
            title: 'Merchant',
            width: 220,
            render: (_, record) => (
                <Form.Item
                    name={`merchant_uid[${record.id}]`}
                    initialValue={record.merchant || 'default'}
                    style={{ marginBottom: 0 }}
                >
                    <Select>
                        <Select.Option value="default">Emily</Select.Option>
                        {merchantsData.map((merchant, key) => (
                            <Select.Option key={key} value={merchant.uid}>
                                {merchant.title}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            ),
        },
        {
            title: 'Payment Due at',
            dataIndex: 'NextPayDate',
            render: (data) => data || '-',
            sorter: true,
        },
        {
            title: 'Process Payment',
            width: 180,
            render: (_, record) => (
                <Button
                    size="small"
                    onClick={paymentSubmit.bind(null, record)}
                    disabled={record.success === true}
                >
                    Submit
                </Button>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'success',
            width: 115,
            render: (data) => <PaymentStatus status={data} />,
        },
    ];

    const onPageChange = (page, pageSize) => {
        const newPage = {
            current: page,
            pageSize,
        };

        setPagination((curPageData) => ({
            ...curPageData,
            ...newPage,
        }));

        fetchPaymentData(newPage);
    };

    const handleTableChange = (a, b, sorting) => {
        let ordering = '';

        const { order, field } = sorting;

        if (order) {
            ordering = `${order === 'ascend' ? '' : '-'}${field}`;
        }

        setOrdering(ordering);
    };

    const resetList = async () => {
        try {
            toggleLoading(true);
            const req = await api({ url: endpoints.RESET_DUE_PAY });
            const res = await req.data;

            toggleLoading(false);

            if (res) {
                fetchPaymentData();
            }
        } catch (error) {
            toggleLoading(false);
            handleError(error);
        }
    };

    const applyFilter = (filterQuery) => {
        const newFilter = { ...filter, ...filterQuery };
        const cleanFilter = cleanObj(newFilter);

        if (JSON.stringify(filter) !== JSON.stringify(cleanFilter)) {
            setFilter(cleanFilter);
        }
    };

    const onRowSelection = (_, selectedRows) => {
        setSelectedRows(selectedRows);
    };

    return (
        <Fragment>
            <PaymentHeader />
            <TableWrapper>
                <Row justify="space-between" gutter={20}>
                    <Col>
                        <Button
                            type="primary"
                            onClick={resetList}
                            icon={<ReloadOutlined />}
                        >
                            RESET
                        </Button>

                        <Button
                            type="primary"
                            onClick={null}
                            icon={<CheckSquareFilled />}
                            style={{ marginLeft: 20 }}
                            disabled={!selectedRows.length}
                        >
                            SUBMIT SELECTED
                        </Button>
                    </Col>
                    <Col>
                        <PaymentFilter onFilter={applyFilter} />
                    </Col>
                </Row>
                <TableResponsive>
                    <Form form={form}>
                        <Table
                            dataSource={paymentData}
                            columns={columns}
                            rowKey={'id'}
                            pagination={false}
                            loading={loading}
                            rowSelection={{
                                onChange: onRowSelection,
                            }}
                            onChange={handleTableChange}
                        />
                    </Form>
                </TableResponsive>
                <Pagination
                    defaultCurrent={1}
                    total={pagination.total}
                    current={pagination.current}
                    onChange={onPageChange}
                />
            </TableWrapper>

            <MerchantSummary />
        </Fragment>
    );
}

const TableWrapper = styled.div`
    .ant-pagination {
        text-align: right;
        margin: 16px 0;
    }
`;

const TableResponsive = styled.div`
    max-width: 100%;
    overflow-y: auto;

    .ant-table table {
        background-color: #fff;
    }
`;

export default ManualPayment;
