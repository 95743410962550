import moment from "moment";

export const DATE_FORMAT = 'MM-DD-YYYY';
export const TYPE_ADMIN = 10;
export const COLORS = [
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];

export const ACCEPTED_FILE_TYPES =
  'image/jpg,image/png,image/jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';


export const LETTER_CASES_PATTERN = /(?=.*[a-z])(?=.*[A-Z])/;
export const CHARACTERS_TYPE_PATTERN = /(?=.*\d)(?=.*[a-zA-Z])/;
export const SPECIAL_SYMBOLS_PATTERN = /(?=.*\W)/;
export const FORBIDDEN_SPECIAL_SYMBOLS_PATTERN = /^[^<>]*$/;


export const AdminType = 10
export const ManagerType = 20
export const CaseManagerType = 30
export const FinancialAnalystType = 40
export const ClientType = 50

export const monthRanges = [
  {
    label: 'Last 1 months',
    value: '1_months',
    years: 0,
    months: 1
  },
  {
    label: 'Last 2 months',
    value: '2_months',
    years: 0,
    months: 2
  },
  {
    label: 'Last 3 months',
    value: '3_months',
    years: 0,
    months: 3
  },
  {
    label: 'Last 4 months',
    value: '4_months',
    years: 0,
    months: 4
  },
  {
    label: 'Last 5 months',
    value: '5_months',
    years: 0,
    months: 5
  },
  {
    label: 'Last 6 months',
    value: '6_months',
    years: 0,
    months: 6
  },
  {
    label: 'Last 7 months',
    value: '7_months',
    years: 0,
    months: 7
  },
  {
    label: 'Last 8 months',
    value: '8_months',
    years: 0,
    months: 8
  },
  {
    label: 'Last 9 months',
    value: '9_months',
    years: 0,
    months: 9
  },
  {
    label: 'Last 10 months',
    value: '10_months',
    years: 0,
    months: 10
  },
  {
    label: 'Last 11 months',
    value: '11_months',
    years: 0,
    months: 11
  },
  {
    label: 'Last 12 months',
    value: '12_months',
    years: 0,
    months: 12
  },
  {
    label: 'Year to Date',
    value: 'current_year',
    years: 0,
    months: moment().month() + 1
  },
  {
    label: 'Prior Year',
    value: 'prior_year',
    years: 1,
    months: 12
  }
]

export const yearRanges = [
  {
    label: '2012',
    value: '2012',
  },
  {
    label: '2013',
    value: '2013',
  },
  {
    label: '2014',
    value: '2014',
  },
  {
    label: '2015',
    value: '2015',
  },
  {
    label: '2016',
    value: '2016',
  },
  {
    label: '2017',
    value: '2017',
  },
  {
    label: '2018',
    value: '2018',
  },
  {
    label: '2019',
    value: '2019',
  },
  {
    label: '2020',
    value: '2020',
  },
  {
    label: '2021',
    value: '2021',
  },
  {
    label: '2022',
    value: '2022',
  },
  {
    label: '2023',
    value: '2023',
  },
  {
    label: '2024',
    value: '2024',
  },
  {
    label: '2025',
    value: '2025',
  },
]