import React, { useState } from 'react'
import { useForm } from 'react-hook-form';

import { Button, Modal, Tooltip } from 'antd'
import { DeleteFilled } from '@ant-design/icons';

import styled from 'styled-components';

const DeleteButton = ({ title, handleDelete, disabled }) => {
  const [visible, setVisible] = useState(false);
  const {
    reset,
    handleSubmit,
  } = useForm();

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onSubmit = (data) => {
    handleDelete(data);
    reset();
    setVisible(false);
  };

  return (
    <>
    <Tooltip title="Delete">
      <DeleteBtn size="middle" icon={<DeleteFilled />} type="text" onClick={showModal} disabled={disabled} />
    </Tooltip>
    <Modal
        title={title}
        visible={visible}
        centered
        ghost="false"
        onCancel={handleCancel}
        onOk={handleSubmit(onSubmit)}
        okType="danger"
        okText="Delete"
        cancelText="Cancel"
      >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>It will not remove any answers provided by the Client</div>
      </form>
      </Modal>
    </>
  )
}

const DeleteBtn = styled(Button)`
  color: #FF5455;

  :hover {
    color: #f73838;
  }
`;

export default DeleteButton
