import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, Button, Tooltip, Spin, Pagination, Select, Input } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { TableResponsive } from 'components/Styled'
import { useApi } from 'api';
import { useActions } from 'hooks/useAction';
import { formattedLocaleDateTime } from 'utilities/dateUtils';
import PreviewButton from 'components/TableActionButtons/PreviewButton/PreviewButton';
import PreviewTaxOrganizerButton from 'components/TableActionButtons/PreviewTaxOrganizerButton/PreviewTaxOrganizerButton';
import RejectButton from 'components/TableActionButtons/RejectButton/RejectButton';
import AcceptButton from 'components/TableActionButtons/AcceptButton/AcceptButton';
import DeleteButton from 'components/TableActionButtons/DeleteButton/DeleteButton';
import UpdateDocument from './UpdateDocumentModal';
import { Col, Row } from 'antd';

const {Search} = Input;

const UploadedDocuments = () => {
  const [currentVisibleUpdateModal, setCurrentVisibleUpdateModal] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [enteredCaseId, setEnteredCaseId] = useState(null);

  const [documentOptions, setDocumentOptions] = useState([]);
  const { categoriesData, categoriesLoading } = useSelector(state => state.categories);
  const { allDocumentsData, allDocumentsLoading, allDocumentsError, allDocumentsTotal } = useSelector(state => state.allDocuments);
  const [api] = useApi(true);
  const {
    fetchAllDocuments,
    fetchCategories,
    acceptDocumentFromAllDocs,
    deleteDocumentFromAllDocs,
    rejectDocumentFromAllDocs,
    updateDocumentFromAllDocs,
  } = useActions();

  useEffect(() => {
    fetchCategories(api);
    fetchAllDocuments(api, 1, 100, "uploaded");
  }, [api]);

  useEffect(() => {
    if (!categoriesData) {
      setDocumentOptions([])
      return
    }
    // Update Document Name Filter selections when a new category has been selected
    const options = []
    for (const category of categoriesData) {
      if (selectedCategory) {
        if (selectedCategory === category.category) {
          options.push({value: category.id, label: category.type})
        }
      } else {
        options.push({value: category.id, label: category.type})
      }
    }
     setDocumentOptions(options)
  }, [selectedCategory, categoriesData]);

  if (allDocumentsLoading || categoriesLoading || !categoriesData || !allDocumentsData) {
    return <div>
      <TableWrapper>
        <TableResponsive>
          <LoadingContainer>
            <Spin />
          </LoadingContainer>
        </TableResponsive>
      </TableWrapper>
    </div>
  }

  const handleDocumentAccept = (request_id, case_id) => {
    acceptDocumentFromAllDocs(api, case_id, request_id, {status: "uploaded", category: selectedCategory, document_type_id: selectedDocument?.id})
  }

  const handleDocumentDelete = (request_id, case_id) => {
    deleteDocumentFromAllDocs(api, case_id, request_id, {status: "uploaded", category: selectedCategory, document_type_id: selectedDocument?.id})
  }

  const handleDocumentReject = (request_id, reason, case_id) => {
    rejectDocumentFromAllDocs(api, case_id, request_id, reason, {status: "uploaded", category: selectedCategory, document_type_id: selectedDocument?.id})
  }

  const handleDocumentUpdate = (request_id, document, case_id) => {
    updateDocumentFromAllDocs(api, case_id, request_id, document, {status: "uploaded", category: selectedCategory, document_type_id: selectedDocument?.id})
  }

  const requestFilteredDocuments = (value, filterOption) => {
    try{
      if (filterOption === "document") {
        for (const category of categoriesData) {
          if (category.id === value){
            setSelectedDocument({id: value, name: category.type})
            setSelectedCategory(category.category)
            fetchAllDocuments(api, 1, 100, "uploaded", null, category.id, enteredCaseId);
            return
          }
        }
      } else if (filterOption === "category") {
        setSelectedDocument(null)
        setSelectedCategory(value)
        fetchAllDocuments(api, 1, 100, "uploaded", value, null, enteredCaseId);
      } else if (filterOption === "case_id") {
        fetchAllDocuments(api, 1, 100, "uploaded", selectedCategory, selectedDocument?.id, value);
      } else if (filterOption === "clear") {
        setSelectedDocument(null)
        setSelectedCategory(null)
        setEnteredCaseId(null)
        setPage(1)
        fetchAllDocuments(api, 1, 100, "uploaded");
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handlePageChange = (selectedPage, pageSize) => {
    setPage(selectedPage)
    if (selectedDocument) {
      fetchAllDocuments(api, page, pageSize, "uploaded", null, selectedDocument.id);
    } else if (selectedCategory) {
      fetchAllDocuments(api, page, pageSize, "uploaded", selectedCategory);
    } else {
      fetchAllDocuments(api, page, pageSize, "uploaded");
    }
  }

  const columns = !categoriesData || categoriesLoading || allDocumentsLoading ? [] : [
    {
      title: '#',
      dataIndex: '#',
      render: (type, data) => data.id,
      sorter: false,
    },
    {
      title: 'Case ID',
      dataIndex: 'case_id',
      render: (type, data) => data.case_id,
      sorter: false,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      render: (type, data) => data?.document_type.category,
      sorter: false,
    },
    {
      title: 'Document',
      dataIndex: 'type',
      render: (type, data) => data?.document_type.type + ' ' + (data?.title ? data.title : ''),
      sorter: false,
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   render: (status) => <Status>{status}</Status>,
    //   filters: [...new Map(allDocumentsData?.map(c => [c.status, { text: c.status, value: c.status }])).values()],
    //   onFilter: (value, record) => record?.status === value,
    //   sorter: false,
    // },
    {
      title: 'Requested',
      dataIndex: 'requested_at',
      render: (requested_at) => formattedLocaleDateTime(requested_at),
      sorter: false,
    },
    {
      title: 'Last Status Updated At',
      dataIndex: 'updated_at',
      render: (updated_at) => formattedLocaleDateTime(updated_at, '-'),
      sorter: false,
    },
    {
      title: 'Actions',
      align: 'center',
      dataIndex: 'actions',
      width: 195,
      render: (_, record) => {
        const { s3_file_url_presigned, id, status, title, document_type, data, case_id } = record
        return (
          <>
            {/* <UploadButton
              onUpload={file => handleUploadDocuments(id, file)}
              disabled={status === 'uploaded' || status === 'approved' || status === 'accepted'}
            /> */}
            {document_type.type == 'Tax Organizer' && (
              <PreviewTaxOrganizerButton  taxOrganizerData={data}/>
            )}
            {document_type.type != 'Tax Organizer' && (
              <PreviewButton
                previewLink={s3_file_url_presigned}
              />
            )}
            <AcceptButton
              onAcceptClick={() => handleDocumentAccept(id, case_id)}
              disabled={status !== 'uploaded'}
            />
            <RejectButton
              handleReject={reason => handleDocumentReject(id, reason, case_id)}
              disabled={status !== 'uploaded'}
            />
            <DeleteButton
              title={title}
              handleDelete={() => handleDocumentDelete(id, case_id)}
            />
             <Tooltip title="Update">
              <Button size="middle" icon={<FormOutlined />} type="text" onClick={() => {
                setCurrentVisibleUpdateModal(id)
              }} />
            </Tooltip>

            <UpdateDocument
              title='Update Document Request'
              categoriesData={categoriesData}
              show={currentVisibleUpdateModal === id}
              document={{ title, document_type, id }}
              setShow={(show) => setCurrentVisibleUpdateModal(show ? id : 0)}
              handleModalSubmit={(document) => handleDocumentUpdate(id, document, case_id)}
            />
          </>
        )
      },
      sorter: false,
    },
  ]

  return (
    <div>
      <TableWrapper>
        <TableResponsive>
          <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col sm={24} lg={3} xxl={2}>
            <FilterTextContainer>
              <h3 style={{margin: 0}}>Filter by:</h3>
            </FilterTextContainer>
          </Col>
          <Col sm={24} lg={21} xxl={14}>
            <div style={{display: "flex", flexWrap: "wrap", gap: 10}}>
              <Select 
                placeholder="Category" 
                onChange={(val) => requestFilteredDocuments(val, "category")}
                value={selectedCategory} 
                style={{ width: 180 }}
                options={[...new Map(categoriesData?.map(c => [c.category, {
                  text: c.category,
                  value: c.category
                }])).values()]}
              />
              <Select 
                placeholder="Document Name" 
                value={selectedDocument?.name} 
                onChange={(val) => requestFilteredDocuments(val, "document")} 
                options={documentOptions}
                style={{ width: 250 }}
              />
              <Search 
                placeholder="Case ID" 
                className="table-search"
                value={enteredCaseId} 
                onSearch={(val) => requestFilteredDocuments(val, "case_id")} 
                onChange={(e) => setEnteredCaseId(e.target.value)}
                style={{ width: 180 }}
                enterButton
                allowClear
              />
              <Button 
                onClick={() => requestFilteredDocuments(null, "clear")} 
                style={{margin: 0, color: "gray", cursor: "pointer"}}
              >
                Clear
              </Button>
            </div>
          </Col>
          <Col xs={24} xxl={8}>
            <PaginationContainer>
              <Pagination 
                showLessItems={true} 
                current={page} 
                total={allDocumentsTotal} 
                pageSize={100} 
                showSizeChanger={false} 
                onChange={(page, pageSize) => handlePageChange(page, pageSize)}
                style={{margin: 0}}
              />
            </PaginationContainer>
          </Col>
          </Row>
             <Table
              loading={allDocumentsLoading}
              dataSource={allDocumentsData}
              columns={columns}
              pagination={false}
              rowKey={'id'}
            />
        </TableResponsive>
      </TableWrapper>
    </div>
  )
}

const TableWrapper = styled('div')`
  .ant-table {
    font-size: 12px;
  }

  .ant-pagination {
    text-align: right;
    margin: 16px 0;
  }
`;

const LoadingContainer = styled('div')`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center
`;
const FilterTextContainer = styled('div')`
  height: 100%;
  display: flex;
  align-items: center
`;

const PaginationContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export default UploadedDocuments;
