import { Switch, Route } from 'react-router-dom';
import { UPLOADED_DOCUMENTS } from 'routes/paths';
import UploadedDocsContainer from './UploadedDocsContainer';

function UploadedDocumentsWrapper(props) {
    return (
        <Switch>
            <Route exact path={UPLOADED_DOCUMENTS}>
                <UploadedDocsContainer />
            </Route>
        </Switch>
    );
}

export default UploadedDocumentsWrapper;
