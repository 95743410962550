import { Layout } from 'antd';
import Menu from 'containers/Menu';
import { useState } from 'react';
import styled from 'styled-components';
import Logo from './Logo';

const { Sider } = Layout;

const triggerStyle = {
    top: '13px',
    borderColor: '#fff',
    border: '1px solid #008ac8',
    borderRadius: '2px',
    height: '36px',
    lineHeight: '36px',
    color: '#008ac8',
};

function Navigation({ onCollapseTrigger }) {
    const [collapsedWidth, setCollapsedWidth] = useState(80);

    const onBreakpoint = (broken) => {
        setCollapsedWidth(broken ? 0 : 80);
    };

    return (
        <SideBar
            onCollapse={onCollapseTrigger}
            breakpoint="sm"
            collapsedWidth={collapsedWidth}
            zeroWidthTriggerStyle={triggerStyle}
            collapsible
            onBreakpoint={onBreakpoint}
            width={218}
        >
            <Logo />
            <Menu />
        </SideBar>
    );
}

const SideBar = styled(Sider)`
    height: 100vh;
    position: fixed;
    left: 0;
`;

export default Navigation;
