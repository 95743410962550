import { endpoints } from 'api';
import { message } from 'antd';

export const fetchDocuments = (api, case_id) => {
  return async (dispatch) => {

    try {
      dispatch({ type: 'FETCH_DOCUMENTS' });

      const { data } = await api({
        url: `${endpoints.DOCUMENTS}/v1/cases/${case_id}/requests`,
      });

      dispatch({
        type: 'FETCH_DOCUMENTS_SUCCESS',
        payload: data,
      });

    } catch (error) {
      dispatch({
        type: 'FETCH_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};


export const fetchLastNotification = (api, case_id) => {
  return async (dispatch) => {
    try {
      const { data } = await api({
        url: `${endpoints.DOCUMENTS}/v1/cases/${case_id}/notification/last`,
      });

      dispatch({
        type: 'NOTIFY_DOCUMENTS_SUCCESS',
        payload: data,
      });

    } catch (error) {
      console.log(error)
    }
  };
};

export const requestDocument = (api, case_id, documents) => {
  return async (dispatch) => {
    try {
      const res = await api({
        method: 'post',
        url: `${endpoints.DOCUMENTS}/v1/cases/${case_id}/requests/`,
        data: documents
      });

      if (res.status === 200) {
        dispatch(fetchDocuments(api, case_id))
      }

    } catch (error) {
      dispatch({
        type: 'FETCH_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const acceptDocument = (api, case_id, request_id) => {
  return async (dispatch) => {
    try {
      const res = await api({
        method: 'put',
        url: `${endpoints.DOCUMENTS}/v1/cases/${case_id}/requests/${request_id}/`,
        data: {
          status: 'approved',
        }
      });

      if (res.status === 200) {
        dispatch(fetchDocuments(api, case_id))
      }

    } catch (error) {
      dispatch({
        type: 'FETCH_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const deleteDocument = (api, case_id, request_id) => {
  return async (dispatch) => {
    try {
      const res = await api({
        method: 'delete',
        url: `${endpoints.DOCUMENTS}/v1/cases/${case_id}/requests/${request_id}/`,
      });

      if (res.status === 200) {
        dispatch(fetchDocuments(api, case_id))
      }

    } catch (error) {
      dispatch({
        type: 'FETCH_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const rejectDocument = (api, case_id, request_id, { rejection_description }) => {
  return async (dispatch) => {
    try {
      const res = await api({
        method: 'put',
        url: `${endpoints.DOCUMENTS}/v1/cases/${case_id}/requests/${request_id}/`,
        data: {
          status: 'rejected',
          rejection_description
        }
      });

      if (res.status === 200) {
        dispatch(fetchDocuments(api, case_id))
      }

    } catch (error) {
      dispatch({
        type: 'FETCH_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const updateDocument = (api, case_id, request_id, document) => {
  return async (dispatch) => {
    try {
      const res = await api({
        method: 'put',
        url: `${endpoints.DOCUMENTS}/v1/cases/${case_id}/requests/${request_id}/`,
        data: document
      });

      if (res.status === 200) {
        dispatch(fetchDocuments(api, case_id))
      }

    } catch (error) {
      dispatch({
        type: 'FETCH_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const notifyDocuments = (api, case_id) => {
  return async (dispatch) => {
    try {
      const res = await api({
        method: 'post',
        data: {},
        url: `${endpoints.DOCUMENTS}/v1/cases/${case_id}/notification/`,
        headers: {
          'content-type': 'application/json',
        }
      });

      if (res.status === 200) {
        message.success('Notify is successfuly sent')

        dispatch({
          type: 'NOTIFY_DOCUMENTS_SUCCESS',
          payload: res.data,
        });
      }

    } catch (error) {
      console.log(error)
    }
  };
};

export const uploadDocument = (api, case_id, request_id, file) => {
  return async (dispatch) => {
    const fileData = new FormData()

    fileData.append('file', file)

    try {
      dispatch({ type: 'FETCH_DOCUMENTS' });

      const res = await api({
        method: 'post',
        url: `${endpoints.DOCUMENTS}/v1/cases/${case_id}/requests/${request_id}/`,
        data: fileData
      });

      if (res.status === 200) {
        dispatch(fetchDocuments(api, case_id))
      }

    } catch (error) {
      dispatch({
        type: 'FETCH_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};