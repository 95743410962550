import { Layout, Button, Menu, Dropdown } from 'antd';
import { UserOutlined, LoginOutlined, LockOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import store from 'store';
import { useHistory } from 'react-router-dom';
import {CHANGE_PASSWORD, LOGIN} from 'routes/paths';
import { useApi, endpoints } from 'api';

const { Header: AntHeader } = Layout;

const UserDropdownMenu = styled(Menu)`
    min-width: 150px;
    margin-top: 5px;
`;

function Header({ triggerMobileNav }) {
    const history = useHistory();
    const [api] = useApi();

    const signOut = () => {
        const logout = async () => {
            try {
                const req = await api({
                    url: endpoints.INVALIDATE_TOKEN,
                    method: 'POST'
                });
                const res = await req.data;
                if (res) {
                    store.clearAll();
                    history.push(LOGIN);
                }
                else {
                    store.clearAll();
                    history.push(LOGIN);
                }
            } catch (error) {
                console.log({ error });
                store.clearAll();
                history.push(LOGIN);
            }
        };
        logout();
    };

    const menu = (
        <UserDropdownMenu>
            <Menu.Item key="3" icon={<LockOutlined />} onClick={()=>{history.push(CHANGE_PASSWORD)}}>
                Change Password
            </Menu.Item>
            <Menu.Item key="3" icon={<LoginOutlined />} onClick={signOut}>
                Sign Out
            </Menu.Item>
        </UserDropdownMenu>
    );
    return (
        <TopBar>
            <Dropdown overlay={menu} trigger={['click']}>
                <UserDropdownBtn
                    type="primary"
                    shape="circle"
                    onClick={(e) => e.preventDefault()}
                    icon={<UserOutlined />}
                    size={'large'}
                />
            </Dropdown>
        </TopBar>
    );
}

const TopBar = styled(AntHeader)`
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const UserDropdownBtn = styled(Button)`
    margin-left: auto;
    margin-right: 20px;
`;

export default Header;
