import {CheckCircleOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {
    Typography,
    Space,
    Divider,
    Row,
    Tooltip,
    PageHeader,
    Tag
} from 'antd';
import {defaultIcon} from 'assets/img';
import {formateDateTime} from 'library';


const {Text} = Typography;

function ClientProfile({clientDetails}) {

    const {
        email,
        case_id,
        is_email_verified,
        date_joined,
        last_login,
        first_name,
        last_name,
        middle_name,
    } = clientDetails;

    const clientStatus =
        date_joined ? (
            <Tag color="green">Registered</Tag>
        ) : (
            <Tag color="orange">Unregistered</Tag>
        );

    return (
        <PageHeader
            onBack={null}
            title={`${first_name} ${middle_name || ''} ${last_name}`}
            avatar={{src: defaultIcon}}
            ghost={false}
            tags={clientStatus}
        >
            <Row>
                <Space split={<Divider type="vertical"/>} style={{flex: 1}}>
                    <div>
                        <Text type="secondary" strong>
                            Case ID
                        </Text>{' '}
                        <br/>
                        <Text>{case_id}</Text>
                    </div>
                    <div>
                        <Text type="secondary" strong>
                            Email
                        </Text>{' '}
                        <br/>
                        <Space>
                            {is_email_verified === true ? (
                                <Tooltip title="Email verified">
                                    <CheckCircleOutlined
                                        style={{color: '#389e0d'}}
                                    />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Email not verified">
                                    <InfoCircleOutlined
                                        style={{color: '#d46b08'}}
                                    />
                                </Tooltip>
                            )}
                            <Text>{email}</Text>
                        </Space>
                    </div>
                </Space>
                <Space split={<Divider type="vertical"/>}>
                    <div>
                        <Text type="secondary" strong>
                            Joined Date
                        </Text>{' '}
                        <br/>
                        <Text>
                            {date_joined ? formateDateTime(date_joined) : ''}
                        </Text>
                    </div>
                    <div>
                        <Text type="secondary" strong>
                            Last Login
                        </Text>{' '}
                        <br/>
                        <Text>
                            {last_login ? formateDateTime(last_login) : ''}
                        </Text>
                    </div>
                </Space>
            </Row>
        </PageHeader>
    );
}

export default ClientProfile;
