import moment from 'moment';

export const formattedLocaleDateTime = (date, message) => {
  if (date === null || date === undefined || date === 0) {
    return <span style={{ color: '#bababa' }}>{message ? message : ''}</span>;
  }

  const utcDate = moment.utc(date)

  return utcDate.local().format("MM/DD/YYYY h:mm:ss A")
};
