import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form';

import { Button, Input, Modal, Select } from 'antd'
import { monthRanges, yearRanges } from '../../../../const/index'
import styled from 'styled-components';

import moment from 'moment';
import { useEffect } from 'react';

const RequestDocument = ({ title, categoriesData, handleRequestDocument }) => {
  const { Option } = Select;
  const [visible, setVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedType, setSelectedType] = useState({})
  const [selectedTypeId, setSelectedTypeId] = useState('')
  const [monthRange, setMonthRange] = useState()
  const [documentYear, setDocumentYear] = useState()
  const [requestBtnDisabled, setRequestBtnDisabled] = useState(false)


  const categoriesArr = [...new Set(categoriesData?.map(cat => cat.category))];
  const filteredTypes = categoriesData?.filter(el => el.category === selectedCategory)

  useEffect(() => {
    if (selectedTypeId && categoriesData.length > 0) {
      console.log(categoriesData?.find(el => el.id === selectedTypeId))
      setSelectedType(categoriesData?.find(el => el.id === selectedTypeId))
    }
  }, [categoriesData, selectedTypeId])

  useEffect(() => {
    if (selectedType) {
      if (selectedType.type === "Tax Organizer" && !documentYear) {
        setRequestBtnDisabled(true)
      } else {
        setRequestBtnDisabled(false)
      }
    }
  }, [documentYear, selectedType]);

  const {
    reset,
    handleSubmit,
    setValue,
    control
  } = useForm();

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setDocumentYear(null)
    setSelectedTypeId(null);
    setMonthRange(null);
    setDocumentYear(null);
    setVisible(false);
  };

  const onSubmit = (data) => {
    const documents = []

    if (monthRange) {
      const dateRangeObject = monthRanges.find(el => el.value === monthRange)
      for (let i = 0; i < dateRangeObject.months; i++) {
        const monthName = moment().subtract(dateRangeObject.years, 'years').subtract(i + 1, 'months').format('MMM')
        const year = moment().subtract(dateRangeObject.years, 'years').subtract(i + 1, 'months').format('YYYY')
        const document = {
          document_type_id: selectedTypeId,
          title: data.title + ' ' + monthName + ' ' + year,
        }

        documents.push(document)
      }
    } else if (documentYear) {
      const document = {
        document_type_id: selectedTypeId,
        title: data.title + ' ' + documentYear,
        period: documentYear
      }

      documents.push(document)
    } else {
      const document = {
        document_type_id: selectedTypeId,
        title: data.title,
      }
      documents.push(document)
    }

    handleRequestDocument(documents);
    reset();
    handleCancel();
  };

  return (
    <>
      <Button type="primary" ghost onClick={showModal}>
        {title}
      </Button>
      <Modal
        title="Request a Document"
        visible={visible}
        centered
        onCancel={handleCancel}
        onOk={handleSubmit(onSubmit)}
        okText="Request"
        cancelText="Cancel"
        okButtonProps={{ disabled: requestBtnDisabled }}
      >
        <InputWrapper>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select document category"
            optionFilterProp="children"
            onChange={(value) => {
              setSelectedCategory(value)
              setValue('title', '')
              setDocumentYear(null)
              setMonthRange(null)
              setSelectedTypeId(null);
            }}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {categoriesArr?.length && categoriesArr?.map((cat, idx) =>
              <Option key={idx} value={cat}>{cat}</Option>
            )}
          </Select>
        </InputWrapper>
        {(filteredTypes && filteredTypes?.length > 0) &&
          <InputWrapper>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select document type"
              optionFilterProp="children"
              onChange={setSelectedTypeId}
              value={selectedTypeId}
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
              {filteredTypes?.map((el, idx) =>
                <Option key={idx} value={el.id}>{el.type}</Option>
              )}
            </Select>
          </InputWrapper>
        }
        {selectedType?.period === 'month' &&
          <InputWrapper>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Period"
              optionFilterProp="children"
              onChange={setMonthRange}
              value={monthRange}
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
              {monthRanges?.map((el, idx) =>
                <Option key={idx} value={el.value}>{el.label}</Option>
              )}
            </Select>
          </InputWrapper>
        }
        {selectedType?.period === 'year' &&
          <InputWrapper>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Year of the Document"
              optionFilterProp="children"
              onChange={setDocumentYear}
              value={documentYear}
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
              {yearRanges?.map((el, idx) =>
                <Option key={idx} value={el.value}>{el.label}</Option>
              )}
            </Select>
          </InputWrapper>
        }
        <form onSubmit={handleSubmit(onSubmit)}>
          {selectedTypeId &&
            <InputWrapper>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Document title (optional)"
                    {...field}
                  />
                )}
              />
            </InputWrapper>
          }
        </form>
      </Modal>
    </>
  )
}

const InputWrapper = styled('div')`
  width: 100%;

  :not(:first-of-type) {
    margin: 24px 0;
  }
`;

export default RequestDocument
