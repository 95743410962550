const initialState = {
  data: [],
  loading: false,
};

export const documentsAuditLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ABANDONED_DOCUMENTS':
      return { ...state, loading: true };
    case 'FETCH_DOCUMENTS_AUDIT_LOG_SUCCESS':
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}
