import { Typography } from 'antd';

const { Text } = Typography;

function PaymentStatus({ status }) {
    if (status === true) return <Text type="success">Success</Text>;
    if (status === false) return <Text type="warning">Declined</Text>;
    return '-';
}

export default PaymentStatus;
