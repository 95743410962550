import React, { useState, useEffect } from 'react'
import { endpoints } from 'api';
import { Button, Modal, Col, Row, message} from 'antd'
import { SelectInput, TextAreaInput } from 'components/FormInput';
import { yearRanges } from 'const';
import { useApi } from 'api';
import { Formik} from "formik";
import * as Yup from "yup";
import AddFieldInputs from 'components/FormInput/AddFieldInputs';
import styled from 'styled-components';

const CreateTaxOrgQModal = ({ title, setTriggerUpdateToTaxOrgQs, triggerUpdateToTaxOrgQs }) => {
  const [api] = useApi(true);
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [enteredOptions, setEnteredOptions] = useState([]);
  const [showOptionsError, setShowOptionsError] = useState(false);

  // Ant-D Success Alert
  const [messageApi, contextHolder] = message.useMessage();
  const showSuccessAlert = () => {
    messageApi.open({
      type: 'success',
      content: 'Question created',
    });;
  };

 const QUESTION_TYPES = [
  { label: "Text", value: "text"}, 
  {label: "Radio", value: "radio"}, 
  {label: "Select", value: "select"}
]

  const initialInfo = {year: "", type: "", question: ""}

  const validationRules = Yup.object().shape({
    question: Yup.string().required('Please enter Question Text'),
    type: Yup.string().required('Please select Type'),
    year: Yup.string().required('Please select Year'),
  });

useEffect(() => {
  console.log(enteredOptions)
}, [enteredOptions]);

  async function createTaxOrgQ(values, resetForm, handleSubmit, isValid) {
    try{
      setIsSubmitting(true)

      // Check Formik Validations for question, type, and year
      handleSubmit()
      if (!isValid) {
        setIsSubmitting(false)
        return
      }

      // Check if user provided options for radio and select inputs
      const {question, year, type} = values
      if ((type === "radio" || type === "select")) {
        if (enteredOptions.length < 2) {
          setShowOptionsError(true)
          setIsSubmitting(false)
          return
        }
        for (const option of enteredOptions) {
          console.log("option", option)
          if (!option) {
            setShowOptionsError(true)
            setIsSubmitting(false)
            return
          }
        }
        setShowOptionsError(false)
      }

      const apiObject = {
        question,
        year,
        type,
        options: enteredOptions
      }
      const res = await api({
        method: 'POST',
        url: `${endpoints.TAX_ORG_YEAR_QUESTIONS}`,
        data: apiObject
      });


      if (res.status === 200) {
        console.log("create Q")
        showSuccessAlert()
        setVisible(false)
        setTriggerUpdateToTaxOrgQs(!triggerUpdateToTaxOrgQs)
        setEnteredOptions([])

        // Reset Formik Values
        resetForm()
      } 
      setIsSubmitting(false)

    } catch(error) {
      console.log(error)
      setIsSubmitting(false)
    }
  }

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const optionsFieldsUpdated = (changedFields, allFields) => {
    for (const field of allFields) {
      if (Array.isArray(field.value)) {
        setEnteredOptions(field.value)
      }
    }
  };

  return (
    <>
    <Button type="primary" ghost onClick={showModal}>
      {title}
    </Button>
    <Formik
          initialValues={initialInfo}
          validationSchema={validationRules}
          onSubmit={(values, {resetForm}) => {resetForm()}}
          validateOnMount
        >
          {({isValid, handleSubmit, values, setFieldValue, touched, resetForm}) => (
    <Modal
        title="Create Question"
        visible={visible}
        centered
        onCancel={handleCancel}
        width={600}
        bodyStyle={{padding: "15px"}}
        onOk={() =>createTaxOrgQ(values, resetForm, handleSubmit, isValid)}
        okButtonProps={{ loading: isSubmitting }}
        okText="Create"
      >

                <div onSubmit={handleSubmit}>


                  <Row>
                    <Col xs={{span:12}}>
                      <SelectInput 
                        label="Year" 
                        options={yearRanges}
                        name="year"
                        placeholder="Year"
                        onChange={(val) => setFieldValue("year", val)}
                        style={{width: "90px"}}
                      />
                      {/* <ErrorMessage name="year"/> */}
                    </Col>
                    <Col xs={{span:12}}>
                      <SelectInput
                            name="type"
                            label="Type"
                            placeholder="Type"
                            options={QUESTION_TYPES}
                            onChange={(val) => setFieldValue("type", val)}
                            style={{width: "90px"}}
                            />
                      {/* <ErrorMessage name="type"/> */}
                    </Col>
                  </Row>
                    <TextAreaInput
                        name="question"
                        label="Question Text"
                    />
                    {
                      ( values.type === "radio" || values.type === "select") &&
                      <AddFieldInputs optionsFieldsUpdated={optionsFieldsUpdated}createTaxOrgQ={createTaxOrgQ} />
                    }
                    { showOptionsError && <div className="ant-form-item-explain ant-form-item-explain-error">
                      <div role="alert">Please fill out all options</div>
                    </div>}
                    {contextHolder}
                </div>

              </Modal>
              )}

          </Formik>

    </>
  )
}

const SelectWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default CreateTaxOrgQModal
