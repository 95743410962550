const initialState = {
  allDocumentsData: null,
  allDocumentsTotal: null,
  allDocumentsLoading: false,
  allDocumentsError: null,
};

export const allDocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ALL_DOCUMENTS':
      return { ...state, allDocumentsLoading: true, allDocumentsError: null, allDocumentsData: [], allDocumentsTotal: null };
    case 'FETCH_ALL_DOCUMENTS_SUCCESS':
      return { ...state, allDocumentsLoading: false, allDocumentsError: null, allDocumentsData: action.payload.data, allDocumentsTotal: action.payload.total};
    case 'FETCH_ALL_DOCUMENTS_ERROR':
      return { ...state, allDocumentsLoading: true, allDocumentsError: action.payload, allDocumentsData: [], allDocumentsTotal: null };
    case 'NOTIFY_DOCUMENTS_SUCCESS':
      return { ...state, notificationData: action.payload };
    default:
      return state;
  }
}
