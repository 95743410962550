import { Form, Input, Button } from 'antd';
import styled from 'styled-components';
import store from 'store';
import { useHistory } from 'react-router-dom';
import { DASHBOARD } from 'routes/paths';
import { endpoints, useApi } from 'api';
import { useState } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';

function LoginForm() {
    const [loading, toggleLoading] = useState(false);
    const history = useHistory();
    const [api] = useApi(false);
    const [form] = Form.useForm();

    // Show generalized errors (for now)
    const showErrors = () => {
        form.setFields([
            {
                name: 'username',
                errors: ["Username might be wrong or you don't have access"],
            },
            {
                name: 'password',
                errors: ["Password might be wrong or you don't have access"],
            },
        ]);
    };

    // Process authentication data.
    const login = ({ access_token, token_type, refresh_token }) => {
        store.set('access_token', access_token);
        store.set('token_type', token_type);
        store.set('refresh_token', refresh_token);

        history.push(DASHBOARD);
    };

    // Submit form to get user authentication data.
    const submitForm = async (values) => {
        try {
            toggleLoading(true);
            const { username, password } = values;
            const body = new URLSearchParams();
            body.append('password', password);
            body.append('username', username);

            const req = await api.post(endpoints.LOGIN, body);
            const res = await req.data;

            if (res['access_token']) {
                login(res);
            }
        } catch (error) {
            toggleLoading(false);
            showErrors();
        }
    };

    // Show error on invalid form submit
    const onSubmitFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <FormWrapper>
            <Form
                name="basic"
                initialValues={{}}
                onFinish={submitForm}
                onFinishFailed={onSubmitFailed}
                layout="vertical"
                form={form}
            >
                <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        size="large"
                    >
                        Login <ArrowRightOutlined />
                    </Button>
                </Form.Item>
            </Form>
        </FormWrapper>
    );
}

const FormWrapper = styled.div`
    max-width: 500px;
    margin: 0 auto;
    padding: 0 15px;
`;

export default LoginForm;
