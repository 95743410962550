import { Table } from 'antd';

const DATA_SET = [
    {
        id: 1,
        amount: 1500.55,
        date: '01-01-2020',
        category: 'Salary',
        name: 'Salary of month December 2019',
    },
    {
        id: 2,
        amount: 2000,
        date: '02-01-2020',
        category: 'Credit',
        name: '1099 Income',
    },
    {
        id: 3,
        amount: 3500.55,
        date: '03-01-2020',
        category: 'Salary',
        name: 'Salary of month February 2020',
    },
    {
        id: 4,
        amount: 4500.55,
        date: '04-01-2020',
        category: 'Salary',
        name: 'Business Income',
    },
    {
        id: 5,
        amount: 1500.55,
        date: '05-01-2020',
        category: 'Credit',
        name: 'Side Business Income',
    },
];

const COLUMNS = [
    {
        title: 'Descriptions',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
    },
];

const VerifilinkIncomes = () => {
    return <Table dataSource={DATA_SET} rowKey="id" columns={COLUMNS} />;
};

export default VerifilinkIncomes;
