import { Switch, Route } from 'react-router-dom';
import { CREATE_STAFF_OR_ADMIN } from 'routes/paths';
import CreateAdmin from './CreateAdmin';

function CreateAdminIndex(props) {
    return (
        <Switch>
            <Route exact path={CREATE_STAFF_OR_ADMIN}>
                <CreateAdmin />
            </Route>
        </Switch>
    );
}

export default CreateAdminIndex;
