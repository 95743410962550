import { endpoints } from 'api';

export const fetchCategories = (api) => {
  return async (dispatch) => {
    
    try {
      dispatch({ type: 'FETCH_CATEGORIES' });

      const { data } = await api({
        url: `${endpoints.DOCUMENTS}/v1/types`,
      });

      dispatch({
        type: 'FETCH_CATEGORIES_SUCCESS',
        payload: data,
      });

    } catch (error) {
      dispatch({
        type: 'FETCH_CATEGORIES_ERROR',
        payload: error?.message || error,
      });
    }
  };
};