import { Table } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import styled from 'styled-components';
import { TableResponsive } from 'components/Styled';

function ClientTable(props) {

    const locale = {
        emptyText: (
            <div>
                <span style={{ fontSize: 45 }}>
                    <InboxOutlined />
                </span>
                <p>

                    Clicking on chart will show corresponding clients
                </p>
            </div>
        )
    };

    return (
        <>
            <TableWrapper>
                <TableResponsive>
                    <Table
                        locale={locale}
                        dataSource={props.data}
                        columns={props.columns}
                        rowKey={'id'}
                        loading={props.loading}
                    />
                </TableResponsive>
            </TableWrapper>
        </>
    );
}


const TableWrapper = styled.div`
    .ant-table {
        font-size: 12px;
    }

    .ant-pagination {
        text-align: right;
        margin: 16px 0;
    }
`;


export default ClientTable;
