import styled from 'styled-components';
import LoginForm from './LoginForm';
import { logoImage } from 'assets/img';

function Login() {
    return (
        <Wrapper gutter="0" className="bg-primary">
            <Content>
                <img src={logoImage} alt="TaxRise Logo" className="logo" />
                <LoginForm />
            </Content>
        </Wrapper>
    );
}

const Wrapper = styled.section`
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 20px;
`;

const Content = styled.div`
    width: 100%;
    max-width: 500px;
    background-color: #fff;
    margin: 0 auto;
    padding: 60px 20px;

    .logo {
        width: 200px;
        max-width: 100%;
        margin-left: 13px;
        padding: 25px 0;
    }

    @media (max-width: 767px) {
        padding: 30px 10px;
    }
`;

export default Login;
