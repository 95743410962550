import {useMemo} from 'react';
import {
    DashboardOutlined,
    DollarCircleOutlined,
    UserOutlined,
    PlusSquareOutlined,
    FileDoneOutlined,
    FileSearchOutlined,
    CloudUploadOutlined
} from '@ant-design/icons';

 import {Menu as AntMenu} from 'antd';
import {Link, useLocation} from 'react-router-dom';
import {
    CLIENTS,
    DASHBOARD,
    MANUAL_PAYMENT,
    CLIENT_PORTAL_INVITATION,
    MANAGER_DOCUMENTS,
    USERS,
    REQUEST_DOCUMENT,
    UPLOADED_DOCUMENTS
} from 'routes/paths';

import store from "store";
import jwt_decode from "jwt-decode";


const {Item} = AntMenu;

// Steps core route paths. The order is important
const ROOT_MENUS = [DASHBOARD, MANAGER_DOCUMENTS, CLIENTS, MANUAL_PAYMENT];

function Menu() {
    const {pathname} = useLocation();

    // Check which menu needs be selected.
    const selectedMenu = useMemo(() => {
        // Set primary/root route selected for inner routes
        for (let i = 0; i < ROOT_MENUS.length; i++) {
            if (pathname.includes(ROOT_MENUS[i])) {
                return ROOT_MENUS[i];
            }
        }
    }, [pathname]);


    const isAdmin = () => {
        const token = store.get('access_token');
        const decoded = jwt_decode(token);
        return decoded.type === 10;
    }

    return (
        <AntMenu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[DASHBOARD]}
            selectedKeys={[selectedMenu]}
        >
            <Item key={DASHBOARD} icon={<DashboardOutlined />}>
              <Link to={DASHBOARD}>Dashboard</Link>
            </Item>
            <Item key={MANAGER_DOCUMENTS} icon={<FileSearchOutlined />}>
              <Link to={MANAGER_DOCUMENTS}>My Work</Link>
            </Item>
            <Item key={CLIENTS} icon={<UserOutlined/>}>
                <Link to={CLIENTS}>Clients</Link>
            </Item>
            <Item key={CLIENT_PORTAL_INVITATION} icon={<PlusSquareOutlined/>}>
                <Link to={CLIENT_PORTAL_INVITATION}>CP Invite</Link>
            </Item>
            <Item key={REQUEST_DOCUMENT} icon={<FileDoneOutlined/>}>
                <Link to={REQUEST_DOCUMENT}>Document Request</Link>
            </Item>
            <Item key={UPLOADED_DOCUMENTS} icon={<CloudUploadOutlined/>}>
                <Link to={UPLOADED_DOCUMENTS}>Uploaded Documents</Link>
            </Item>
            {isAdmin() ?
                <>
                    <Item key={MANUAL_PAYMENT} icon={<DollarCircleOutlined/>}>
                        <Link to={MANUAL_PAYMENT}>Manual Payment</Link>
                    </Item>
                    <Item key={USERS} icon={<UserOutlined/>}>
                        <Link to={USERS}>Users</Link>
                    </Item>
                    {/* <Item key={CREATE_STAFF_OR_ADMIN} icon={<PlusSquareOutlined/>}> */}
                    {/*    <Link to={CREATE_STAFF_OR_ADMIN}>Create Staff</Link>*/}
                    {/* </Item> */}
                </>
                : ''}
        </AntMenu>
    );
}

export default Menu;
