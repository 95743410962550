import PageNotFound from 'pages/NotFound/PageNotFound';
import { Switch, Route } from 'react-router-dom';
import { USERS, CLIENT_DETAILS } from 'routes/paths';
import ClientProvider from './ClientContext/ClientProvider';
import ClientDetails from './ClientDetails';
import Users from './Users';

function UsersRoot(props) {
  console.log(`${CLIENT_DETAILS}/case/:caseId`);
  return (
    <Switch>
      <Route exact path={`${CLIENT_DETAILS}/case/:caseId`}>
        <ClientProvider>
          <ClientDetails />
        </ClientProvider>
      </Route>
      <Route exact path={`${CLIENT_DETAILS}/case/:caseId/`}>
        <ClientProvider>
          <ClientDetails />
        </ClientProvider>
      </Route>
      <Route exact path={`${CLIENT_DETAILS}/:id`}>
        <ClientProvider>
          <ClientDetails />
        </ClientProvider>
      </Route>
      <Route exact path={USERS}>
        <Users />
      </Route>
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
}

export default UsersRoot;
