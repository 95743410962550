import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
    Typography,
    Space,
    Divider,
    Row,
    Tooltip,
    PageHeader,
    Tag,
    Button,
    Input,
    message,
} from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { defaultIcon } from 'assets/img';
import { formateDateTime } from 'library';
import { useContext, useState } from 'react';
import ClientContext from '../ClientContext/ClientContext';
import { useClientUpdate } from 'hooks';
import { endpoints, useApi } from 'api';


const { Text } = Typography;


const getSendPasswordRedirectUrl = function () {
    if (endpoints.REACT_APP_API_ENDPOINT.includes('staging')) {
        return 'https://staging-client.taxrise.com'
    }
    else return 'https://client.taxrise.com'
}

function ClientProfile() {
    const { clientDetails, fetchClientDetails, toggleLoading } = useContext(
        ClientContext
    );
    const [updateClient] = useClientUpdate(
        fetchClientDetails,
        null,
        toggleLoading
    );

    const [newPass, setNewPass] = useState('')
    const [newPassIsSshow, setNewPassIsSshow] = useState(false)

    const [api] = useApi();
    const generateNewPassword = async (case_id) => {
        const req = await api({
            method: 'post',
            url: `${endpoints.GENERATE_NEW_PASSWORD}`,
            data: case_id,
        })

        const res = req.data;
        setNewPass(res.new_password)
        setNewPassIsSshow(true)
        message.success('New password created successfully');
    }

    const resendVerificationEmailToClient = async (case_id) => {
        await api({
            method: 'post',
            url: `${endpoints.RESEND_CLIENT_PORTAL_VERIFICATION_EMAIL}`,
            data: {case_id: case_id, redirect_url: `${getSendPasswordRedirectUrl()}/sign-up/email-verification`, email_app_name: 'Taxrise'},
        })

        message.success('Email verification email sent');
    }

    const {
        email,
        case_id,
        is_email_verified,
        date_joined,
        last_login,
        first_name,
        last_name,
        middle_name,
        is_active,
        id,
    } = clientDetails;

    const actions = <>
        {newPassIsSshow ? (
            <>
                <Input.Password value={newPass} placeholder="input password" />
                <CopyToClipboard text={newPass}
                    onCopy={() => message.success('New password copied to clipboard')}>
                    <Button>Copy password to clipboard</Button>
                </CopyToClipboard>
            </>
        ) : ''}

        <Button
            style={{ marginLeft: 5 }}
            // size="small"
            // icon={<MailOutlined />}
            type="default"
            onClick={() =>
                resendVerificationEmailToClient(case_id)
            }
        >Send verification email</Button>
        <Button
            onClick={() => generateNewPassword(case_id)}
        >
            Generate Password
        </Button>
        {is_active ? (
            <Button
                ghost
                type="danger"
                onClick={() => updateClient(id, { is_active: false })}
            >
                Deactivate
            </Button>
        ) : (
            <Button
                ghost
                type="primary"
                onClick={() => updateClient(id, { is_active: true })}
            >
                Activate
            </Button>
        )}
    </>

    const clientStatus =
        is_active === true ? (
            <Tag color="green">Active</Tag>
        ) : (
            <Tag color="orange">In Active</Tag>
        );

    return (
        <PageHeader
            onBack={null}
            title={`${first_name} ${middle_name || ''} ${last_name}`}
            avatar={{ src: defaultIcon }}
            ghost={false}
            tags={clientStatus}
            extra={actions}
        >
            <Row>
                <Space split={<Divider type="vertical" />} style={{ flex: 1 }}>
                    <div>
                        <Text type="secondary" strong>
                            Case ID
                        </Text>{' '}
                        <br />
                        <Text>{case_id}</Text>
                    </div>
                    <div>
                        <Text type="secondary" strong>
                            Email
                        </Text>{' '}
                        <br />
                        <Space>
                            {is_email_verified === true ? (
                                <Tooltip title="Email verified">
                                    <CheckCircleOutlined
                                        style={{ color: '#389e0d' }}
                                    />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Email not verified">
                                    <InfoCircleOutlined
                                        style={{ color: '#d46b08' }}
                                    />
                                </Tooltip>
                            )}
                            <Text>{email}</Text>
                        </Space>
                    </div>
                </Space>
                <Space split={<Divider type="vertical" />}>
                    <div>
                        <Text type="secondary" strong>
                            Joined Date
                        </Text>{' '}
                        <br />
                        <Text>
                            {date_joined ? formateDateTime(date_joined) : ''}
                        </Text>
                    </div>
                    <div>
                        <Text type="secondary" strong>
                            Last Login
                        </Text>{' '}
                        <br />
                        <Text>
                            {last_login ? formateDateTime(last_login) : ''}
                        </Text>
                    </div>
                </Space>
            </Row>
        </PageHeader>
    );
}

export default ClientProfile;
