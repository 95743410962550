import { useCallback, useEffect, useState } from 'react';
import { Radio, Row, Col, Spin, Empty } from 'antd';
import styled from 'styled-components';
import { endpoints, useApi } from 'api';
import CategoryTransactions from './CategoryTransactions';
import { cleanObj } from 'library';
import { CategoryPieChart, TransactionBarChart } from 'components/Charts';

const PERIOD = {
    month: 1,
    quarter: 3,
    year: 12,
};

const Spending = ({ caseId, banksFilter }) => {
    const [loading, toggleLoading] = useState(true);
    const [data, setData] = useState([]);
    const [view, setView] = useState(PERIOD.month);
    const [categoryData, setCategoryData] = useState([]);
    const [barDates, setBarDates] = useState({ fromDate: '', toDate: '' });
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [api] = useApi();

    const resetCategorySelections = () => {
        // Reset category states
        setCategoryData([]);
        setBarDates({
            fromDate: '',
            toDate: '',
        });
        setSelectedCategory('');
    };

    const fetchSpendingData = useCallback(async () => {
        if (!caseId) return;
        try {
            toggleLoading(true);
            resetCategorySelections();

            const req = await api({
                url: `${endpoints.PLAID_PERIODIC}${view}/`,
                params: cleanObj({
                    cs_id: caseId,
                    amount_gt: 0,
                    bank_names: banksFilter,
                }),
            });

            const res = req.data;

            if (Array.isArray(res)) {
                setData(res);
            }
            toggleLoading(false);
        } catch (error) {
            toggleLoading(false);
        }
    }, [api, caseId, view, banksFilter]);

    useEffect(() => {
        fetchSpendingData();
    }, [fetchSpendingData, view]);

    const viewHandler = (e) => {
        const view = e.target.value;
        setView(view);

        resetCategorySelections();
    };

    const barClickHandler = (e) => {
        if (!e) return;

        const { activePayload } = e;
        const selectedBarInfo = activePayload[0].payload;

        setCategoryData(activePayload[0].payload.category || []);
        setBarDates({
            fromDate: selectedBarInfo.from_date,
            toDate: selectedBarInfo.to_date,
        });
        setSelectedCategory(null);
    };

    const pieClickHandler = (e) => {
        if (!e) return;

        const { payload } = e;
        const selectedPieInfo = payload.payload;

        setSelectedCategory(selectedPieInfo.label);
    };

    if (!loading && !data.length) {
        return (
            <Empty
                description="No record found"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
        );
    }

    return (
        <Spin spinning={loading}>
            <Row gutter={20}>
                <Col xs={24} sm={24} lg={12}>
                    <SwitchWrapper>
                        <Radio.Group value={view} onChange={viewHandler}>
                            <Radio.Button value={PERIOD.month}>
                                Month
                            </Radio.Button>
                            <Radio.Button value={PERIOD.quarter}>
                                Quarter
                            </Radio.Button>
                            <Radio.Button value={PERIOD.year}>
                                Year
                            </Radio.Button>
                        </Radio.Group>
                    </SwitchWrapper>

                    <TransactionBarChart
                        data={data}
                        onClick={barClickHandler}
                    />
                </Col>
                <CategoryColumn xs={24} sm={24} lg={12}>
                    {!categoryData.length ? (
                        <p className="instruction">
                            <strong>Click on the bar to see details</strong>
                        </p>
                    ) : (
                        <CategoryPieChart
                            data={categoryData}
                            onClick={pieClickHandler}
                        />
                    )}
                </CategoryColumn>
            </Row>
            <CategoryTransactions
                caseId={caseId}
                fromDate={barDates.fromDate}
                toDate={barDates.toDate}
                categories={selectedCategory}
                banksFilter={banksFilter}
                apiParams={{ amount_gt: 0 }}
                showTotalAmount
                showCategoryFilter
            />
        </Spin>
    );
};

const SwitchWrapper = styled.div`
    margin-bottom: 25px;
    overflow: auto;

    .ant-radio-group {
        float: right;
    }
`;

const CategoryColumn = styled(Col)`
    background-color: #f5f5f5;
    min-height: 150px;
    padding-top: 10px;
    padding-bottom: 10px;

    p.instruction {
        color: #bdbdbd;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-bottom: 0;
    }
`;

export default Spending;
