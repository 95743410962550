import { Layout, Typography} from 'antd';
import {Fragment} from 'react';
import Documents from './UploadedDocuments';

const { Title } = Typography;
const {Content} = Layout;

function UploadedDocsContainer() {
    return (
        <Fragment>
            <Title level={3}>Uploaded Documents</Title>
                <Content
                    style={{
                        padding: '16px 24px',
                        backgroundColor: 'white',
                        marginTop: 20,
                    }}
                >
                    <Documents/>
                </Content>
        </Fragment>
    );
}

export default UploadedDocsContainer;
