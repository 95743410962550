import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import './App.less';
import 'styles/index.scss';
import Routes from 'routes/Routes';
import ScrollToTop from 'utilities/ScrollToTop';
import { store } from 'stores/store';

function App() {
    return (
        <Provider store={store}>
            <Router>
                <ScrollToTop />
                <Routes />
            </Router>
        </Provider>
    );
}
export default App;
