import * as DocumentsActionCreators from 'stores/Documents/actions';
import * as AllDocumentsActionCreators from 'stores/AllDocuments/actions';
import * as CategoriesActionCreators from 'stores/DocumentCategories/actions';
import * as ManagerDocumentsActionCreators from 'stores/ManagerDocuments/actions';
import * as DocumentsAuditLogActionCreators from 'stores/DocumentsAuditLog/actions';
import * as ChatActionCreators from 'stores/Chat/actions';

export default {
  ...DocumentsActionCreators,
  ...AllDocumentsActionCreators,
  ...CategoriesActionCreators,
  ...ManagerDocumentsActionCreators,
  ...ManagerDocumentsActionCreators,
  ...DocumentsAuditLogActionCreators,
  ...ChatActionCreators,
};
