/* eslint-disable react/display-name */
import { CalendarOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { TableResponsive } from 'components/Styled';
import { DateRangeFilter } from 'components/TableFilters';
import { useEffect, useState } from 'react';
import { useFetchTransactions } from 'hooks';
import { formateDateTime } from 'library';
import PropTypes from 'prop-types';

const TransactionsTable = ({
    caseId,
    params,
    disableDateFilter,
    showTotalAmount,
    showCategoryFilter,
}) => {
    const [transactions, updateTransactions] = useState([]);
    const [listParams, setListParams] = useState(params);
    const [totalAmount, setTotalAmount] = useState(0);
    const [filteredInfo, setFilteredInfo] = useState(null);

    const [transactionsData, loading] = useFetchTransactions(
        caseId,
        listParams
    );

    const clearFilters = () => {
        setFilteredInfo(null);
    };

    useEffect(() => {
        updateTransactions(transactionsData);
        if (showTotalAmount) {
            setTotalAmount(transactionsData.reduce((a, t) => a + t.amount, 0));
        }
        if (showCategoryFilter) {
            clearFilters();
        }
    }, [transactionsData, showTotalAmount, showCategoryFilter]);

    useEffect(() => {
        setListParams((existingParams) => {
            if (JSON.stringify(existingParams) !== JSON.stringify(params)) {
                return {
                    ...existingParams,
                    ...params,
                };
            } else {
                return existingParams;
            }
        });
    }, [params]);

    const categorySelectItems = (() => {
        const categories = transactions.map((t) => t.category.join(', '));

        return [...new Set(categories)];
    })();

    const filterByDateRange = (dates) => {
        let startDate = null;
        let endDate = null;

        if (dates) {
            startDate = formateDateTime(dates[0], 'YYYY-MM-DD');
            endDate = formateDateTime(dates[1], 'YYYY-MM-DD');
        }

        const filter = {
            from_date: startDate,
            to_date: endDate,
        };

        setListParams((existingParams) => ({
            ...existingParams,
            ...filter,
        }));
    };

    const categoryFilter = (() => {
        if (!showCategoryFilter) return {};

        return {
            filteredValue: filteredInfo ? filteredInfo.category : null,
            filters: categorySelectItems.map((cat) => ({
                text: cat,
                value: cat,
            })),
            onFilter: (value, record) =>
                record.category.join(', ').indexOf(value) === 0,
        };
    })();

    const columns = [
        {
            title: 'Description',
            dataIndex: 'name',
            key: 'description',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            render: (cat) =>
                Array.isArray(cat) && cat.length ? cat.join(', ') : '',
            ...categoryFilter,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 150,
            filterIcon: (filtered) => (
                <CalendarOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                />
            ),
            filterDropdown: (filterProps) => (
                <DateRangeFilter
                    {...filterProps}
                    dataIndex="date_joined"
                    onChange={filterByDateRange}
                    filterKeys={['from_date', 'to_date']}
                    disabled={disableDateFilter ? [true, true] : null}
                />
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    const totalAmountSummary = () => {
        if (!showTotalAmount) return null;
        return (
            <Table.Summary>
                <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={3} align="right">
                        <strong>Total</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                        <strong>{totalAmount.toFixed(2)}</strong>
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        );
    };

    const onTableChange = (pagination, filters, sorter, extra) => {
        setFilteredInfo(filters);

        const { currentDataSource } = extra;

        setTotalAmount(currentDataSource.reduce((a, t) => a + t.amount, 0));
    };

    return (
        <TableResponsive>
            <Table
                columns={columns}
                dataSource={transactions}
                rowKey="transaction_id"
                loading={loading}
                summary={totalAmountSummary}
                onChange={onTableChange}
            />
        </TableResponsive>
    );
};

TransactionsTable.propTypes = {
    caseId: PropTypes.number,
    params: PropTypes.object,
    disableDateFilter: PropTypes.bool,
    showTotalAmount: PropTypes.bool,
    showCategoryFilter: PropTypes.bool,
};

TransactionsTable.defaultProps = {
    caseId: null,
    params: {},
    disableDateFilter: false,
    showTotalAmount: false,
    showCategoryFilter: false,
};

export default TransactionsTable;
