import { useMemo } from 'react';
import { Select } from 'antd';
import { useField } from 'formik';
import { FieldWrapper } from 'components/Wrapper';

function SelectInput({ label, required, onChange, ...props }) {
    const [field, meta, helper] = useField(props);
    const { touched, error } = meta;

    const { setValue } = helper;

    const isInvalid = useMemo(() => {
        return !!(touched && error);
    }, [touched, error]);

    const changeHandler = (value) => {
        setValue(value);

        if (onChange) onChange(value);
    };

    return (
        <FieldWrapper
            isInvalid={isInvalid}
            label={label}
            required={required}
            error={error}
        >
            <Select {...field} {...props} onChange={changeHandler} />
        </FieldWrapper>
    );
}

export default SelectInput;
