import React from 'react'
import { Button, Tooltip } from 'antd'
import { EyeFilled } from '@ant-design/icons';

import styled from 'styled-components';

const PreviewButton = ({ previewLink, disabled }) => {
  return (
    <Tooltip title="Preview">
      <PreviewBtn size="middle" icon={<EyeFilled/>} type="text" target="_blank" href={previewLink} disabled={!previewLink} />
    </Tooltip>
  )
}

const PreviewBtn = styled(Button)`
  color: #008AC8;

  :hover {
    color: #005893;
  }

  svg {
    width: 18px;
    height: 18px;
    margin-bottom: -1px;
  }
`;

export default PreviewButton
