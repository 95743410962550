import { endpoints } from 'api';
import { message } from 'antd';

export const fetchDocumentsAuditLog = (api, case_id, page=1, limit=10) => {
  return async (dispatch) => {

    try {
      dispatch({ type: 'FETCH_DOCUMENTS_AUDIT_LOG' });

      const { data } = await api({
        url: `${endpoints.DOCUMENTS}/v1/cases/${case_id}/log?limit=${limit}&page=${page}`,
      });

      dispatch({
        type: 'FETCH_DOCUMENTS_AUDIT_LOG_SUCCESS',
        payload: data,
      });

    } catch (error) {
      dispatch({
        type: 'FETCH_DOCUMENTS_AUDIT_LOG_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const fetchUploadedDocuments = (api, page=1, limit=10) => {
  return async (dispatch) => {

    try {
      dispatch({ type: 'FETCH_UPLOADED_DOCUMENTS' });

      const { data } = await api({
        url: `${endpoints.DOCUMENTS}/v1/cases/manager/review?limit=${limit}&page=${page}`,
      });

      dispatch({
        type: 'FETCH_UPLOADED_DOCUMENTS_SUCCESS',
        payload: data,
      });

    } catch (error) {
      dispatch({
        type: 'FETCH_UPLOADED_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};
