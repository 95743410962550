import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form';

import { Tooltip, Button, Modal, Input } from 'antd'
import { CloseCircleFilled } from '@ant-design/icons';

import styled from 'styled-components';

const RejectButton = ({ handleReject, disabled }) => {
  const [visible, setVisible] = useState(false);
  const {
    control,
    reset,
    handleSubmit,
  } = useForm();

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onSubmit = (data) => {
    handleReject(data);
    reset();
    setVisible(false);
  };

  return (
    <>
    <Tooltip title="Reject">
      <RejectBtn size="middle" icon={<CloseCircleFilled />} type="text" onClick={showModal} disabled={disabled}/>
    </Tooltip>
    <Modal
        title="Rejecting a Document"
        visible={visible}
        centered
        onCancel={handleCancel}
        onOk={handleSubmit(onSubmit)}
        okText="Reject"
        cancelText="Cancel"
      >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Controller
            name="rejection_description"
            control={control}
            rules={{
              required: 'Please type reason',
            }}
            render={({ field }) => (
              <Input placeholder="Reject reason"
                {...field}
              />
            )}
          />
        </div>
      </form>
      </Modal>
    </>
  )
}

const RejectBtn = styled(Button)`
  color: #FF5455;

  :hover {
    color: #f73838;
  }
`;

export default RejectButton
