import { Tabs, Select } from 'antd';
import { useFetchBanks } from 'hooks';
import { useContext, useEffect, useState } from 'react';
import ClientContext from '../ClientContext/ClientContext';
import Spending from './Spending';
import Transactions from './Transactions';
import Incomes from './Incomes';
import VerifilinkIncomes from './VerifilinkIncomes';

const { TabPane } = Tabs;
const { Option } = Select;

const FinancialTransactions = () => {
    const [banksList, updateBanksList] = useState([]);
    const [selectedBanks, updateSelectedBanks] = useState([]);

    const { clientDetails } = useContext(ClientContext);
    const caseId = clientDetails ? clientDetails.case_id : null;

    const [banksData, bankLoading] = useFetchBanks(caseId);

    useEffect(() => {
        updateBanksList(banksData);
    }, [banksData]);

    const banksFilter = selectedBanks.join(',');

    // Global bank filter
    const filters = (
        <Select
            mode="multiple"
            allowClear
            style={{ minWidth: 150 }}
            placeholder="Select Bank"
            value={selectedBanks}
            onChange={updateSelectedBanks}
            loading={bankLoading}
            showSearch
            disabled={banksList.length < 2} // Disable bank selection when there has only one bank or none
        >
            {banksList.map((bank, key) => (
                <Option value={bank} key={key}>
                    {bank}
                </Option>
            ))}
        </Select>
    );

    return (
        <Tabs defaultActiveKey="spending" tabBarExtraContent={filters}>
            <TabPane tab="Spending" key="spending">
                <Spending caseId={caseId} banksFilter={banksFilter} />
            </TabPane>
            <TabPane tab="Incomes" key="incomes">
                <Incomes banksFilter={banksFilter} caseId={caseId} />
            </TabPane>
            <TabPane tab="Verifilink Incomes" key="v_incomes">
                <VerifilinkIncomes />
            </TabPane>
            <TabPane tab="Transactions" key="transaction">
                <Transactions banksFilter={banksFilter} caseId={caseId} />
            </TabPane>
        </Tabs>
    );
};

export default FinancialTransactions;
