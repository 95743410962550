import TransactionsTable from './TransactionsTable';

const CategoryTransactions = (props) => {
    const {
        caseId,
        categories,
        fromDate,
        toDate,
        banksFilter,
        apiParams,
        showTotalAmount,
        showCategoryFilter,
    } = props;

    if (!caseId || !categories || !fromDate || !toDate) {
        return null;
    }

    const params = {
        categories,
        from_date: fromDate,
        to_date: toDate,
        bank_names: banksFilter,
        ...apiParams,
    };

    return (
        <div style={{ padding: '20px 0' }}>
            <TransactionsTable
                caseId={caseId}
                params={params}
                disableDateFilter
                showTotalAmount={showTotalAmount}
                showCategoryFilter={showCategoryFilter}
            />
        </div>
    );
};

export default CategoryTransactions;
